import { ResourceWrapper } from 'lib/http/utils';
import { IntlKeys,  } from 'lib/localization/keys/__keys';
import { TitlesKeys } from 'lib/localization/keys';

export type DataViews = 'table' | 'card' | 'map';

type DashboardTotalUnserialized = {
  entities_count: {
    certificate: number;
    recognition: number;
    deviation: number;
    farm: number;
    validity_certificate_ikb: number;
    validity_certificate_welfare: number;
  };
  applications_access: {
    my_farms: boolean;
    report_uploads: boolean;
    animal_passports: boolean;
    company_reports: boolean;
    company_reports_survey_js: boolean;
    join_data_link: boolean;
    participations: boolean;
    calculated_reports: boolean;
    organizations: boolean;
    carbon_dioxide_footprints: boolean;
    cfp_calculations: boolean;
    request_logs: boolean;
    hfm_outputs: boolean;
    vri_comparison_tool: boolean;
    admin: boolean;
    farm_updates: boolean;
    upload_document: boolean;
    feed_provenance_file_validity_tool: boolean;
    template_config: boolean;
  };

  entities_access: {
    slaughter_message: boolean;
    invoice_message: boolean;
    genetic: boolean;
    delivery_message: boolean;
    movement_event: boolean;
    veterinarian_data: boolean;
    slaughter_price_correction: boolean;
    transport: boolean;
    certificate: boolean;
    data_catalogue: boolean;
    raw_material_origins: boolean;
    error_logs: boolean;
    reference_tables: boolean;
    farm_management: boolean;
  };
};

export const serializeDashboardTotal = (response: ResourceWrapper<DashboardTotalUnserialized>) => {
  const { resource } = response.data;

  const weekly =
    resource?.entities_count?.farm + resource?.entities_count?.recognition + resource?.entities_count?.deviation;

  const kiwa = weekly + resource?.entities_count?.certificate;

  const certificates =
    kiwa + resource?.entities_count?.validity_certificate_ikb + resource?.entities_count?.validity_certificate_welfare;

  const applications = Object.entries(resource?.applications_access).map(([key]) => key);

  const availableApplications = Object.entries(resource?.applications_access).filter(([, entity]) => entity).map(([key]) => key);
  const availableEntities = Object.entries(resource?.entities_access).filter(([, entity]) => entity).map(([key]) => key);

  // Witch items are hidden
  return {
    availableMenuItems: {
      // entities_count
      [IntlKeys.titleCertificates]: certificates !== 0,
      [IntlKeys.titleCertificatesKiwa]: kiwa !== 0,
      [IntlKeys.titleCertificatesDaily]: resource?.entities_count?.certificate !== 0,
      [IntlKeys.titleCertificatesWeekly]: weekly !== 0,
      [IntlKeys.titleCertificatesFarms]: resource?.entities_count?.farm !== 0,
      [IntlKeys.titleCertificatesRecognitions]: resource?.entities_count?.recognition !== 0,
      [IntlKeys.titleCertificatesDeviations]: resource?.entities_count?.deviation !== 0,
      [IntlKeys.titleCertificatesIKB]: resource?.entities_count?.validity_certificate_ikb !== 0,
      [IntlKeys.titleCertificatesWelfare]: resource?.entities_count?.validity_certificate_welfare !== 0,

      // entities_access
      [IntlKeys.titleSlaughter]: resource?.entities_access?.slaughter_message,
      [IntlKeys.slaughterPriceCorrections]: resource?.entities_access?.slaughter_price_correction,
      [IntlKeys.titleInvoices]: resource?.entities_access?.invoice_message,
      [IntlKeys.titleGenetics]: resource?.entities_access?.genetic,
      [IntlKeys.titleDeliveryMessages]: resource?.entities_access?.delivery_message,
      [IntlKeys.dashboardDeliveryMessages]: resource?.entities_access?.delivery_message,
      [IntlKeys.titleMovementEvents]: resource?.entities_access?.movement_event,
      [IntlKeys.dashboardMovementEvents]: resource?.entities_access?.movement_event,
      [IntlKeys.titleVeterinarianData]: resource?.entities_access?.veterinarian_data,
      [IntlKeys.transports]: resource?.entities_access?.transport,
      [IntlKeys.titleDataCatalogue]: resource?.entities_access?.data_catalogue,
      [IntlKeys.titleCertificates]: resource?.entities_access?.certificate,
      [IntlKeys.titleRawMaterialsOrigin]: resource?.entities_access?.raw_material_origins,
      [IntlKeys.titleErrorsLog]: resource?.entities_access?.error_logs,
      [IntlKeys.titleReferenceTables]: resource?.entities_access?.reference_tables,
      [IntlKeys.titleManageUploads]: resource?.entities_access?.reference_tables,
      [IntlKeys.referenceTablesAsPensLevel]: resource?.entities_access?.reference_tables,
      [IntlKeys.referenceTablesAsPensTypeSowBarn]: resource?.entities_access?.reference_tables,
      [IntlKeys.referenceTablesAsYesNo]: resource?.entities_access?.reference_tables,
      [IntlKeys.referenceTablesCfpCo2EmissionFactors]: resource?.entities_access?.reference_tables,
      [IntlKeys.referenceTablesCvbTable]: resource?.entities_access?.reference_tables,
      [IntlKeys.referenceTablesCfpGfliFeedprintTable]: resource?.entities_access?.reference_tables,
      [IntlKeys.referenceTablesIpccEmissionFactors]: resource?.entities_access?.reference_tables,
      [IntlKeys.referenceTablesContentOfNitricOxide]: resource?.entities_access?.reference_tables,
      [IntlKeys.referenceTablesPigHousingEmissionFactors]: resource?.entities_access?.reference_tables,
      [IntlKeys.referenceTablesElectricityConsumptionRates]: resource?.entities_access?.reference_tables,
      [IntlKeys.referenceTablesGasConsumptionRates]: resource?.entities_access?.reference_tables,
      [IntlKeys.referenceTablesWaterConsumptionRates]: resource?.entities_access?.reference_tables,
      [IntlKeys.referenceTablesRavCodes]: resource?.entities_access?.reference_tables,
      [IntlKeys.referenceTablesGwp100Factors]: resource?.entities_access?.reference_tables,
      [IntlKeys.referenceTablesWurMfcEfem]: resource?.entities_access?.reference_tables,
      [IntlKeys.referenceTablesCl550]: resource?.entities_access?.reference_tables,
      [IntlKeys.referenceTablesCl649]: resource?.entities_access?.reference_tables,
      [IntlKeys.referenceTablesCl650]: resource?.entities_access?.reference_tables,
      [IntlKeys.referenceTablesCl607]: resource?.entities_access?.reference_tables,
      [IntlKeys.referenceTablesSuppliersNumbers]: resource?.entities_access?.reference_tables,
      [IntlKeys.referenceTablesCountryCodes]: resource?.entities_access?.reference_tables,
      [IntlKeys.referenceTablesRavReferenceList]: resource?.entities_access?.reference_tables,
      [IntlKeys.referenceTablesAllocationManure]: resource?.entities_access?.reference_tables,
      [IntlKeys.referenceTablesGreenhouseGasEmission]: resource?.entities_access?.reference_tables,
      [IntlKeys.referenceTablesEntitiesList]: resource?.entities_access?.reference_tables,
      [IntlKeys.titleFarmManagement]: resource?.entities_access?.farm_management,
      [IntlKeys.titleFtpUpdates]: resource?.entities_access?.farm_management,
      [IntlKeys.titleCodesAccount]: resource?.entities_access?.farm_management,
      [IntlKeys.titleCodesAnimalIdentityType]: resource?.entities_access?.farm_management,
      [IntlKeys.titleCodesAnimalRemarks]: resource?.entities_access?.farm_management,
      [IntlKeys.titleCodesAnimalScore]: resource?.entities_access?.farm_management,
      [IntlKeys.titleCodesBarn]: resource?.entities_access?.farm_management,
      [IntlKeys.titleCodesDefects]: resource?.entities_access?.farm_management,
      [IntlKeys.titleCodesDiagnose]: resource?.entities_access?.farm_management,
      [IntlKeys.titleCodesEventTypes]: resource?.entities_access?.farm_management,
      [IntlKeys.titleCodesFarmOption]: resource?.entities_access?.farm_management,
      [IntlKeys.titleCodesInsemination]: resource?.entities_access?.farm_management,
      [IntlKeys.titleCodesMatingType]: resource?.entities_access?.farm_management,
      [IntlKeys.titleCodesPen]: resource?.entities_access?.farm_management,
      [IntlKeys.titleCodesRemovalType]: resource?.entities_access?.farm_management,
      [IntlKeys.titleCodesRoom]: resource?.entities_access?.farm_management,
      [IntlKeys.titleCodesTreatments]: resource?.entities_access?.farm_management,
      [IntlKeys.titleCodesWeightType]: resource?.entities_access?.farm_management,
      [IntlKeys.titleGroupExpense]: resource?.entities_access?.farm_management,
      [IntlKeys.titleGroupFinancialEvents]: resource?.entities_access?.farm_management,
      [IntlKeys.titleGroupPurchasedFood]: resource?.entities_access?.farm_management,
      [IntlKeys.titleGroupUsedFood]: resource?.entities_access?.farm_management,
      [IntlKeys.titleGroupGroups]: resource?.entities_access?.farm_management,
      [IntlKeys.titleGroupsArrival]: resource?.entities_access?.farm_management,
      [IntlKeys.titleGroupMoves]: resource?.entities_access?.farm_management,
      [IntlKeys.titleGroupRemovals]: resource?.entities_access?.farm_management,
      [IntlKeys.titleGroupWeights]: resource?.entities_access?.farm_management,

      // applications_access
      [IntlKeys.titleVri]: resource?.applications_access?.report_uploads,
      [IntlKeys.titleVriCalculated]: resource?.applications_access?.calculated_reports,
      [IntlKeys.titleMyCompany]: resource?.applications_access?.my_farms,
      [IntlKeys.titleAnimalPassport]: resource?.applications_access?.animal_passports,
      [IntlKeys.titleFarmerInput]: resource?.applications_access?.company_reports,
      [IntlKeys.titleFarmerInputSurveyJs]: resource?.applications_access?.company_reports_survey_js,
      [IntlKeys.participationPlural]: resource?.applications_access?.participations,
      [IntlKeys.requestedParticipations]: resource?.applications_access?.participations,
      [IntlKeys.unmatchedOrganizations]: resource?.applications_access?.participations,
      [IntlKeys.titleMyJoinData]: resource?.applications_access?.join_data_link,
      [IntlKeys.titleAllOrganizations]: resource?.applications_access?.organizations,
      [IntlKeys.titleCarbonFootprints]: resource?.applications_access?.carbon_dioxide_footprints,
      [IntlKeys.titleCarbonFootprintRuns]: resource?.applications_access?.cfp_calculations,
      [IntlKeys.titleMonitoringDashboard]: resource?.applications_access?.request_logs,
      [IntlKeys.titleHFMOutput]: resource?.applications_access?.hfm_outputs,
      [IntlKeys.titleVriComparisonTool]: resource?.applications_access?.vri_comparison_tool,
      [TitlesKeys.admin]: resource?.applications_access?.admin,
      [IntlKeys.titleFarmUpdates]: resource?.applications_access?.farm_updates,
      [TitlesKeys.uploadDocument]: resource?.applications_access?.upload_document,
      [TitlesKeys.feedProvenanceValidityTool]: resource?.applications_access?.feed_provenance_file_validity_tool,
      [IntlKeys.titleTemplateConfigs]: resource?.applications_access?.template_config,
    },
    applications,
    availableApplications,
    availableEntities
  };
};
