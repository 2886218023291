import { SERVICE_FIELDS } from 'lib/excel/config/service';
import { IntlKeys } from 'lib/localization/keys/__keys';

enum AdminPermissionProfileTemplatesSerialized {
  PermissionProfileTemplates = 'PermissionProfileTemplates',
}

enum AdminPermissionProfileTemplatesUnserialized {
  PermissionProfileTemplates = 'PermissionProfileTemplates',
}

export enum AdminUsersUnserializedHeaders {
  title = 'title',
  notes = 'notes',

  // original
  permissionRules = 'permission_rules',
  // derived
  dataCatalogue = 'entities',
  reportUploads = 'report_uploads',
  myFarms = 'my_farms',
  movementEvents = 'movement_events',
  veterinarianData = 'veterinarian_data',
  deliveryMessages = 'delivery_messages',
  genetics = 'genetics',
  slaughterMessages = 'slaughter_messages',
  slaughterPriceCorrections = 'slaughter_price_corrections',
  invoiceMessages = 'invoice_messages',
  welfareValidityCertificate = 'welfare_validity_certificate',
  ikbValidityCertificate = 'ikb_validity_certificate',
  kiwaWeekly = 'kiwa_weekly',
  kiwaDaily = 'certificates',
  animalPassports = 'animal_passports',
  participations = 'participations',
  organizations = 'organizations',
  transports = 'transports',
  companyReports = 'company_reports',
  companyReportsSurveyJs = 'company_reports_survey_js',
  vriTool = 'calculated_reports',
  certificatesAll = 'certificates.all',
  myJoinData = 'join_data_link',
  carbonFootprints = 'carbon_dioxide_footprints',
  cfpCalculations = 'cfp_calculations',
  monitoringDashboard = 'request_logs',
  rawMaterialOrigins = 'raw_material_origins',
  referenceTables = 'reference_tables',
  farmManagement = 'farm_management',
  errorsLog = 'error_logs',
  hfmOutputs = 'hfm_outputs',
  vriComparisonTool = 'vri_comparison_tool',
}

export enum AdminUsersSerializedHeaders {
  title = 'title',
  notes = 'notes',

  // original
  permissionRules = 'permissionRules',
  // derived
  dataCatalogue = 'dataCatalogue',
  vri = 'vri',
  myFarms = 'myFarms',
  movementEvents = 'movementEvents',
  veterinarianData = 'veterinarianData',
  deliveryMessages = 'deliveryMessages',
  genetics = 'genetics',
  slaughterMessages = 'slaughterMessages',
  slaughterPriceCorrections = 'slaughterPriceCorrections',
  invoiceMessages = 'invoiceMessages',
  welfareValidityCertificate = 'welfareValidityCertificate',
  ikbValidityCertificate = 'ikbValidityCertificate',
  kiwaWeekly = 'kiwaWeekly',
  kiwaDaily = 'kiwaDaily',
  animalPassports = 'animalPassports',
  participations = 'participations',
  organizations = 'organizations',
  transports = 'transports',
  companyReports = 'companyReports',
  companyReportsSurveyJs = 'companyReportsSurveyJs',
  vriTool = 'vriTool',
  certificatesAll = 'certificatesAll',
  myJoinData = 'myJoinData',
  carbonFootprints = 'carbonFootprints',
  cfpCalculations = 'cfpCalculations',
  monitoringDashboard = 'monitoringDashboard',
  rawMaterialOrigins = 'rawMaterialOrigins',
  referenceTables = 'referenceTables',
  farmManagement = 'farmManagement',
  errorsLog = 'errorsLog',
  hfmOutputs = 'hfmOutputs',
  vriComparisonTool = 'vriComparisonTool'
}

export const PERMISSION_PROFILE_TEMPLATES = {
  [AdminPermissionProfileTemplatesSerialized.PermissionProfileTemplates]: {
    serialized: AdminPermissionProfileTemplatesSerialized.PermissionProfileTemplates,
    unserialized: AdminPermissionProfileTemplatesUnserialized.PermissionProfileTemplates,
    view: IntlKeys.titleAllRoles,

    columns: {
      [SERVICE_FIELDS.id.serialized]: {
        serialized: SERVICE_FIELDS.id.serialized,
        unserialized: SERVICE_FIELDS.id.unserialized,
        view: SERVICE_FIELDS.id.view,
      },
      [AdminUsersSerializedHeaders.title]: {
        serialized: AdminUsersSerializedHeaders.title,
        unserialized: AdminUsersUnserializedHeaders.title,
        view: IntlKeys.title,
      },
      [AdminUsersSerializedHeaders.notes]: {
        serialized: AdminUsersSerializedHeaders.notes,
        unserialized: AdminUsersUnserializedHeaders.notes,
        view: IntlKeys.notes,
      },
      [AdminUsersSerializedHeaders.permissionRules]: {
        serialized: AdminUsersSerializedHeaders.permissionRules,
        unserialized: AdminUsersUnserializedHeaders.permissionRules,
        view: IntlKeys.adminPermissionRules,
      },
      [AdminUsersSerializedHeaders.dataCatalogue]: {
        serialized: AdminUsersSerializedHeaders.dataCatalogue,
        unserialized: AdminUsersUnserializedHeaders.dataCatalogue,
        view: IntlKeys.titleDataCatalogue,
      },
      [AdminUsersSerializedHeaders.vri]: {
        serialized: AdminUsersSerializedHeaders.vri,
        unserialized: AdminUsersUnserializedHeaders.reportUploads,
        view: IntlKeys.titleVri,
      },
      [AdminUsersSerializedHeaders.myFarms]: {
        serialized: AdminUsersSerializedHeaders.myFarms,
        unserialized: AdminUsersUnserializedHeaders.myFarms,
        view: IntlKeys.titleMyCompany,
      },
      [AdminUsersSerializedHeaders.movementEvents]: {
        serialized: AdminUsersSerializedHeaders.movementEvents,
        unserialized: AdminUsersUnserializedHeaders.movementEvents,
        view: IntlKeys.titleMovementEvents,
      },
      [AdminUsersSerializedHeaders.veterinarianData]: {
        serialized: AdminUsersSerializedHeaders.veterinarianData,
        unserialized: AdminUsersUnserializedHeaders.veterinarianData,
        view: IntlKeys.titleVeterinarianData,
      },
      [AdminUsersSerializedHeaders.deliveryMessages]: {
        serialized: AdminUsersSerializedHeaders.deliveryMessages,
        unserialized: AdminUsersUnserializedHeaders.deliveryMessages,
        view: IntlKeys.titleDeliveryMessages,
      },
      [AdminUsersSerializedHeaders.genetics]: {
        serialized: AdminUsersSerializedHeaders.genetics,
        unserialized: AdminUsersUnserializedHeaders.genetics,
        view: IntlKeys.titleGenetics,
      },
      [AdminUsersSerializedHeaders.slaughterMessages]: {
        serialized: AdminUsersSerializedHeaders.slaughterMessages,
        unserialized: AdminUsersUnserializedHeaders.slaughterMessages,
        view: IntlKeys.titleSlaughterMessages,
      },
      [AdminUsersSerializedHeaders.slaughterPriceCorrections]: {
        serialized: AdminUsersSerializedHeaders.slaughterPriceCorrections,
        unserialized: AdminUsersUnserializedHeaders.slaughterPriceCorrections,
        view: IntlKeys.slaughterPriceCorrections,
      },
      [AdminUsersSerializedHeaders.invoiceMessages]: {
        serialized: AdminUsersSerializedHeaders.invoiceMessages,
        unserialized: AdminUsersUnserializedHeaders.invoiceMessages,
        view: IntlKeys.titleInvoices,
      },
      [AdminUsersSerializedHeaders.welfareValidityCertificate]: {
        serialized: AdminUsersSerializedHeaders.welfareValidityCertificate,
        unserialized: AdminUsersUnserializedHeaders.welfareValidityCertificate,
        view: IntlKeys.titleCertificatesWelfare,
      },
      [AdminUsersSerializedHeaders.ikbValidityCertificate]: {
        serialized: AdminUsersSerializedHeaders.ikbValidityCertificate,
        unserialized: AdminUsersUnserializedHeaders.ikbValidityCertificate,
        view: IntlKeys.titleCertificatesIKB,
      },
      [AdminUsersSerializedHeaders.kiwaWeekly]: {
        serialized: AdminUsersSerializedHeaders.kiwaWeekly,
        unserialized: AdminUsersUnserializedHeaders.kiwaWeekly,
        view: IntlKeys.titleCertificatesKiwaWeekly,
      },
      [AdminUsersSerializedHeaders.kiwaDaily]: {
        serialized: AdminUsersSerializedHeaders.kiwaDaily,
        unserialized: AdminUsersUnserializedHeaders.kiwaDaily,
        view: IntlKeys.titleCertificatesDaily,
      },
      [AdminUsersSerializedHeaders.animalPassports]: {
        serialized: AdminUsersSerializedHeaders.animalPassports,
        unserialized: AdminUsersUnserializedHeaders.animalPassports,
        view: IntlKeys.titleAnimalPassport,
      },
      [AdminUsersSerializedHeaders.participations]: {
        serialized: AdminUsersSerializedHeaders.participations,
        unserialized: AdminUsersUnserializedHeaders.participations,
        view: IntlKeys.participationPlural,
      },
      [AdminUsersSerializedHeaders.organizations]: {
        serialized: AdminUsersSerializedHeaders.organizations,
        unserialized: AdminUsersUnserializedHeaders.organizations,
        view: IntlKeys.titleAllOrganizations,
      },
      [AdminUsersSerializedHeaders.transports]: {
        serialized: AdminUsersSerializedHeaders.transports,
        unserialized: AdminUsersUnserializedHeaders.transports,
        view: IntlKeys.transports,
      },
      [AdminUsersSerializedHeaders.companyReports]: {
        serialized: AdminUsersSerializedHeaders.companyReports,
        unserialized: AdminUsersUnserializedHeaders.companyReports,
        view: IntlKeys.titleFarmerInput,
      },
      [AdminUsersSerializedHeaders.companyReportsSurveyJs]: {
        serialized: AdminUsersSerializedHeaders.companyReportsSurveyJs,
        unserialized: AdminUsersUnserializedHeaders.companyReportsSurveyJs,
        view: IntlKeys.titleFarmerInputSurveyJs,
      },
      [AdminUsersSerializedHeaders.vriTool]: {
        serialized: AdminUsersSerializedHeaders.vriTool,
        unserialized: AdminUsersUnserializedHeaders.vriTool,
        view: IntlKeys.titleVriCalculated,
      },
      [AdminUsersSerializedHeaders.certificatesAll]: {
        serialized: AdminUsersSerializedHeaders.certificatesAll,
        unserialized: AdminUsersUnserializedHeaders.certificatesAll,
        view: IntlKeys.certificates,
      },
      [AdminUsersSerializedHeaders.myJoinData]: {
        serialized: AdminUsersSerializedHeaders.myJoinData,
        unserialized: AdminUsersUnserializedHeaders.myJoinData,
        view: IntlKeys.titleMyJoinData,
      },
      [AdminUsersSerializedHeaders.carbonFootprints]: {
        serialized: AdminUsersSerializedHeaders.carbonFootprints,
        unserialized: AdminUsersUnserializedHeaders.carbonFootprints,
        view: IntlKeys.titleCarbonFootprints,
      },
      [AdminUsersSerializedHeaders.cfpCalculations]: {
        serialized: AdminUsersSerializedHeaders.cfpCalculations,
        unserialized: AdminUsersUnserializedHeaders.cfpCalculations,
        view: IntlKeys.titleCarbonFootprintRuns,
      },
      [AdminUsersSerializedHeaders.monitoringDashboard]: {
        serialized: AdminUsersSerializedHeaders.monitoringDashboard,
        unserialized: AdminUsersUnserializedHeaders.monitoringDashboard,
        view: IntlKeys.titleMonitoringDashboard,
      },
      [AdminUsersSerializedHeaders.rawMaterialOrigins]: {
        serialized: AdminUsersSerializedHeaders.rawMaterialOrigins,
        unserialized: AdminUsersUnserializedHeaders.rawMaterialOrigins,
        view: IntlKeys.titleRawMaterialsOrigin,
      },
      [AdminUsersSerializedHeaders.referenceTables]: {
        serialized: AdminUsersSerializedHeaders.referenceTables,
        unserialized: AdminUsersUnserializedHeaders.referenceTables,
        view: IntlKeys.titleReferenceTables,
      },
      [AdminUsersSerializedHeaders.farmManagement]: {
        serialized: AdminUsersSerializedHeaders.farmManagement,
        unserialized: AdminUsersUnserializedHeaders.farmManagement,
        view: IntlKeys.titleFarmManagement,
      },
      [AdminUsersSerializedHeaders.errorsLog]: {
        serialized: AdminUsersSerializedHeaders.errorsLog,
        unserialized: AdminUsersUnserializedHeaders.errorsLog,
        view: IntlKeys.titleErrorsLog,
      },
      [AdminUsersSerializedHeaders.hfmOutputs]: {
        serialized: AdminUsersSerializedHeaders.hfmOutputs,
        unserialized: AdminUsersUnserializedHeaders.hfmOutputs,
        view: IntlKeys.titleHFMOutput,
      },
      [AdminUsersSerializedHeaders.vriComparisonTool]: {
        serialized: AdminUsersSerializedHeaders.vriComparisonTool,
        unserialized: AdminUsersUnserializedHeaders.vriComparisonTool,
        view: IntlKeys.titleVriComparisonTool,
      },
    },
  },
};
