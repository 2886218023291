import {
  //
  TitlesKeys,
  UiKeys,
  CertificatesKiwaDailyKeys,
  CertificatesKiwaWeeklyDeviations,
  CertificatesKiwaWeeklyFarms,
  CertificatesKiwaWeeklyRecognitions,
  CertificatesWelfareKeys,
  FarmEventsKeys,
  FarmReportKeys,
  FarmStablesKeys,
  VriKeys,
  InvoicesKeys,
  GeneticKeys,
  MovementEventsKeys,
  ServiceKeys,
  FarmHomeKeys,
  UploadsKeys,
} from '../keys';
import { IntlKeys } from '../keys';

/** Always check if number of lines match
 * export on line 40
 *
 *
 *
 *
 *
 *
 *
 *
 *
 *
 *
 *
 *
 *
 */
export const en = {
  // common
  [IntlKeys.ok]: 'Ok',
  [IntlKeys.messageId]: 'Message ID',
  [IntlKeys.fromTo]: 'from {from} to {to}',
  [IntlKeys.dateRange]: '{start} - from {from} to {to}',
  [IntlKeys.date]: 'Date',
  [IntlKeys.of]: 'of',
  [IntlKeys.pig]: 'Pig',
  [IntlKeys.pigs]: 'Pigs',
  [IntlKeys.hideDetails]: 'Hide Details',
  [IntlKeys.moreDetails]: 'More Details',
  [IntlKeys.cancel]: 'Cancel',
  [IntlKeys.save]: 'Save',
  [IntlKeys.apply]: 'Apply',
  [IntlKeys.accept]: 'Accept',
  [IntlKeys.more]: 'More',
  [IntlKeys.show]: 'Show',
  [IntlKeys.close]: 'Close',
  [IntlKeys.back]: 'Back',
  [IntlKeys.return]: 'Return',
  [IntlKeys.submit]: 'Submit',
  [IntlKeys.approve]: 'Approve',
  [IntlKeys.reject]: 'Reject',
  [IntlKeys.delete]: 'Delete',
  [IntlKeys.download]: 'Download',
  [IntlKeys.start]: 'Start',
  [IntlKeys.request]: 'Request',
  [IntlKeys.synchronize]: 'Synchronize',
  [IntlKeys.success]: 'Success',
  [IntlKeys.error]: 'Error',
  [IntlKeys.status]: 'Status',
  [IntlKeys.statusUpdatedBy]: 'Status updated by',
  [IntlKeys.statusUpdatedAt]: 'Status updated at',
  [IntlKeys.deleted]: 'Deleted',
  [IntlKeys.sessionId]: 'Session ID',
  [IntlKeys.methodology]: 'Methodology',
  [IntlKeys.branch]: 'Branch',
  [IntlKeys.genericSuccessMsg]: 'Everything went ok...',
  [IntlKeys.genericErrorMsg]: 'Something went wrong...',
  [IntlKeys.continueEditing]: 'Continue Editing',
  [IntlKeys.leaveWOSaving]: 'Leave without saving',
  [IntlKeys.leavePage]: 'Leave Page',
  [IntlKeys.areYouSure]: 'Are you sure?',
  [IntlKeys.unsavedChanges]: 'You have unsaved changes',
  [IntlKeys.unsavedDataWarning]: 'All unsaved data will be lost',
  [IntlKeys.dataSaved]: 'All data has been saved',
  [IntlKeys.lastModified]: 'Last modified',
  [IntlKeys.lastModifiedBy]: 'Last modified: {date} by {username}',
  [IntlKeys.maximumCapacity]: 'Maximum Capacity',
  [IntlKeys.selectLocation]: 'Select location',
  [IntlKeys.selectYourLocation]: 'Select your location',
  [IntlKeys.selectUserPassport]: 'Select user passport',
  [IntlKeys.id]: 'Id',
  [IntlKeys.name]: 'Name',
  [IntlKeys.email]: 'Email',
  [IntlKeys.kvk]: 'KVK',
  [IntlKeys.ubn]: 'UBN',
  [IntlKeys.kvkCommon]: 'KVK',
  [IntlKeys.ubnCommon]: 'UBN',
  [IntlKeys.orgKvkCommon]: 'KVK',
  [IntlKeys.ubns]: 'UBNs',
  [IntlKeys.gln]: 'GLN',
  [IntlKeys.btw]: 'BTW',
  [IntlKeys.certificates]: 'Certificates',
  [IntlKeys.notes]: 'Notes',
  [IntlKeys.scheme]: 'Scheme',
  [IntlKeys.title]: 'Title',
  [IntlKeys.filter]: 'Filter',
  [IntlKeys.yes]: 'Yes',
  [IntlKeys.no]: 'No',
  [IntlKeys.created]: 'Created',
  [IntlKeys.requested]: 'Requested',
  [IntlKeys.valid]: 'Valid',
  [IntlKeys.updated]: 'Updated',
  [IntlKeys.approved]: 'Approved',
  [IntlKeys.invoice]: 'Invoice',
  [IntlKeys.message]: 'Message',
  [IntlKeys.importantMessage]: 'Important Message',
  [IntlKeys.receipt]: 'Receipt',
  [IntlKeys.event]: 'Event',
  [IntlKeys.delivery]: 'Delivery',
  [IntlKeys.export]: 'Export',
  [IntlKeys.startDate]: 'Start date',
  [IntlKeys.endDate]: 'End date',
  [IntlKeys.duration]: 'Duration',
  [IntlKeys.admin]: 'Admin',
  [IntlKeys.new]: 'New',
  [IntlKeys.existing]: 'Existing',
  [IntlKeys.application]: 'Application',
  [IntlKeys.vionFarming]: 'Vion Farming',
  [IntlKeys.slaughter]: 'Slaughter',
  [IntlKeys.messages]: 'Messages',
  [IntlKeys.info]: 'Info',
  [IntlKeys.managementInfo]: 'Management Info',
  [IntlKeys.customKvkName]: 'KVK Custom Name',
  [IntlKeys.newReport]: 'New Report',
  [IntlKeys.manure]: 'Manure Reports',
  [IntlKeys.fermentation]: 'Fermentation Reports',
  [IntlKeys.byProducts]: 'By-Products Reports',
  [IntlKeys.companyData]: 'Company data (main)',
  [IntlKeys.zero]: '0',
  [IntlKeys.one]: '1',
  [IntlKeys.two]: '2',
  [IntlKeys.three]: '3',
  [IntlKeys.four]: '4',
  [IntlKeys.five]: '5',
  [IntlKeys.duplicate]: 'Duplicate',
  [IntlKeys.saveAndSubmit]: 'Save and submit',
  [IntlKeys.saveAsDraft]: 'Save as draft',
  [IntlKeys.revertToDraft]: 'Revert to draft',
  [IntlKeys.removeRavCode]: 'Remove RAV code',
  [IntlKeys.addRavCode]: 'Add RAV code',
  [IntlKeys.addConcept]: 'Add Concept',
  [IntlKeys.templates]: 'Templates',
  [IntlKeys.description]: 'Description',
  [IntlKeys.viewEdit]: 'View/Edit',
  [IntlKeys.update]: 'Update',
  [IntlKeys.deleteForever]: 'Delete forever',
  [IntlKeys.newIdMapping]: 'New ID Mapping',
  [IntlKeys.newAlias]: 'New alias',
  [IntlKeys.validDate]: 'Valid Date',
  [IntlKeys.uploadDate]: 'Upload Date',
  [IntlKeys.uploadedBy]: 'Uploaded By',
  [IntlKeys.type]: 'Type',
  [IntlKeys.primeUbn]: 'UBN',
  [IntlKeys.ubnAliases]: 'UBN Aliases',
  [IntlKeys.uploadedAt]: 'Uploaded At',
  [IntlKeys.downloadAliases]: 'Download Aliases',
  [IntlKeys.continue]: 'Continue',
  [IntlKeys.remove]: 'Remove',
  [IntlKeys.keep]: 'Keep',
  [IntlKeys.createNew]: 'Create New',
  [IntlKeys.or]: 'Or',
  [IntlKeys.labelName]: 'Label name',
  [IntlKeys.assignedTo]: 'Assigned to',
  [IntlKeys.create]: 'Create',
  [IntlKeys.vri]: 'VRI',
  [IntlKeys.epg]: 'EPG',
  [IntlKeys.healthBalance]: 'Health Balance',
  [IntlKeys.balanceWrtBase]: 'Balance w.r.t. Base',
  [IntlKeys.vriTool]: 'VRI Tool',
  [IntlKeys.vs]: 'vs',
  [IntlKeys.score]: 'score',
  [IntlKeys.selectApps]: 'Select Apps',
  [IntlKeys.requestDate]: 'Request Date',
  [IntlKeys.concepts]: 'Concepts',
  [IntlKeys.concept]: 'Concept',
  [IntlKeys.default]: 'Default',
  [IntlKeys.templateType]: 'Template Type',
  [IntlKeys.companyAvatar]: 'Company Avatar',
  [IntlKeys.isContactPerson]: 'Is Contact Person',
  [IntlKeys.contentAdmin]: 'Content Admin',
  [IntlKeys.userAdmin]: 'User Admin',
  [IntlKeys.monitoringAdmin]: 'Monitoring Admin',
  [IntlKeys.simpleUser]: 'Simple User',
  [IntlKeys.fiveAndMore]: '5 and more',
  [IntlKeys.lastUpdate]: 'last update',
  [IntlKeys.quarter]: 'Quarter',
  [IntlKeys.halfYear]: 'Half Year',
  [IntlKeys.year]: 'Year',
  [IntlKeys.timeRange]: 'Time Range',
  [IntlKeys.lastUpdated]: 'Last Updated',
  [IntlKeys.colorTheme]: 'Color theme',
  [IntlKeys.quarters]: 'Quarters',
  [IntlKeys.halfYears]: 'Half-Years',
  [IntlKeys.years]: 'Years',
  [IntlKeys.here]: 'here',
  [IntlKeys.view]: 'View',
  [IntlKeys.companyEmail]: 'Company Email',
  [IntlKeys.statusSubmitted]: 'Submitted',
  [IntlKeys.statusDraft]: 'Draft',
  [IntlKeys.submitted]: 'Submitted',
  [IntlKeys.draft]: 'Draft',
  [IntlKeys.isRemovedSuccessfully]: '{item} is deleted successfully',
  [IntlKeys.isCreatedSuccessfully]: '{item} created successfully',
  [IntlKeys.organization]: 'Organization',
  [IntlKeys.user]: 'User',
  [IntlKeys.adminUserPassport]: 'User Passport',
  [IntlKeys.alias]: 'Alias',
  [IntlKeys.permissionProfileTemplate]: 'Permission Profile Template',
  [IntlKeys.samePassword]: 'The new password must be different from the current password',
  [IntlKeys.passportStatus]: 'Passport status',

  // titles
  [TitlesKeys.solutionBySIM]: 'a solution by IB',
  [TitlesKeys.signIn]: 'Log In',
  [TitlesKeys.signUp]: 'Sign Up',
  [TitlesKeys.signOut]: 'Sign Out',
  [TitlesKeys.password]: 'Password',
  [TitlesKeys.dashboard]: 'Dashboard',
  [TitlesKeys.map]: 'Map',
  [TitlesKeys.certificates]: 'Certificates',
  [TitlesKeys.certificatesDaily]: 'Daily',
  [TitlesKeys.certificatesWeekly]: 'Weekly',
  [TitlesKeys.certificatesDeviations]: 'Deviations',
  [TitlesKeys.certificatesFarms]: 'Farms',
  [TitlesKeys.certificatesRecognitions]: 'Recognitions',
  [IntlKeys.titleCertificatesIKB]: 'IKB',
  [IntlKeys.titleCertificatesWelfare]: 'Welfare',
  [IntlKeys.titleCertificatesKiwa]: 'Kiwa', // NOT TRANSLATED OR TRANSLATED ??
  [IntlKeys.titleCertificatesKiwaDaily]: 'Kiwa Daily',
  [IntlKeys.titleCertificatesKiwaWeekly]: 'Kiwa Weekly',
  [IntlKeys.titleInvoices]: 'Invoices',
  [TitlesKeys.invoiceDetails]: 'Invoice Details',
  [IntlKeys.titleInvoiceMessages]: 'Invoice Messages',
  [TitlesKeys.slaughter]: 'Slaughter',
  [IntlKeys.titleSlaughterMessages]: 'Slaughter Messages',
  [IntlKeys.titleSlaughterDetails]: 'Slaughter Details',
  [IntlKeys.titleGenetics]: 'Genetics',
  [IntlKeys.titleDeliveryMessages]: 'Delivery Messages',
  [IntlKeys.titleDeliverySegment]: 'Delivery Segment',
  [TitlesKeys.deliverySegmentContents]: 'Delivery Segment Contents',
  [IntlKeys.titleUserPassports]: 'Organization Passports',
  [IntlKeys.titleMovementEvents]: 'Movement Events',
  [IntlKeys.titleVeterinarianData]: 'Veterinarian Data',
  [IntlKeys.titleUploadDocument]: 'Upload Document',
  [IntlKeys.titleFeedProvenanceValidityTool]: 'Feed Provenance File Validity Tool',
  [IntlKeys.titleTemplateConfig]: 'Survey Template Configuration',
  [IntlKeys.titleTemplateConfigs]: 'Survey Templates Configuration',
  [IntlKeys.titleTemplateConfigNew]: 'New Survey Template Configuration',
  [IntlKeys.titleTemplateConfigEdit]: 'Edit Survey Template Configuration',
  [IntlKeys.titleAdminFileUpload]: 'Upload History',
  [IntlKeys.titleDataCatalogue]: 'Data Catalogue',
  [IntlKeys.titleVri]: 'Good Farming Star Benchmark',
  [IntlKeys.titleVriCalculated]: 'VRI Tool',
  [TitlesKeys.vriEpg]: 'EPG',
  [TitlesKeys.vriHealthBalance]: 'Health Balance',
  [TitlesKeys.vriBalanceBase]: 'Balance W.R.T. Base',
  [TitlesKeys.vriReportingTool]: 'VRI Tool',
  [IntlKeys.titleVriCalculatedReportingTool]: 'VRI Calculated Tool',
  [TitlesKeys.vriDatabase]: 'Database',
  [TitlesKeys.vriDatabaseReports]: 'Database Reports',
  [TitlesKeys.myFarm]: 'Organization Passport',
  [IntlKeys.titleMyCompany]: 'My Company',
  [TitlesKeys.farmStables]: 'Create stable profile',
  [TitlesKeys.farmReports]: 'Reports',
  [IntlKeys.titleFarmUpdates]: 'Updates Log',
  [IntlKeys.titleMyJoinData]: 'My Joindata',
  [TitlesKeys.history]: 'History',
  [TitlesKeys.admin]: 'Admin Panel',
  [IntlKeys.titleAllOrganizations]: 'Organization Passports',
  [IntlKeys.titleAllUsers]: 'All Users',
  [IntlKeys.titleUsers]: 'Users',
  [IntlKeys.titleCreateOrganization]: 'Create Organization',
  [IntlKeys.titleUploads]: 'Uploads',
  [IntlKeys.titleUploadOrgsPassportsUsers]: 'Upload organization, passport and user',
  [IntlKeys.titleUploadOrganization]: 'Upload Organization',
  [IntlKeys.titleUploadUserPassports]: 'Upload Passports',
  [IntlKeys.titleUploadUsers]: 'Upload Users',
  [IntlKeys.titleAllRoles]: 'All Roles',
  [IntlKeys.titleAdminMenu]: 'Admin menu',
  [IntlKeys.titleAnimalPassport]: 'Animal Passport',
  [IntlKeys.titleFarmerInput]: 'Farmer Input',
  [IntlKeys.titleFarmerInputSurveyJs]: 'Farmer Input (SurveyJS)',
  [IntlKeys.titleCarbonFootprintRuns]: 'Carbon Footprint Runs',
  [IntlKeys.titleReportList]: 'Report List',
  [IntlKeys.titleCompanyReportNew]: 'New Company Data (Main) Reports',
  [IntlKeys.titleCompanyReportEdit]: 'Company Data (Main) Reports View/Edit',
  [IntlKeys.titleCompanyReportView]: 'Company Data (Main) Reports View',
  [IntlKeys.titleByProductsNew]: 'New By-Products Reports',
  [IntlKeys.titleByProductsEdit]: 'By-Products Reports View/Edit',
  [IntlKeys.titleByProductsView]: 'By-Products Reports View',
  [IntlKeys.titleFermentationNew]: 'New Fermentation Reports',
  [IntlKeys.titleFermentationEdit]: 'Fermentation Reports View/Edit',
  [IntlKeys.titleFermentationView]: 'Fermentation Reports View',
  [IntlKeys.titleManureNew]: 'New Manure Reports',
  [IntlKeys.titleManureEdit]: 'Manure Reports View/Edit',
  [IntlKeys.titleManureView]: 'Manure Reports View',
  [IntlKeys.titleManageUploads]: 'Manage Uploads',
  [IntlKeys.titleReferenceTables]: 'Reference Tables',
  [IntlKeys.titleUploadsList]: 'Uploads list',
  [IntlKeys.titleManageUploadEdit]: 'Manage Upload View/Edit',
  [IntlKeys.titleFarmManagementData]: 'Farm Management Data',
  [IntlKeys.titleFarmManagement]: 'Farm Management',
  [IntlKeys.titleGroupFinancialEvents]: 'Group - Financial Events',
  [IntlKeys.titleGroupGroups]: 'Group - Groups',
  [IntlKeys.titleFtpUpdates]: 'FTP Updates',
  [IntlKeys.titleGroupWeights]: 'Group - Group Weight',
  [IntlKeys.titleGroupRemovals]: 'Group - Group Removal',
  [IntlKeys.titleGroupsArrival]: 'Group - Groups Arrival',
  [IntlKeys.titleGroupMoves]: 'Group - Group Move',
  [IntlKeys.titleCodesAnimalRemarks]: 'Codes - Animal Remark',
  [IntlKeys.titleCodesDefects]: 'Codes - Defect',
  [IntlKeys.titleCodesDiagnose]: 'Codes - Diagnose',
  [IntlKeys.titleCodesAnimalScore]: 'Codes - Animal Score',
  [IntlKeys.titleCodesInsemination]: 'Codes - Insemination',
  [IntlKeys.titleCodesTreatments]: 'Codes - Treatment',
  [IntlKeys.titleGroupPurchasedFood]: 'Group - Food',
  [IntlKeys.titleGroupUsedFood]: 'Group - Food Used',
  [IntlKeys.titleCodesMatingType]: 'Codes - Mating Type',
  [IntlKeys.titleCodesWeightType]: 'Codes - Weight Type',
  [IntlKeys.titleCodesRemovalType]: 'Codes - Removal Type',
  [IntlKeys.titleCodesAnimalIdentityType]: 'Codes - Animal Identity Type',
  [IntlKeys.titleCodesFarmOption]: 'Codes - Farm Options',
  [IntlKeys.titleCodesBarn]: 'Codes - Barn',
  [IntlKeys.titleCodesRoom]: 'Codes - Room',
  [IntlKeys.titleCodesPen]: 'Codes - Pen',
  [IntlKeys.titleFms]: 'FMS',
  [IntlKeys.titleFarms]: 'Farms',
  [IntlKeys.titleStablesGroups]: 'Stables Groups',
  [IntlKeys.titleValidityCertificates]: 'Validity Certificates',
  [IntlKeys.titleReports]: 'Reports',
  [IntlKeys.titleAliases]: 'Aliases',
  [IntlKeys.titleCarbonFootprints]: 'Carbon Footprint Farms',
  [IntlKeys.titleFarmsList]: 'Farms List',
  [IntlKeys.titleUploadAliases]: 'Upload Aliases',
  [IntlKeys.titleMonitoringDashboard]: 'Monitoring Dashboard',
  [IntlKeys.titleSuppliers]: 'Suppliers',
  [IntlKeys.titleData]: 'Data',
  [IntlKeys.titleLabels]: 'Labels',
  [IntlKeys.titleManageLabels]: 'Manage labels',
  [IntlKeys.titleCreateLabel]: 'Create label',
  [IntlKeys.titleVriComparisonTool]: 'VRI Comparison Tool',
  [IntlKeys.titleVriTotal]: 'VRI',
  [IntlKeys.titleOrganization]: 'Organizations',
  [IntlKeys.titleParticipation]: 'Participations',
  [IntlKeys.titleVisits]: 'Views',
  [IntlKeys.titleCodesAccount]: 'Codes - Account',
  [IntlKeys.titleCodesEventTypes]: 'Codes - Event Type',
  [IntlKeys.titleGroupExpense]: 'Group - Expense',
  [IntlKeys.titleRawMaterialsOrigin]: 'CFP Raw Materials Origin',
  [IntlKeys.titleCarbonFootprintDetails]: 'Carbon Footprint Details',
  [IntlKeys.titleHFMOutput]: 'CFP Scope 1&2',
  [IntlKeys.titleVionGoodFarmingStar]: 'Vion Good Farming Star',
  [IntlKeys.titleVionGoodFarmingBalance]: 'Vion Good Farming Balance',
  [IntlKeys.titleOther]: 'Other',
  [IntlKeys.titleErrorsLog]: 'Errors Log',
  [IntlKeys.titleBreeding]: 'Breeding',
  [IntlKeys.titleFlattening]: 'Fattening',
  [IntlKeys.titleClosed]: 'Closed',
  [IntlKeys.titleRearing]: 'Rearing',

  // UI

  [UiKeys.bulkAction]: 'Bulk Action',
  [UiKeys.showMenu]: 'Show Menu',
  [UiKeys.displayAs]: 'Display As',
  [UiKeys.list]: 'List',
  [UiKeys.cards]: 'Cards',
  [UiKeys.filterItems]: 'Filter Items',
  [UiKeys.all]: 'All',
  [UiKeys.today]: 'Today',
  [UiKeys.thisWeek]: 'Last 7 Days',
  [UiKeys.thisMonth]: 'This Month',
  [UiKeys.dateRange]: 'Date Range',
  [UiKeys.from]: 'from',
  [UiKeys.to]: 'to',
  [UiKeys.resetFilters]: 'Reset Filters',
  [UiKeys.applyFilters]: 'Apply Filters',
  [UiKeys.tableSettings]: 'Table Settings',
  [UiKeys.applySettings]: 'Apply Settings',
  [UiKeys.resetToDefault]: 'Reset To Default',
  [IntlKeys.details]: 'Details',
  [IntlKeys.sftpAccount]: 'SFTP account',
  [IntlKeys.fileName]: 'File Name',
  [UiKeys.proceed]: 'Proceed',
  [UiKeys.discard]: 'Discard',
  //
  [IntlKeys.uiEdit]: 'Edit',
  [IntlKeys.uiResetPassword]: 'Reset Password',
  [IntlKeys.uiSaveAndClose]: 'Save and close',
  [IntlKeys.uiCloseWOSaving]: 'Close Without Saving',
  [IntlKeys.uiBack]: 'Back',
  [IntlKeys.uiCreated]: 'Created',
  [IntlKeys.uiUpdated]: 'Updated',
  [IntlKeys.uiValid]: 'Valid',
  [IntlKeys.uiNoOptions]: 'No options',
  [IntlKeys.uiNothingSelected]: 'Nothing selected',
  [IntlKeys.uiNothingFound]: 'Nothing found',
  [IntlKeys.uiOpenPassport]: 'Open Passport',
  [IntlKeys.sync]: 'Sync',
  [IntlKeys.validate]: 'Validate',
  [IntlKeys.process]: 'Process',

  // admin

  [IntlKeys.adminConceptsCreateNewConcept]: 'Create New Concept',

  [IntlKeys.adminUsersName]: 'Name',
  [IntlKeys.adminUsersFullName]: 'Full Name',
  [IntlKeys.adminUsersConcepts]: 'Concepts',
  [IntlKeys.adminUsersCompanyId]: 'Company Id',
  [IntlKeys.adminUsersOrganizations]: 'Organizations',
  [IntlKeys.adminUsersOrganizationIds]: 'Organization IDs',
  [IntlKeys.adminUsersUserPassportIds]: 'User Passport IDs',
  [IntlKeys.adminUsersCompanies]: 'Companies',
  [IntlKeys.adminUsersOrganizationName]: 'Organization Name',
  [IntlKeys.adminUsersPrefLang]: 'Preferred Language',
  [IntlKeys.adminUsersUid]: 'UID',
  [IntlKeys.adminUsersProvider]: 'Provider',
  [IntlKeys.adminUsersUserRole]: 'User Role',
  [IntlKeys.adminUsersUserTypeDescriptions]: 'User Role Description',
  [IntlKeys.adminUsersUserType]: 'User Type',
  [IntlKeys.adminUsersUserPermissionProfile]: 'Permission Profile',
  [IntlKeys.adminUsersUserPermissionProfileTitle]: 'title',
  [IntlKeys.adminUsersIsContactPerson]: 'Is Contact Person',
  [IntlKeys.adminUsersStatus]: 'Status',
  [IntlKeys.adminUsersSuspend]: 'Suspend',
  [IntlKeys.adminUsersActivate]: 'Activate',
  [IntlKeys.adminUsersDeleteUser]: 'Delete User',
  [IntlKeys.adminUsersSaveAndNotify]: 'Save and notify via email',
  [IntlKeys.adminUsersSaveChanges]: 'Save Changes',
  [IntlKeys.adminUsersCloseWOSaving]: 'CLOSE WITHOUT SAVING',
  [IntlKeys.adminUsersCreateNewUser]: 'Create New User',
  [IntlKeys.adminUsersCreateNewUserPassport]: 'Create New User Passport',
  [IntlKeys.adminUsersSimpleUser]: 'simple user',
  [IntlKeys.adminUsersContentAdmin]: 'content admin',
  [IntlKeys.adminUsersUserAdmin]: 'user admin',
  [IntlKeys.adminUsersNewUserPassport]: 'NEW USER PASSPORT',
  [IntlKeys.adminUsersSuspended]: 'suspended',
  [IntlKeys.adminUsersActive]: 'active',
  [IntlKeys.adminUsersInvited]: 'invited',
  [IntlKeys.adminUsersFarmer]: 'farmer',
  [IntlKeys.adminUsersTransporter]: 'transporter',
  [IntlKeys.adminUsersVeterinarian]: 'veterinarian',
  [IntlKeys.adminUsersLastSignInAt]: 'Last Sign In At',
  [IntlKeys.adminUsersSignInCount]: 'Sign In Count',
  [IntlKeys.userProfileId]: 'User Profile Id',
  [IntlKeys.userFullName]: 'User Full Name',
  [IntlKeys.userEmail]: 'User Email',
  [IntlKeys.doYouWantToSubmitPassport]: 'Do you want to submit your passport for check and approval? Please mind that you will not be able to edit it after submitting',
  [IntlKeys.noDefaultTemplateConfiguredAdmin]: 'Default template is not configured',
  [IntlKeys.noDefaultTemplateConfiguredUser]: 'Default template is not configured, please contact administrator',

  // permissions

  [IntlKeys.adminPermissionProfileTemplate]: 'Permission Profile Template',
  [IntlKeys.adminPermissionRules]: 'Permission Rules',
  [IntlKeys.permissionAccessTo]: 'Access to',

  [IntlKeys.adminPermissionsGroupDataset]: 'Dataset',
  [IntlKeys.adminPermissionsGroupApplications]: 'Applications',
  [IntlKeys.adminPermissionsAlwaysOn]: 'Always On',
  [IntlKeys.adminPermissionsAlwaysOff]: 'Always Off',
  [IntlKeys.adminPermissionsOrganizationTypePlaceholder]: 'Organization Type Or Visibility Name',
  [IntlKeys.adminPermissionsNotesPlaceholder]: 'Notes (e.g. organization type description)',

  [IntlKeys.adminPermissionsSourceNone]: '-',
  [IntlKeys.adminPermissionsSourceFtp]: 'FTP',
  [IntlKeys.adminPermissionsSourceUpload]: 'Manual Upload',
  [IntlKeys.adminPermissionsSourceEdiCircle]: 'EDI Circle (Other system)',
  [IntlKeys.adminPermissionsSourceJoindata]: 'Joindata (Other system)',
  [IntlKeys.adminPermissionsSourceOrganizations]: 'System',

  [IntlKeys.adminPermissionsStatusAlwaysOn]: 'Always on',
  [IntlKeys.adminPermissionsStatusAlwaysOff]: 'Always off',
  [IntlKeys.adminPermissionsStatusAllowedFrom]: 'Allowed from {from}',
  [IntlKeys.adminPermissionsStatusAllowedTo]: 'Allowed to {to}',
  [IntlKeys.adminPermissionsStatusAllowedFromTo]: 'Allowed from {from} to {to}',

  [IntlKeys.adminCreatePermissionProfileTemplate]: 'Create Permission Profile Template',
  [IntlKeys.adminEditPermissionProfileTemplate]: 'Edit Permission Profile Template',

  [IntlKeys.adminOrganizationsRegistrationName]: 'KVK Name',
  [IntlKeys.adminOrganizationsStreet]: 'Street',
  [IntlKeys.adminOrganizationsEdiCircleCode]: 'Edi Circle Code',
  [IntlKeys.adminOrganizationsPostcode]: 'Postcode',
  [IntlKeys.adminOrganizationsPlace]: 'Place',
  [IntlKeys.adminOrganizationsPhone]: '(Mobile) Phone Number',
  [IntlKeys.adminOrganizationsCertificationAuthority]: 'Certification Authority',
  [IntlKeys.adminOrganizationsFeedSuppliers]: 'Feed Suppliers',
  [IntlKeys.adminOrganizationsVisibilityRules]: 'Visibility Rules',
  [IntlKeys.adminOrganizationsNewUser]: 'New User',
  [IntlKeys.adminOrganizationsLastUpdate]: 'Last Update',
  [IntlKeys.adminOrganizationsBy]: 'By',
  [IntlKeys.adminOrganizationsOrganizationId]: 'Organization Id',
  [IntlKeys.adminOrganizationsFeedSupplierOther]: 'Feed Supplier (other)',
  [IntlKeys.adminOrganizationsCertificates]: 'Certifications',
  [IntlKeys.adminOrganizationsCertificatesOther]: 'Certifications (other)',
  [IntlKeys.adminOrganizationsGeneticSuppliers]: 'Genetics Supplier',
  [IntlKeys.adminOrganizationsGeneticSuppliersOther]: 'Genetics Supplier (other)',
  [IntlKeys.adminOrganizationsKiStation]: 'KI Station',
  [IntlKeys.adminOrganizationsKiStationOther]: 'KI Station (other)',
  [IntlKeys.adminOrganizationsSowLines]: 'Sow Lines',
  [IntlKeys.adminOrganizationsSowLineOther]: 'Sow Line (other)',
  [IntlKeys.adminOrganizationsBreedingProgram]: 'Breeding program',
  [IntlKeys.adminOrganizationsSireLines]: 'Sire Line',
  [IntlKeys.adminOrganizationsSireLineOther]: 'Sire Line (other)',
  [IntlKeys.adminOrganizationsVeterinaryPractice]: 'Veterinary Practice',
  [IntlKeys.adminOrganizationsVeterinaryPracticeOther]: 'Veterinary Practice (other)',
  [IntlKeys.adminOrganizationsFmsNames]: 'Farm Management System',
  [IntlKeys.adminOrganizationsFmsModuleAgrisysts]: 'FMS Module Agrisyst',
  [IntlKeys.adminOrganizationsFmsModuleAgroCoops]: 'FMS Module Agroscoop',
  [IntlKeys.adminOrganizationsFmsModuleAgrovisions]: 'FMS Module Agrovision',
  [IntlKeys.adminOrganizationsFeedingSystems]: 'Feeding System',
  [IntlKeys.adminOrganizationsFeedingSystemOther]: 'Feeding System (other)',
  [IntlKeys.adminOrganizationsClimateSystems]: 'Climate System',
  [IntlKeys.adminOrganizationsClimateSystemOther]: 'Climate System (other)',

  [IntlKeys.adminOrganizationMyUsers]: 'Users of my dashboard',
  [IntlKeys.adminOrganizationOtherUsers]: 'Users of My Data',
  [IntlKeys.adminOrganizationOtherUsersDescription]: 'Here you see which party has access to your data.',
  [IntlKeys.adminOrganizationAddExistingUsers]: 'Add existing users',
  [IntlKeys.adminOrganizationGetInTouch]: 'Get in Touch',
  [IntlKeys.adminOrganizationGetInTouchDescription]: 'Do you want to change something?',
  // prettier-ignore
  [IntlKeys.adminOrganizationDataSharingDescription]: 'Below is an overview of the applications that are available to you through JoinData permissions on your Dashboard. For each application you can see which data is used and for whom this data can be viewed. This data is only visible to other chain partners when the authorization status is active.',
  [IntlKeys.adminOrganizationDataSharingButton]: 'Data sharing details',

  [IntlKeys.adminPermissionApplyToAll]: 'Apply to all',
  [IntlKeys.adminUsersUserId]: 'User ID',
  [IntlKeys.adminRolesRoleId]: 'Role ID',
  [IntlKeys.userPassport]: 'Passport',
  [IntlKeys.userPassportId]: 'Passport ID',
  [IntlKeys.userPassportUbnCustomName]: 'UBN Custom Name',
  [IntlKeys.userPassportSetUbnCustomName]: 'Set UBN Custom Name',
  [IntlKeys.passportProfileId]: 'Passport Profile Id',

  // animal passport

  [IntlKeys.animalPassportAverageWeightKg]: 'Average Weight (kg)',
  [IntlKeys.animalPassportAnimalCategory]: 'Animal category',
  [IntlKeys.animalPassportDetails]: 'Details',
  [IntlKeys.animalPassportCustom]: 'Custom',

  [IntlKeys.animalPassportDataTabsFoodSafety]: 'Food Safety',
  [IntlKeys.animalPassportDataTabsAnimalWelfare]: 'Animal Welfare',
  [IntlKeys.animalPassportDataTabsProductIntegrity]: 'Product Integrity',
  [IntlKeys.animalPassportDataTabsSustainability]: 'Sustainability',

  [IntlKeys.animalPassportDataTabsCertification]: 'Certification',
  [IntlKeys.animalPassportDataTabsMedication]: 'Medication',
  [IntlKeys.animalPassportDataTabsFeed]: 'Feed',
  [IntlKeys.animalPassportDataTabsTransport]: 'Transport',
  [IntlKeys.animalPassportDataTabsFarm]: 'Farm',
  [IntlKeys.animalPassportDataTabsSlaughter]: 'Slaughter',
  [IntlKeys.animalPassportDataTabsGenetics]: 'Genetics',

  [IntlKeys.animalPassportCertificatesCertificate]: 'certificate',
  [IntlKeys.animalPassportCertificatesStatus]: 'Status',
  [IntlKeys.animalPassportCertificatesValidUntil]: 'Valid Until',

  [IntlKeys.animalPassportFarmDeathCause]: 'Death Cause',
  [IntlKeys.animalPassportFarmAnimalCount]: 'Animal count',
  [IntlKeys.animalPassportFarmAverageFeedType]: 'Feed type',
  [IntlKeys.animalPassportFarmAverageAnimalWeight]: 'Animal Weight',

  [IntlKeys.animalPassportFeedGmpType]: 'GMP type',
  [IntlKeys.animalPassportFeedItemQuantity]: 'item quantity',

  [IntlKeys.animalPassportGeneticsFemaleType]: 'female type',
  [IntlKeys.animalPassportGeneticsFemaleAmount]: 'female amount',
  [IntlKeys.animalPassportGeneticsMaleType]: 'male type',
  [IntlKeys.animalPassportGeneticsMaleAmount]: 'male amount',

  [IntlKeys.animalPassportMedicationAnimalLastExportDate]: 'Last export date',
  [IntlKeys.animalPassportMedicationAnimalPreviousExportDate]: 'previous export date',
  [IntlKeys.animalPassportMedicationAnimalLastDddTm]: 'Last ddd tm',
  [IntlKeys.animalPassportMedicationAnimalPreviousDddTm]: 'previous ddd tm',

  [IntlKeys.animalPassportSlaughterAnimalType]: 'Animal type',
  [IntlKeys.animalPassportSlaughterAnimalCount]: 'Animal count',
  [IntlKeys.animalPassportSlaughterCarcassCodeTotal]: `Carcass code: {carcassCode} total`,
  [IntlKeys.animalPassportSlaughterOrganCodeTotal]: 'Organ code: {organCode} total',

  [IntlKeys.animalPassportTransportNumberOfAnimals]: 'Number of animals',

  [IntlKeys.animalTypeGiltOrCastratedBoar]: 'Classified Gilt or Castrated boar',
  [IntlKeys.animalTypeBoar]: 'Classified as Boar',

  [IntlKeys.animalCategorySowsAndBreedingGilts]: 'Sows and breeding gilts',
  [IntlKeys.animalCategoryBreedingBoars]: 'Breeding boars',
  [IntlKeys.animalCategoryPiglets]: 'Piglets',
  [IntlKeys.animalCategorySlaughterPigs]: 'Slaughter pigs',
  [IntlKeys.animalCategorySlaughterSowsAndPiglets]: 'Slaughter sows and slaughter piglets',

  [IntlKeys.carcassCodePleurisy]: 'Pleurisy',
  [IntlKeys.carcassCodeInflamedSkin]: 'Inflamed Skin',
  [IntlKeys.carcassCodeInflamedLeg]: 'Inflamed Leg',

  [IntlKeys.organCodeLiverAffected]: 'Liver Affected',
  [IntlKeys.organCodeLiverDisapproved]: 'Liver Disapproved',
  [IntlKeys.organCodeLungDisapproved]: 'Lung Disapproved',

  [IntlKeys.dataNotToRate]: 'Not to Rate',
  [IntlKeys.dataNotClassified]: 'Non Classified',

  // dashboard

  [IntlKeys.dashboardFakeCategory]: 'FAKE_CATEGORY',
  [IntlKeys.dashboardApplications]: 'Applications',
  [IntlKeys.dashboardDataset]: 'Dataset',
  [IntlKeys.dashboardVriHealthBalance]: 'Health Balance',
  [IntlKeys.dashboardVriBalanceBase]: 'Balance w.r.t. Base',
  [IntlKeys.dashboardDeliveryMessages]: 'Delivery Messages',
  [IntlKeys.dashboardMovementEvents]: 'Movement Events',

  [IntlKeys.dashboardTooltipAllowed]: 'Allowed',
  [IntlKeys.dashboardTooltipFrom]: 'from',
  [IntlKeys.dashboardTooltipTo]: 'to',
  [IntlKeys.dashboardTooltipDataSources]: 'This application uses data from the following sources:',
  [IntlKeys.dashboardTooltipDetailedDataUsageInfo]: 'Detailed information about data usage',

  [IntlKeys.userPassportDraftAlert]: 'Organization passport has not been submitted yet, please go to \'My company\' to complete and submit your questionnaire',
  [IntlKeys.userPassportSubmittedAlert]: 'Organization passport approval is pending, please contact powerchain@impactbuying.com if you have any questions',
  [IntlKeys.userPassportRejectedAlert]: 'Organisation passport status is rejected, please refer to your email for more details or contact us at powerchain@impactbuying.com',

  // uploads

  [UploadsKeys.uploadFile]: 'Upload File',
  [UploadsKeys.dragNDrop]: 'Drag & Drop',
  [UploadsKeys.dndToUpload]: 'To uploads',
  [UploadsKeys.dndOr]: 'Or',
  [UploadsKeys.dndSelectFile]: 'Select File',
  [UploadsKeys.dndSupportedFormats]: 'Supported formats: .xls .xlsx',
  [UploadsKeys.dndSingleFile]: 'Please drag single file',
  [IntlKeys.uploadsDownloadTemplate]: 'Download Template',
  [IntlKeys.uploadsDndSupportedFormatsCsv]: 'Supported formats: .csv',
  [IntlKeys.discardFile]: 'Discard file',
  [IntlKeys.yourFile]: 'Your file',

  [UploadsKeys.uploadDaily]: 'Upload Daily',
  [UploadsKeys.uploadWeekly]: 'Upload Weekly',
  [UploadsKeys.uploadVriDatabase]: 'Upload VRI Database',
  [UploadsKeys.uploadHistory]: 'Upload History',

  [UploadsKeys.pleaseStandBy]: 'Please Stand by',
  [UploadsKeys.calculating]: 'calculating...',
  [UploadsKeys.nSeconds]: '{n} seconds',
  [UploadsKeys.uploadSuccessful]: 'Upload successful',
  [UploadsKeys.alreadyUploaded]: 'File already exist',
  [UploadsKeys.alreadyUploadedMsgConfirm]: 'This file is seems to be uploaded already, proceed anyway?',

  [UploadsKeys.fileName]: 'File Name',
  [UploadsKeys.fileSize]: 'File Size',
  [UploadsKeys.fileType]: 'File Type',
  [UploadsKeys.processingTime]: 'Processing Time',

  [UploadsKeys.nSelected]: '{n} selected',
  [UploadsKeys.sortedAscending]: 'sorted ascending',
  [UploadsKeys.sortedDescending]: 'sorted descending',
  [UploadsKeys.densePadding]: 'Dense padding',
  [UploadsKeys.filterList]: 'Filter list',

  [IntlKeys.uploadsUploadReferenceTables]: 'Upload Reference Tables',

  [IntlKeys.uploadsUploadCarbonFootprint]: 'Upload Carbon Footprint',

  [IntlKeys.uploadsUploadHFMOutput]: 'Upload HFM Output',

  // certificates

  [IntlKeys.certificatesBlk]: 'Beter Leven Keurmerk 1 ster',
  [IntlKeys.certificatesPigsTomorrow]: 'Varken van Morgen',
  [IntlKeys.certificatesIkb]: 'IKB Nederland Varkens',
  [IntlKeys.certificatesVionWelfare]: 'Vion Welfare',
  [IntlKeys.certificatesActive]: 'Active',
  [IntlKeys.certificatesAboutToExpire]: 'About to expire',
  [IntlKeys.certificatesExpired]: 'Expired',

  // certificates IKB

  [IntlKeys.certificateIKBUbn]: 'UBN',
  [IntlKeys.certificateIKBValidFrom]: 'Valid from',
  [IntlKeys.certificateIKBValidTo]: 'Valid to',
  [IntlKeys.certificateIKBType]: 'Type',

  // certificates kiwa daily

  [CertificatesKiwaDailyKeys.RegNumber]: 'Reg No.',
  [CertificatesKiwaDailyKeys.CountryCodeLoc]: 'Country code Location',
  [CertificatesKiwaDailyKeys.BusinessType]: 'Business type',
  [CertificatesKiwaDailyKeys.OwnTransport]: 'Own transport',
  [CertificatesKiwaDailyKeys.ValidUntil]: 'Valid until',
  [CertificatesKiwaDailyKeys.Remark]: 'Remark',
  [CertificatesKiwaDailyKeys.VC]: 'VC',
  [CertificatesKiwaDailyKeys.QSNumber]: 'QS number',
  [CertificatesKiwaDailyKeys.GGNr]: 'GG Nr.',
  [CertificatesKiwaDailyKeys.PigTomorrow]: 'Varken van Morgen', // NOT TRANSLATED
  [CertificatesKiwaDailyKeys.BLK]: 'BLK',
  [CertificatesKiwaDailyKeys.CertificateComment]: 'Certificate Comment',
  [CertificatesKiwaDailyKeys.SalmonellaInfo]: 'Salmonella info',

  //certificates kiwa weekly farms

  [CertificatesKiwaWeeklyFarms.UBN]: 'UBN',
  [CertificatesKiwaWeeklyFarms.Name]: 'Name',
  [CertificatesKiwaWeeklyFarms.Address]: 'Address',
  [CertificatesKiwaWeeklyFarms.PostalCode]: 'Postal Code',
  [CertificatesKiwaWeeklyFarms.Place]: 'Place',
  [CertificatesKiwaWeeklyFarms.Phone]: 'Phone',
  [CertificatesKiwaWeeklyFarms.Date]: 'Date',
  [CertificatesKiwaWeeklyFarms.Sows]: 'Sows',
  [CertificatesKiwaWeeklyFarms.Vlv]: 'Fattening Pigs (vlv)',
  [CertificatesKiwaWeeklyFarms.Program]: 'Program',
  [CertificatesKiwaWeeklyFarms.Remark]: 'Remark',
  [CertificatesKiwaWeeklyFarms.Type]: 'Type',

  // certificates kiwa weekly recognitions

  [CertificatesKiwaWeeklyRecognitions.UBN]: 'UBN',
  [CertificatesKiwaWeeklyRecognitions.Date]: 'Date',
  [CertificatesKiwaWeeklyRecognitions.Program]: 'Program',
  [CertificatesKiwaWeeklyRecognitions.Code]: 'Code',
  [CertificatesKiwaWeeklyRecognitions.Regnr]: 'Reg No.',
  [CertificatesKiwaWeeklyRecognitions.Name]: 'Name',

  // certificates kiwa weekly deviations

  [CertificatesKiwaWeeklyDeviations.UBN]: 'UBN',
  [CertificatesKiwaWeeklyDeviations.Program]: 'Program',
  [CertificatesKiwaWeeklyDeviations.Date]: 'Date',
  [CertificatesKiwaWeeklyDeviations.QuestionCode]: 'Question Code',
  [CertificatesKiwaWeeklyDeviations.Remark]: 'Remark',
  [CertificatesKiwaWeeklyDeviations.Answer]: 'Answer',

  // certificates welfare

  [CertificatesWelfareKeys.ubn]: 'UBN',
  [CertificatesWelfareKeys.validFrom]: 'Valid from',
  [CertificatesWelfareKeys.validTo]: 'Valid to',
  [CertificatesWelfareKeys.type]: 'Type',

  // VRI

  [VriKeys.vriRanking]: 'VRI Ranking',
  [VriKeys.goToVriReportingTool]: 'Go to VRI reporting tool',
  [VriKeys.vriTotalRanking]: 'VRI Total Ranking',
  [VriKeys.serialNumber]: 'Serial Number',
  [VriKeys.yourScoreLabel]: 'your score',
  [VriKeys.centsPerKilogram]: 'ct per kg',
  [VriKeys.perPig]: 'per pig',
  [VriKeys.vriEpgTitle]: 'VRI EPG',
  [VriKeys.vriHealthBalanceTitle]: 'VRI Health Balance',
  [VriKeys.vriBalanceBaseTitle]: 'VRI Balance w.r.t. Base',
  [VriKeys.chartUbn]: 'UBN',
  [VriKeys.chartName]: 'Name',
  [VriKeys.chartTotal]: 'Total',
  [VriKeys.chartEpg]: 'EPG',
  [VriKeys.chartClassification]: 'Classification',
  [VriKeys.chartHealth]: 'Health',
  [VriKeys.top20Label]: 'top 20%',
  [VriKeys.avgLabel]: 'avg',
  [VriKeys.averageLabel]: 'average',
  [VriKeys.pigsCapacity]: 'Pigs Capacity',
  [VriKeys.differenceLabel]: 'difference',
  [VriKeys.totalCompany]: 'Total Company',
  [VriKeys.database]: 'Database',
  [VriKeys.databaseDetails]: 'Database',
  [VriKeys.uploadedFile]: 'Uploaded Files',
  [IntlKeys.datapoint]: 'Datapoint',

  // VRI Balance Base

  [VriKeys.balanceBaseAverageWeightCorrection]: 'Avg. weight / Correction (ct/kg)',
  [VriKeys.balanceBaseAverageFatCorrection]: 'Avg. fat / Correction (ct/kg)',
  [VriKeys.balanceBaseAverageMuscleCorrection]: 'Avg. muscle / Correction (ct/kg)',
  [VriKeys.balanceBaseAverageMeatCorrection]: 'Avg. meat% / Correction (ct/kg)',

  // VRI values

  [VriKeys.id]: 'Id',
  [VriKeys.rating]: 'Concept',
  [VriKeys.vriSerialNumber]: 'VRI Serial number',
  [VriKeys.ubn]: 'UBN',
  [VriKeys.name]: 'Name',
  [VriKeys.total]: 'VRI total',
  [VriKeys.vriEpg]: 'VRI EPG',
  [VriKeys.epgSerialNumber]: 'Serial number',
  [VriKeys.growth]: 'Growth (g/day)',
  [VriKeys.feedIntakePerDay]: 'VC',
  [VriKeys.ewConversion]: 'EW-Conversion',
  [VriKeys.layingBigWeight]: 'Lay-on big weight (kg)',
  [VriKeys.loss]: 'Loss (%)',
  [VriKeys.epg]: 'EPG (€)',
  [VriKeys.vriClassification]: 'VRI Classification',
  [VriKeys.epgOrderNumber]: 'Epg Order Number',
  [VriKeys.balanceWithRespectToBase]: 'Balance w.r.t. Base (ct/kg)',
  [VriKeys.averageWeight]: 'Avg. slaughter weight (kg)',
  [VriKeys.averageWeightCorrection]: 'Avg. weight correction (ct/kg)',
  [VriKeys.averageFatThickness]: 'Avg. fat thickness (mm)',
  [VriKeys.averageBeaconCorrection]: 'Avg. fat correction (ct/kg)',
  [VriKeys.averageMuscleThickness]: 'Avg. muscle thickness (mm)',
  [VriKeys.averageMuscleCorrection]: 'Avg. muscle correction (ct/kg)',
  [VriKeys.averageMeat]: 'Avg. meat (%)',
  [VriKeys.averageMeatCorrection]: 'Avg. meat % correction (ct/kg)',
  [VriKeys.averageClassificationCorrection]: 'Avg. classification correction (ct/kg)',
  [VriKeys.averageSexAllowance]: 'Avg. sex charge (ct/kg)',
  [VriKeys.averageConceptSurcharge]: 'Avg. concept charge (ct/kg)',
  [VriKeys.pigsInDraft]: 'Pigs in concept (%)',
  [VriKeys.vriHealth]: 'VRI health',
  [VriKeys.epgNumber]: 'EPG Serial number',
  [VriKeys.euroPerPig]: 'Health Balance (€ per pig)',
  [VriKeys.approvedCarcass]: 'Approved Carcass (%)',
  [VriKeys.pleurisy]: 'Pleurisy',
  [VriKeys.inflamedSkin]: 'Inflamed skin',
  [VriKeys.inflamedLeg]: 'Inflamed paw',
  [VriKeys.approvedOrgan]: 'Approved Organ',
  [VriKeys.leverAfg]: 'Liver rejected',
  [VriKeys.liverAfg]: 'Liver rejected',
  [VriKeys.longAfg]: 'Long affected',
  [VriKeys.lungAfg]: 'Long affected',
  [VriKeys.healthy]: 'No carcass and organ abnormalities (%)',
  [VriKeys.vriTotal]: 'VRI Total',
  [VriKeys.lapSpeed]: 'Speed of round',
  [VriKeys.deliveredAnimals]: 'Delivered animals',
  [VriKeys.delivered]: 'Delivered kg',
  [VriKeys.vriBalanceWithRespectToBase]: 'Balance w.r.t. VRI Base',
  [VriKeys.endManufacturer]: 'End Manufacturer',
  [VriKeys.feedingSystem]: 'Feeding system',
  [VriKeys.reportDateTo]: 'Report Date To',
  [VriKeys.reportDateFrom]: 'Report Date From',
  [VriKeys.euroPerPigMax]: 'Euro per Pig Max',
  [VriKeys.euroPerPigMin]: 'Euro per Pig Min',
  [VriKeys.epgMax]: 'EPG Max',
  [VriKeys.epgMin]: 'EPG Min',
  [VriKeys.averageEpgWeight]: 'Living weight (kg)',
  [VriKeys.averageBirthWeight]: 'Raising weight piglets',
  [IntlKeys.vriFeedIntakePerDay]: 'VC',
  [IntlKeys.vriAverageNutrientEw]: 'EW-Conversion',
  [IntlKeys.vriRemovedPigsCount]: 'Loss (%)',
  [IntlKeys.vriAverageEpgWeight]: 'Living weight (kg)',
  [IntlKeys.vriAverageBirthWeight]: 'Raising weight piglets',
  [IntlKeys.vriAverageRoundLengthDays]: 'Average laying days',
  [IntlKeys.vriAveragePigsCount]: 'Average pigs count',
  [IntlKeys.vriEpgEuro]: 'EPG (€)',
  [IntlKeys.vriGrowth]: 'Growth (g/day)',

  // invoices

  [InvoicesKeys.currencyCode]: 'Currency Code',
  [InvoicesKeys.overviewType]: 'Overview Type',
  [InvoicesKeys.invoiceNumber]: 'Invoice Number',
  [InvoicesKeys.invoiceDate]: 'Invoice Date',
  [InvoicesKeys.uniqueMessageNumber]: 'Unique Message Number',
  [InvoicesKeys.supplierName]: 'Supplier Name',
  [InvoicesKeys.supplierStreet]: 'Supplier Postal Address Street',
  [InvoicesKeys.supplierHouseNumber]: 'Supplier House Number',
  [InvoicesKeys.supplierPlace]: 'Supplier Postal Address Place',
  [InvoicesKeys.supplierPostCode]: 'Supplier Postal Address Postcode',
  [InvoicesKeys.supplierEANAddressCode]: 'Supplier EAN Address Code',
  [InvoicesKeys.supplierBankAccount]: 'Supplier Bank Account Number',
  [InvoicesKeys.supplierIban]: 'Supplier IBAN',
  [InvoicesKeys.supplierVatNumber]: 'Supplier International VAT Number',
  [InvoicesKeys.customerName]: 'Customer Name',
  [InvoicesKeys.customerStreetAddress]: 'Customer Street Address',
  [InvoicesKeys.customerPostalAddressPlace]: 'Customer Postal Address Place',
  [InvoicesKeys.customerRelationNumber]: 'Customer Relation Number',
  [InvoicesKeys.deliveries]: 'Deliveries',

  // invoice details

  [InvoicesKeys.invoice]: 'Invoice',
  [InvoicesKeys.unit]: 'Unit',
  [InvoicesKeys.invoiceMessageId]: 'Invoice Message Id',
  [InvoicesKeys.lineNumber]: 'Line Number',
  [InvoicesKeys.deliveryDate]: 'Delivery Date',
  [InvoicesKeys.deliveryNoteNumber]: 'Delivery Note Number',
  [InvoicesKeys.deliveryNoteLineNumber]: 'Delivery Note Line Number',
  [InvoicesKeys.productQuantity]: 'Product Quantity',
  [InvoicesKeys.productArticleCode]: 'Product Article Code',
  [InvoicesKeys.productDescription]: 'Product Description',
  [InvoicesKeys.productArticleGroupCode]: 'Product Article Group Code',
  [InvoicesKeys.productArticleGroupCodeDescription]: 'Product Article Group Code Description',
  [InvoicesKeys.productEnergeticValue]: 'Product Energetic Value',

  // slaughter

  [IntlKeys.slaughterFarmerId]: 'Supplier Relation Number',
  [IntlKeys.slaughterMessageDate]: 'Message date',
  [IntlKeys.slaughterCurrency]: 'Currency',
  [IntlKeys.slaughterSlaughterHouseId]: 'Slaughterhouse ID',
  [IntlKeys.slaughterSlaughterDate]: 'Slaughter date',
  [IntlKeys.slaughterSlaughters]: 'Slaughters',
  [IntlKeys.slaughterSlaughterPlaceId]: 'Slaughter location number',
  [IntlKeys.slaughterUbnList]: 'Supplier ID',
  [IntlKeys.slaughterMessageSequenceNumber]: 'Message sequence number',
  [IntlKeys.slaughterAnimalsSuppliedCount]: 'Number of animals supplied',

  // slaughter details

  [IntlKeys.slaughterSlaughterMessageId]: 'Slaughter Message ID',
  [IntlKeys.slaughterSlaughterMessage]: 'Slaughter Message',
  [IntlKeys.slaughterSlaughterKey]: 'Slaughter ID',
  [IntlKeys.slaughterAnimalType]: 'Slaughterhouse animal type',
  [IntlKeys.slaughterCarcassCode]: 'Carcass abnormalities',
  [IntlKeys.slaughterAnimalWeight]: 'Slaughtered weight (kg)',
  [IntlKeys.slaughterOrganCode]: 'Organ abnormalities',
  [IntlKeys.slaughterMuscleThickness]: 'Muscle thickness (mm)',
  [IntlKeys.slaughterFatThickness]: 'Fat thickness (mm)',
  [IntlKeys.slaughterAfterCorrectionPigAmount]: 'Revenue pig after correction',
  [IntlKeys.slaughterContainerNumber]: 'Animal identification',
  [IntlKeys.slaughterMeat]: 'Meat percentage',
  [IntlKeys.slaughterAnimalIdentificationType]: 'Animal identification type',

  // slaughter price corrections

  [IntlKeys.slaughterPriceCorrections]: 'Slaughter Price Corrections',

  [IntlKeys.slaughterPCFinancialResultId]: 'Financial result ID',
  [IntlKeys.slaughterPCFinancialResultDateTime]: 'Financial result date time',
  [IntlKeys.slaughterPCSlaughterHouseTypeCode]: 'Slaughter house type code',
  [IntlKeys.slaughterPCSlaughterHouseValue]: 'Slaughter house value',
  [IntlKeys.slaughterPCTraderTypeCode]: 'Trader type code',
  [IntlKeys.slaughterPCTraderValue]: 'Trader value',
  [IntlKeys.slaughterPCAnimalTypeCode]: 'Animal type code',
  [IntlKeys.slaughterPCAnimalValue]: 'Animal value',
  [IntlKeys.slaughterPCNumberOfAnimals]: 'Number of animals',
  [IntlKeys.slaughterPCCurrencyCode]: 'Currency code',

  // slaughter price corrections

  [IntlKeys.slaughterChargeOrPremiums]: 'Slaughter Charge Or Premiums',

  [IntlKeys.slaughterCOPCodeListId]: 'Code list ID',
  [IntlKeys.slaughterCOPCode]: 'Code',
  [IntlKeys.slaughterCOPBaseCode]: 'Base code',
  [IntlKeys.slaughterCOPAmount]: 'Amount',
  [IntlKeys.slaughterCOPVatPercentage]: 'VAT percentage',
  [IntlKeys.slaughterCOPVatAmount]: 'VAT amount',

  // genetics

  [GeneticKeys.ubn]: 'Customer number',
  [GeneticKeys.semenProducerUbn]: 'Supplier number',
  [GeneticKeys.deliveryDate]: 'Delivery date',
  [GeneticKeys.maleType]: 'Sire line',
  [GeneticKeys.maleAmount]: 'Sire line dose',
  [GeneticKeys.femaleType]: 'Paternal line',
  [GeneticKeys.femaleAmount]: 'Paternal line dose',

  // organizations

  [IntlKeys.createOrganization]: 'Create New Organization',
  [IntlKeys.organizationPlural]: 'Organizations',
  [IntlKeys.organizationChangeBanner]: 'Click to change banner',
  [IntlKeys.invalidFileExtension]: 'You are not allowed to upload "{extension}" files, allowed types: {whiteList}',
  [IntlKeys.draftPassports]: 'passport(s) in draft',
  [IntlKeys.submittedPassports]: 'passport(s) approval pending',
  [IntlKeys.approvedPassports]: 'passport(s) approved',
  [IntlKeys.rejectedPassports]: 'passport(s) rejected',
  //
  [IntlKeys.organizationsKvkName]: 'KVK Name',
  [IntlKeys.organizationsCertificatesOther]: 'Other Certificates',
  [IntlKeys.organizationsGeneticSuppliers]: 'Genetic Suppliers',
  [IntlKeys.organizationsGeneticSupplierOther]: 'Other Genetic Supplier',
  [IntlKeys.organizationsKiStations]: 'KI Stations',
  [IntlKeys.organizationsKiStationOther]: 'Other KI Station',
  [IntlKeys.organizationsSowLines]: 'Sow Lines',
  [IntlKeys.organizationsSowLineOther]: 'Other Sow Line',
  [IntlKeys.organizationsBreedingProgram]: 'Breeding Program',
  [IntlKeys.organizationsSireLines]: 'Sire Lines',
  [IntlKeys.organizationsSireLineOther]: 'Other Sire Line',
  [IntlKeys.organizationsVeterinaryPractice]: 'Veterinary Practice',
  [IntlKeys.organizationsVeterinaryPracticeOther]: 'Other Veterinary Practice',
  [IntlKeys.organizationsFeedSupplier]: 'Feed Supplier',
  [IntlKeys.organizationsFeedSupplierOther]: 'Other Feed Supplier',
  [IntlKeys.organizationsFmsNames]: 'Farm Management System',
  [IntlKeys.organizationsFmsModuleAgrisysts]: 'FMS Module Agrisyst',
  [IntlKeys.organizationsFmsModuleAgroCoops]: 'FMS Module Agroscoop',
  [IntlKeys.organizationsFmsModuleAgrovisions]: 'FMS Module Agrovisions',
  [IntlKeys.organizationsFeedingSystems]: 'Feeding Systems',
  [IntlKeys.organizationsFeedingSystemOther]: 'Other Feeding System',
  [IntlKeys.organizationsClimateSystems]: 'Climate Systems',
  [IntlKeys.organizationsClimateSystemOther]: 'Other Climate System',
  [IntlKeys.organizationsCompanyName]: 'Company Name',
  [IntlKeys.organizationsAddressLine1]: 'Address Line 1',
  [IntlKeys.organizationsAddressLine2]: 'Address Line 2',
  [IntlKeys.organizationsPostalCode]: 'Postal Code',
  [IntlKeys.organizationsCity]: 'City',
  [IntlKeys.organizationsTelephoneNumber]: 'Telephone Number',
  [IntlKeys.organizationsVatNumber]: 'VAT Number',
  [IntlKeys.organizationsUsers]: 'Organization Users',
  [IntlKeys.organizationsPermissionProfile]: 'Permission Profile',

  // organizations userPassports

  [IntlKeys.organizationsType]: 'Type',
  [IntlKeys.organizationsConcepts]: 'Concepts',
  [IntlKeys.organizationsKvk]: 'KVK',
  [IntlKeys.organizationsGln]: 'GLN',
  [IntlKeys.organizationsUbn]: 'UBN',
  [IntlKeys.organizationsVionRelationNumber]: 'Client Relation Number',
  [IntlKeys.organizationsName]: 'Name',
  [IntlKeys.organizationsRegistrationName]: 'Registration name (KVK)',
  [IntlKeys.organizationsVatNum]: 'VAT No.',
  [IntlKeys.organizationsAddressStreet]: 'Address: street',
  [IntlKeys.organizationsAddressPostcode]: 'Address: postcode',
  [IntlKeys.organizationsAddressPlace]: 'Address: place',
  [IntlKeys.organizationsPhone]: 'Phone',
  [IntlKeys.organizationsEmail]: 'Contact Email',
  [IntlKeys.organizationsCertificationAuthority]: 'Certification authority',
  [IntlKeys.organizationsFeedSuppliers]: 'Feed Suppliers',
  [IntlKeys.organizationsVeterinarian]: 'Veterinarian',
  [IntlKeys.organizationsTypeOfCompany]: 'Company type',

  // delivery messages

  [IntlKeys.deliveryHeaderUniqueMessageNumber]: 'Header Unique Message Number',
  [IntlKeys.deliveryHeaderAssociationAssignedCode]: 'Header Association Assigned Code',
  [IntlKeys.deliveryHeaderMessageSentDate]: 'Header Message sent date',
  [IntlKeys.deliveryHeaderSenderId]: 'Header SenderId',
  [IntlKeys.deliveryHeaderMessageVersionNumber]: 'Header Message version number',
  [IntlKeys.deliverySupplierNumber]: 'Supplier number',
  [IntlKeys.deliverySupplierName]: 'Supplier name',
  [IntlKeys.deliverySupplierStreetName]: 'Supplier street name',
  [IntlKeys.deliverySupplierHouseNumber]: 'Supplier house number',
  [IntlKeys.deliverySupplierPostalCode]: 'Supplier postal code',
  [IntlKeys.deliverySupplierCityName]: 'Supplier city name',
  [IntlKeys.deliverySupplierCountry]: 'Supplier country',
  [IntlKeys.deliverySupplierPhoneNumber]: 'Supplier phone number',
  [IntlKeys.deliverySupplierEmailAddress]: 'Supplier email address',
  [IntlKeys.deliverySupplierUrl]: 'Supplier URL',
  [IntlKeys.deliverySupplierVat]: 'Supplier VAT',
  [IntlKeys.deliveryCustomerNumber]: 'Customer number',
  [IntlKeys.deliveryCustomerName]: 'Customer name',
  [IntlKeys.deliveryCustomerStreetName]: 'Customer street name',
  [IntlKeys.deliveryCustomerHouseNumber]: 'Customer house number',
  [IntlKeys.deliveryCustomerPostalCode]: 'Customer postal code',
  [IntlKeys.deliveryCustomerCityName]: 'Customer city name',
  [IntlKeys.deliveryCustomerCountry]: 'Customer country',

  // delivery segment

  [IntlKeys.deliveryUniqueKeyReceipt]: 'Unique key receipt',
  [IntlKeys.deliveryReceiptNumber]: 'Receipt number',
  [IntlKeys.deliveryReceiptDate]: 'Receipt date',
  [IntlKeys.deliveryReceiptPrintDate]: 'Receipt print date',
  [IntlKeys.deliveryUbnNumber]: 'UBN Number',
  [IntlKeys.deliveryStreetnameDeliveryAddress]: 'Streetname delivery address',
  [IntlKeys.deliveryCityNameDeliveryAddress]: 'City name delivery address',
  [IntlKeys.deliveryPostcodeDeliveryAddress]: 'Postcode delivery address',
  [IntlKeys.deliveryCountryDeliveryAddress]: 'Country delivery address',
  [IntlKeys.deliveryOrderNumber]: 'Order number',
  [IntlKeys.deliveryDeliveryMethod]: 'Delivery method',
  [IntlKeys.deliveryBrsNumber]: 'BRS number',

  // delivery segment contents

  [IntlKeys.deliveryNoteLineNumber]: 'Delivery note line number',
  [IntlKeys.deliveryArticleNumber]: 'Article number',
  [IntlKeys.deliveryArticleDescription]: 'Article description',
  [IntlKeys.deliveryNetQuantityOfProductDelivered]: 'Net quantity of product delivered',
  [IntlKeys.deliveryQuantityUnit]: 'Quantity unit',
  [IntlKeys.deliveryArticleGroupCode]: 'Article group code',
  [IntlKeys.deliveryQuantityOfProductOrdered]: 'Quantity of product ordered',
  [IntlKeys.deliveryBranchNumber]: 'Branch number',
  [IntlKeys.deliveryBranchName]: 'Branch name',
  [IntlKeys.deliveryGmpNumber]: 'GMP number',
  [IntlKeys.deliveryInvoiceNumber]: 'Invoice number',
  [IntlKeys.deliveryBaseContent]: 'Base content',
  [IntlKeys.deliveryNutrientN]: 'Nutrient-N',
  [IntlKeys.deliveryNutrientP]: 'Nutrient-P',
  [IntlKeys.deliveryNutrientRe]: 'Nutrient-RE',
  [IntlKeys.deliveryNutrientFos]: 'Nutrient-FOSFAATP2O5',
  [IntlKeys.deliveryNutrientVem]: 'Nutrient-VEM',
  [IntlKeys.deliveryNutrientVevi]: 'Nutrient-VEVI',
  [IntlKeys.deliveryEnergyValueEw]: 'Energy value (EW)',
  [IntlKeys.deliveryNutrientDs]: 'Nutrient-DS',
  [IntlKeys.deliveryNutrientNdf]: 'Nutrient-NDF',
  [IntlKeys.deliveryNutrientStarch]: 'Nutrient-Starch',
  [IntlKeys.deliveryNutrientEf00]: 'Nutrient-EF00ProcSnijmais',
  [IntlKeys.deliveryNutrientEf40]: 'Nutrient-EF40ProcSnijmais',
  [IntlKeys.deliveryNutrientEf80]: 'Nutrient-EF80ProcSnijmais',
  [IntlKeys.deliveryNutrientCo2]: 'Nutrient-CO2EmOffFarm',
  [IntlKeys.deliveryNutrientVCos]: 'Nutrient-VCos',
  [IntlKeys.deliveryNutrientVCre]: 'Nutrient-VCre',
  [IntlKeys.deliveryAnimalCategoryCode]: 'Animal category code',
  [IntlKeys.deliveryFeedTypeCode]: 'Feed type code',
  [IntlKeys.deliveryFeedTypeCodeKlw]: 'Feed type code KLW',
  [IntlKeys.deliveryRas]: 'RAS',

  // veterinarian data

  [IntlKeys.veterinaryExportDate]: 'Date',
  [IntlKeys.veterinaryUbn]: 'UBN',
  [IntlKeys.veterinaryCompanyName]: 'Name',
  [IntlKeys.veterinaryStreetAddress]: 'Address (Street)',
  [IntlKeys.veterinaryPlaceAddress]: 'Address (Place)',
  [IntlKeys.veterinaryPeriod]: 'Period',
  [IntlKeys.veterinaryAnimalCategory]: 'Animal Category',
  [IntlKeys.veterinaryDddTm]: 'DDD of period',
  [IntlKeys.veterinaryDeliveryDate]: 'Delivery Date',
  [IntlKeys.veterinaryRegistrationDate]: 'Registration Date',
  [IntlKeys.veterinaryVeterinarianName]: 'Name of Veterinarian',
  [IntlKeys.veterinaryEan]: 'EAN',
  [IntlKeys.veterinaryRegistrationNumberNl]: 'Registration Number (NL)',
  [IntlKeys.veterinaryChoice]: 'Choice',
  [IntlKeys.veterinaryProductName]: 'Name of Product',
  [IntlKeys.veterinaryPackagesCount]: 'Number of Packages',
  [IntlKeys.veterinaryCapacity]: 'Content (ml/gr/pieces)',
  [IntlKeys.veterinaryTotal]: 'Total',
  [IntlKeys.veterinaryDosingFactor]: 'Dose per ml/gr per 100 kg per day',
  [IntlKeys.veterinaryAvgAnimalsNumber]: 'Average Number of Animals',
  [IntlKeys.veterinaryWeightPerAnimal]: 'Calculated Weight per Animal',
  [IntlKeys.veterinaryDddDaily]: 'DDD per Delivery',

  // movement events

  [MovementEventsKeys.eventId]: 'Event ID',
  [MovementEventsKeys.eventType]: 'Event Type',
  [MovementEventsKeys.eventDateTime]: 'Event Date',
  [MovementEventsKeys.animalCategory]: 'Animal Category',
  [MovementEventsKeys.animalCount]: 'Animal Count',
  [MovementEventsKeys.transportId]: 'Transport ID',
  [MovementEventsKeys.groupDate]: 'Group Data',
  [MovementEventsKeys.kvk]: 'KVK',
  [MovementEventsKeys.ubn]: 'UBN',
  [MovementEventsKeys.destination]: 'Destination',
  [MovementEventsKeys.origin]: 'Origin',
  [MovementEventsKeys.eventLocationId]: 'Event Location ID',
  [MovementEventsKeys.eventIdMatch]: 'Event ID Match',

  // farm home
  [FarmHomeKeys.farmProfile]: 'Farm Profile',
  [IntlKeys.farmStableData]: 'Stable data',
  [FarmHomeKeys.stableRegistrations]: 'Stable registrations',

  // farm events

  [FarmEventsKeys.ubn]: 'UBN',
  [FarmEventsKeys.stableName]: 'Stable Name',
  [FarmEventsKeys.type]: 'Type',
  [FarmEventsKeys.action]: 'Action',
  [FarmEventsKeys.lastModifiedBy]: 'Last Modified By',

  // farm reports

  [FarmReportKeys.supportTitle]: 'Support',
  [FarmReportKeys.deathsTitle]: 'Deaths',
  [FarmReportKeys.deliveryTitle]: 'Delivery',
  [FarmReportKeys.feedConsumptionTitle]: 'Feed Consumption',
  [FarmReportKeys.stable]: 'Stable',
  [FarmReportKeys.department]: 'Department',
  [FarmReportKeys.originUbn]: 'Origin UBN Multiplier',
  [FarmReportKeys.amount]: 'Amount',
  [FarmReportKeys.weight]: 'Weight',
  [FarmReportKeys.date]: 'Date',
  [FarmReportKeys.geneticMother]: 'Genetics Mother',
  [FarmReportKeys.geneticFather]: 'Genetics Father',
  [FarmReportKeys.vaccination]: 'Vaccination',
  [FarmReportKeys.notes]: 'Notes (optional)',
  [FarmReportKeys.causeOfDeath]: 'Cause of Death',
  [FarmReportKeys.actualWeightOfFeedType]: 'Actual Weight of Feed Type',
  [FarmReportKeys.startFeed]: 'Start Feed',
  [FarmReportKeys.growthFeed]: 'Growth Feed',
  [FarmReportKeys.finishFeed]: 'Finish Feed',
  [FarmReportKeys.departmentNotFound]: 'First create a stable to continue',

  // farm stables

  [FarmStablesKeys.selectLocationTitle]: 'Select location',
  [FarmStablesKeys.stableNumber]: 'Number of stalls in your location',

  [FarmStablesKeys.modifyStableTitle]: 'Create/Edit Stable {id}',
  [FarmStablesKeys.stableName]: 'Stable Name',
  [FarmStablesKeys.animalCategory]: 'Animal Category',
  [FarmStablesKeys.weaningPiglets]: 'Weaning Piglets',
  [FarmStablesKeys.sows]: 'Sows',
  [FarmStablesKeys.finishers]: 'Finishers',
  [FarmStablesKeys.feeding]: 'Feeding',
  [FarmStablesKeys.feedType]: 'Feed Type',
  [FarmStablesKeys.dryFoodLimited]: 'Dry food Limited',
  [FarmStablesKeys.dryFoodUnlimited]: 'Dry food Unlimited',
  [FarmStablesKeys.liquidFoodLimited]: 'Liquid feed Limited',
  [FarmStablesKeys.liquidFoodUnlimited]: 'Liquid feed Unlimited',
  [FarmStablesKeys.deleteStable]: 'Delete stable',
  [FarmStablesKeys.editDepartments]: 'Edit Departments',

  [FarmStablesKeys.departments]: 'Departments',
  [FarmStablesKeys.departmentsOptionalNote]: 'optional, only when finishers is selected',

  [FarmStablesKeys.numberOfDepartments]: 'Number of departments',
  [FarmStablesKeys.placesPerDepartment]: 'Number of places per department',
  [FarmStablesKeys.placesPerDepartmentAverage]: 'Number of places per department (average)',
  [FarmStablesKeys.editDepartmentButtonText]: 'can be used when the numbers differ per department',

  // joindata

  [IntlKeys.purposePlural]: 'Purposes',
  [IntlKeys.participationPlural]: 'Participations',
  [IntlKeys.requestParticipations]: 'Request Participations',
  [IntlKeys.requestedParticipations]: 'Requested Participations',
  [IntlKeys.unmatchedOrganizations]: 'Unmatched organizations',

  [IntlKeys.joindataPurposeName]: 'Purpose Name',
  [IntlKeys.joindataParticipationGrantedOn]: 'Granted On',
  [IntlKeys.joindataParticipationStartDate]: 'Participation Start date',
  [IntlKeys.joindataParticipationStatus]: 'Participation Status',
  [IntlKeys.joindataParticipationValid]: 'Is Participation Valid',
  [IntlKeys.joindataRestrictionType]: 'Restriction type',
  [IntlKeys.joindataRestrictionDescription]: 'Restriction description',
  [IntlKeys.joindataRestrictionStart]: 'Restriction start',
  [IntlKeys.joindataContentStartDate]: 'Content Start Date',
  [IntlKeys.joindataExternalParticipationId]: 'External Participation Id',

  // Purpose Details

  [IntlKeys.purposeStatus]: 'Purpose status',
  [IntlKeys.purposeDetails]: 'Details',
  [IntlKeys.farmManagementData]: 'Farm Management Data',
  [IntlKeys.deliveryNotices]: 'Delivery Notices',
  //
  [IntlKeys.purposeDashboardP1]: 'Kwaliteitscertificaten van controle-instanties',
  [IntlKeys.purposeDashboardP2]: 'Elektronisch slachtbericht',
  [IntlKeys.purposeDashboardP3]: 'Sperma leveringen van de geneticaleverancier',
  [IntlKeys.purposeDashboardP4]: 'Voer leveringsberichten',
  [IntlKeys.purposeDashboardP5]: 'I&R Dierverplaatsingsberichten',
  [IntlKeys.purposeDashboardP6]: 'Leveringen van diergeneesmiddelen en vaccins',
  [IntlKeys.purposeDashboardP7]: 'Farmmanagement systeem',
  [IntlKeys.purposeVriToolP1]: 'Aantal opgelegde dieren',
  [IntlKeys.purposeVriToolP2]: 'Aantal afgeleverde dieren',
  [IntlKeys.purposeVriToolP3]: 'Aantal dode dieren',
  [IntlKeys.purposeVriToolP4]: 'Datum van opleggen',
  [IntlKeys.purposeVriToolP5]: 'Datum van afleveren',
  [IntlKeys.purposeVriToolP6]: 'Gemiddelde opleggewicht',
  [IntlKeys.purposeVriToolP7]: 'Voerlevering aan varkenshouder',
  [IntlKeys.purposeVriToolP8]: 'Samenstelling voedertype',
  [IntlKeys.purposeVriToolP9]: 'Gemiddeld geslacht gewicht',
  [IntlKeys.purposeVriToolP10]: 'Orgaanafwijkingen',
  [IntlKeys.purposeVriToolP11]: 'Karkasafwijkingen',
  [IntlKeys.purposeVriToolP12]: 'Geslacht gewicht',
  [IntlKeys.purposeVriToolP13]: 'Spierdikte',
  [IntlKeys.purposeVriToolP14]: 'Spekdikte',
  [IntlKeys.purposeVriToolP15]: 'Vleespercentage',
  [IntlKeys.purposeVriToolP16]: 'Gewicht correctie',
  [IntlKeys.purposeVriToolP17]: 'Gem spier correctie',
  [IntlKeys.purposeVriToolP18]: 'Gem spek correctie',
  [IntlKeys.purposeVriToolP19]: 'Gem vlees% correctie',
  [IntlKeys.purposeVriToolP20]: 'Gem Seksetoeslag',
  [IntlKeys.purposeVriToolP21]: 'Gem concept toeslag',
  [IntlKeys.purposeVriFarmingGroupP1]: 'Certificeringen',
  [IntlKeys.purposeVriFarmingGroupP2]: 'Aankoop vaccins',
  [IntlKeys.purposeVriFarmingGroupP3]: 'Aankoop medicijnen',
  [IntlKeys.purposeVriFarmingGroupP4]: 'DierDagDosering',
  [IntlKeys.purposeVriFarmingGroupP5]: 'Certificering voedertype',
  [IntlKeys.purposeVriFarmingGroupP6]: 'Voerlevering samenstelling voedertype',
  [IntlKeys.purposeVriFarmingGroupP7]: 'Voerlevering aan varkenshouder',
  [IntlKeys.purposeVriFarmingGroupP8]: 'Herkomst voerlevering aan varkenshouder',
  [IntlKeys.purposeVriFarmingGroupP9]: 'Herkomst grondstoffen',
  [IntlKeys.purposeVriFarmingGroupP10]: 'Herkomstgegevens fokmateriaal',
  [IntlKeys.purposeVriFarmingGroupP11]: 'Herkomst biggen',
  [IntlKeys.purposeVriFarmingGroupP12]: 'Herkomst vleesvarkens',
  [IntlKeys.purposeVriFarmingGroupP13]: 'Locatie slachterij',
  [IntlKeys.purposeVriFarmingGroupP14]: 'Spermatype eindbeer',
  [IntlKeys.purposeVriFarmingGroupP15]: 'Spermatype fokkerij',
  [IntlKeys.purposeVriFarmingGroupP16]: 'Leverdatum sperma bij vermeerderaar/gesloten bedrijf',
  [IntlKeys.purposeVriFarmingGroupP17]: 'Aantal dosissen',
  [IntlKeys.purposeVisionDashboardGroupP1]: 'Certificeringen (e.g. IKB, Beter Leven Keurmerk)',
  [IntlKeys.purposeVisionDashboardGroupP2]: 'Beoordeling van controle resultaten door de controle instantie',
  [IntlKeys.purposeVisionDashboardGroupP3]: 'Elektronisch slachtbericht',
  [IntlKeys.purposeVisionDashboardGroupP4]: 'Slachtcorrectiebericht',
  [IntlKeys.purposeVisionDashboardGroupP5]: 'Locatiegegevens van de herkomst van sperma per levering',
  [IntlKeys.purposeVisionDashboardGroupP6]: 'Spermatype eindbeer',
  [IntlKeys.purposeVisionDashboardGroupP7]: 'Spermatype fokkerij',
  [IntlKeys.purposeVisionDashboardGroupP8]: 'Leverdatum sperma bij vermeerderaar/gesloten bedrijf',
  [IntlKeys.purposeVisionDashboardGroupP9]: 'Aantal dosissen',
  [IntlKeys.purposeVisionDashboardGroupP10]: 'Certificering voedertype',
  [IntlKeys.purposeVisionDashboardGroupP11]: 'Voerlevering samenstelling voedertype (grondstoffen energiewaarde)',
  [IntlKeys.purposeVisionDashboardGroupP12]: 'Voerlevering aan varkenshouder (type voer, kg)',
  [IntlKeys.purposeVisionDashboardGroupP13]: 'Herkomst voerlevering aan varkenshouder (GLN voerfabriek)',
  [IntlKeys.purposeVisionDashboardGroupP14]: 'Herkomstgegevens fokmateriaal (UBN/GLN of locatie (herkomst gelten-> vermeerderaar))',
  [IntlKeys.purposeVisionDashboardGroupP15]: 'Herkomst biggen = UBN/GLN of locatie (aanvoer vleesvarkensbedrijf)',
  [IntlKeys.purposeVisionDashboardGroupP16]: 'Herkomst vleesvarkens = UBN/GLN (aanvoer slachterij)',
  [IntlKeys.purposeVisionDashboardGroupP17]: 'Locatie slachterij (UBN/GLN)',
  [IntlKeys.purposeVisionDashboardGroupP18]: 'Aankoop vaccins',
  [IntlKeys.purposeVisionDashboardGroupP19]: 'Aankoop medicijnen',
  [IntlKeys.purposeVisionDashboardGroupP20]: 'DierDagDosering',
  [IntlKeys.purposeVisionDashboardGroupP21]: 'Ritinformatie van het transport naar de slachterij',
  [IntlKeys.purposeVisionDashboardGroupP22]: 'Kengetallen uit het Farmmanagement systeem',
  [IntlKeys.purposeVisionDashboardGroupP23]: 'Ingevoerde gegevens uit het Invoerportaal',
  [IntlKeys.titleKwaliteitscertificaten]: 'Kwaliteitscertificaten',
  [IntlKeys.titleSlachtAndSaldo]: 'Slacht & Saldo correcties',
  [IntlKeys.titleDierverplaatsingsberichten]: 'I&R Dierverplaatsingsberichten',
  [IntlKeys.titleDierenartsData]: 'Dierenarts Data',
  [IntlKeys.titleTransports]: 'Transporten',
  [IntlKeys.purposeCo2FootprintPigFarmingGroupP1]: 'Gemiddeld aantal geproduceerde biggen per zeug per jaar',
  [IntlKeys.purposeCo2FootprintPigFarmingGroupP2]: 'RVO Dierverplaatsingen',
  [IntlKeys.purposeCo2FootprintPigFarmingGroupP3]: 'Totale aantal verkochte zeugen en biggen per jaar (voor de toewijzing van de CO2-footprint aan biggen en zeugen wordt de verhouding tussen de economische waarde van biggen en zeugen gebruikt)',
  [IntlKeys.purposeCo2FootprintPigFarmingGroupP4]: 'Gemiddeld aantal aanwezige zeugen en biggen',
  [IntlKeys.purposeCo2FootprintPigFarmingGroupP5]: 'RVO Mesttransporten',
  [IntlKeys.purposeCo2FootprintPigFarmingGroupP6]: 'Type mestopslag',
  [IntlKeys.purposeCo2FootprintPigFarmingGroupP7]: 'RAV codes (type huisvesting)',
  [IntlKeys.purposeCo2FootprintPigFarmingGroupP8]: 'Type elektriciteit',
  [IntlKeys.purposeCo2FootprintPigFarmingGroupP9]: 'Elektriciteitsverbruik en -productie',
  [IntlKeys.purposeCo2FootprintPigFarmingGroupP10]: 'Gasverbruik',
  [IntlKeys.purposeCo2FootprintPigFarmingGroupP11]: 'Waterverbruik (via de meter en eigen bron)',
  [IntlKeys.purposeCo2FootprintPigFarmingGroupP12]: 'Totale energieverbruik gesplitst voor zakelijk en privé',
  [IntlKeys.purposeCo2FootprintPigFarmingGroupP13]: 'Gemiddeld voerverbruik per aanwezige zeug',
  [IntlKeys.purposeCo2FootprintPigFarmingGroupP14]: 'Slachtresultaten - Totaal aantal kilo (levend gewicht) geleverd aan slachterij',
  [IntlKeys.purposeCo2FootprintPigFarmingGroupP15]: 'RVO Dierverplaatsingen - Aantal leveringen van biggen aan vleesvarkensbedrijf',
  [IntlKeys.purposeCo2FootprintPigFarmingGroupP16]: 'Gemiddeld gewicht van aangeleverde biggen op vleesvarkensbedrijf',
  [IntlKeys.purposeCo2FootprintPigFarmingGroupP17]: 'Gemiddeld aantal vleesvarkens',
  [IntlKeys.purposeCo2FootprintPigFarmingGroupP18]: 'Voerconversie',
  [IntlKeys.purposeCo2FootprintPigFarmingGroupP19]: 'Groei per dag',
  [IntlKeys.purposeCo2FootprintPigFarmingGroupP20]: 'Uitval van dieren',
  [IntlKeys.purposeCo2FootprintPigFarmingGroupP21]: 'RVO Mesttransporten',
  [IntlKeys.purposeCo2FootprintPigFarmingGroupP22]: 'Type mestopslag',
  [IntlKeys.purposeCo2FootprintPigFarmingGroupP23]: 'RAV codes (type huisvesting)',
  [IntlKeys.purposeCo2FootprintPigFarmingGroupP24]: 'Type elektriciteit',
  [IntlKeys.purposeCo2FootprintPigFarmingGroupP25]: 'Elektriciteitsverbruik en -productie',
  [IntlKeys.purposeCo2FootprintPigFarmingGroupP26]: 'Gasverbruik',
  [IntlKeys.purposeCo2FootprintPigFarmingGroupP27]: 'Waterverbruik (via de meter en eigen bron)',
  [IntlKeys.purposeCo2FootprintPigFarmingGroupP28]: 'Totale energieverbruik gesplitst voor zakelijk en privé',
  [IntlKeys.titleDataFromSawFarming]: 'Gegevens uit de zeugenhouderij',
  [IntlKeys.titleDataFromFatteningPigIndustry]: 'Gegevens uit de vleesvarkenshouderij',
  [IntlKeys.purposeCo2FootprintFeedOriginGroupP1]: 'Gegevens voerleverancier CO2-emissie off Farm',
  [IntlKeys.purposeCo2FootprintFeedOriginGroupP2]: 'Herkomst en hoeveelheid geleverde voergrondstoffen',
  [IntlKeys.purposeCo2FootprintFeedOriginGroupP3]: 'Aantal voerleveringen',
  [IntlKeys.purposeCo2FootprintFeedOriginGroupP4]: 'Verteringscoëfficiënt organische stof (%) per type voer',
  [IntlKeys.purposeCo2FootprintFeedOriginGroupP5]: 'Ruw as (%) per type voer',
  [IntlKeys.purposeCo2FootprintFeedOriginGroupP6]: 'Ruw eiwit (%) per type voer',
  [IntlKeys.purposeCo2FootprintFeedOriginGroupP7]: 'Verteringscoëfficiënt ruw eiwit (%) per type voer',
  [IntlKeys.titleFeedDateFromFeedSupplier]: 'Voergegevens van voerleverancier',
  [IntlKeys.titleFeedDataFromFeedSupplierForCalculationCO2Emissions]: 'Voergegevens van voerleverancier voor de berekening van CO2-emissies uit mest',

  [IntlKeys.bestStarMeatGroupP1]: 'Slachtgegevens',
  [IntlKeys.bestStarMeatGroupP1_1]: 'Identificatie van het bericht',
  [IntlKeys.bestStarMeatGroupP1_2]: 'Identificatie van de varkenshouder',
  [IntlKeys.bestStarMeatGroupP1_3]: 'Identificatie van het UBN',
  [IntlKeys.bestStarMeatGroupP1_4]: 'Slachtdatum',
  [IntlKeys.bestStarMeatGroupP1_5]: 'Identificatie van de slachterij',
  [IntlKeys.bestStarMeatGroupP1_6]: 'Identificatie van slachtlocatie',
  [IntlKeys.bestStarMeatGroupP1_7]: 'Aantal dieren',
  [IntlKeys.bestStarMeatGroupP1_8]: 'Volnummers-identificatie van de dieren',
  [IntlKeys.bestStarMeatGroupP1_9]: 'Diersoort',
  [IntlKeys.bestStarMeatGroupP1_10]: 'Geslacht van het dier',
  [IntlKeys.bestStarMeatGroupP1_11]: 'Slachtgewicht',
  [IntlKeys.bestStarMeatGroupP1_12]: 'Karkasafwijkingen',
  [IntlKeys.bestStarMeatGroupP1_13]: 'Orgaanafwijkingen',
  [IntlKeys.bestStarMeatGroupP1_14]: 'Vleespercentage',
  [IntlKeys.bestStarMeatGroupP1_15]: 'Spekdikte',
  [IntlKeys.bestStarMeatGroupP1_16]: 'Spierdikte',
  [IntlKeys.bestStarMeatGroupP2]: 'Dier identificatie en registratie',
  [IntlKeys.bestStarMeatGroupP2_1]: 'Identificatie van het bericht',
  [IntlKeys.bestStarMeatGroupP2_2]: 'Datum transport',
  [IntlKeys.bestStarMeatGroupP2_3]: 'Identificatie herkomst bedrijf',
  [IntlKeys.bestStarMeatGroupP2_4]: 'Identificatie type herkomst bedrijf',
  [IntlKeys.bestStarMeatGroupP2_5]: 'Identificatie bestemmingslocatie',
  [IntlKeys.bestStarMeatGroupP2_6]: 'Identificatie type bestemmingslocatie',
  [IntlKeys.bestStarMeatGroupP2_7]: 'Diercategorie',
  [IntlKeys.bestStarMeatGroupP2_8]: 'Aantal dieren',
  [IntlKeys.bestStarMeatGroupP2_9]: 'Groepsgegevens',
  [IntlKeys.bestStarMeatGroupP3]: 'Voerleveranties',
  [IntlKeys.bestStarMeatGroupP3_1]: 'Identificatie van de levering van voer',
  [IntlKeys.bestStarMeatGroupP3_2]: 'Het bonnummer van de levering',
  [IntlKeys.bestStarMeatGroupP3_3]: 'Identificatie van de leverancier',
  [IntlKeys.bestStarMeatGroupP3_4]: 'Identificatie van de varkenshouder',
  [IntlKeys.bestStarMeatGroupP3_5]: 'Identificatie van het varkensbedrijf',
  [IntlKeys.bestStarMeatGroupP3_6]: 'Identificatie van de voersoort',
  [IntlKeys.bestStarMeatGroupP3_7]: 'Artikelnummer',
  [IntlKeys.bestStarMeatGroupP3_8]: 'Artikelomschrijving',
  [IntlKeys.bestStarMeatGroupP3_9]: 'De geleverde hoeveelheid en eenheid',
  [IntlKeys.bestStarMeatGroupP3_10]: 'Identificatie van de productielocatie',
  [IntlKeys.bestStarMeatGroupP3_11]: 'GMP nummer van de productielocatie',
  [IntlKeys.bestStarMeatGroupP3_12]: 'De datum van aflevering',
  [IntlKeys.bestStarMeatGroupP3_13]: 'Doelgroep diercategorie',
  [IntlKeys.bestStarMeatGroupP3_14]: 'Berekende CO2 Footprint voeder',
  [IntlKeys.bestStarMeatGroupP3_15]: 'De LUC waarde van de voeders',
  [IntlKeys.bestStarMeatGroupP3_16]: 'LUC waarde van aangekochte LUC certificaten',
  [IntlKeys.bestStarMeatGroupP3_17]: 'Geleverde grondstof en hoeveelheid',
  [IntlKeys.bestStarMeatGroupP3_18]: 'RE, vem etc',
  [IntlKeys.bestStarMeatGroupP3_19]: 'Herkomstgegevens van de gebruikte grondstoffen voor de voeders',
  [IntlKeys.bestStarMeatGroupP3_20]: 'De hoeveelheid van de gebruikte grondstoffen',
  [IntlKeys.bestStarMeatGroupP4]: 'Certificerende instantie. U als leverancier van varkens aan VLG geeft VLG met deze machtiging in JoinData toestemming om van uw certificerende instantie de volgende gegevens te verzamelen',
  [IntlKeys.bestStarMeatGroupP4_1]: 'De erkenning van het bedrijf en de verloopdatum van de erkenning ten aanzien van IKB, Q&S, Beter Leven en VOB',
  [IntlKeys.bestStarMeatGroupP4_2]: 'Het antibioticumgebruik',
  [IntlKeys.bestStarMeatGroupP4_3]: 'De salmonella categorie',
  [IntlKeys.bestStarMeatGroupP5]: 'Genetica',
  [IntlKeys.bestStarMeatGroupP5_1]: 'Identificatie levering sperma',
  [IntlKeys.bestStarMeatGroupP5_2]: 'Aflever datum',
  [IntlKeys.bestStarMeatGroupP5_3]: 'Identificatie herkomst sperma win station',
  [IntlKeys.bestStarMeatGroupP5_4]: 'Identificatie varkenshouder',
  [IntlKeys.bestStarMeatGroupP5_5]: 'Identificatie varkensbedrijf',
  [IntlKeys.bestStarMeatGroupP5_6]: 'Aantal doses',
  [IntlKeys.bestStarMeatGroupP5_7]: 'Identificatie beer',
  [IntlKeys.bestStarMeatGroupP5_8]: 'Identificatie ras/lijn beer',
  [IntlKeys.bestStarMeatGroupP5_9]: 'Productiegroep beer',
  [IntlKeys.bestStarMeatGroupP6]: 'Mest',
  [IntlKeys.bestStarMeatGroupP6_1]: 'VDM-nummer',
  [IntlKeys.bestStarMeatGroupP6_2]: 'KVK nummer',
  [IntlKeys.bestStarMeatGroupP6_3]: 'Netto gewicht',
  [IntlKeys.bestStarMeatGroupP6_4]: 'Stikstof (kg)',
  [IntlKeys.bestStarMeatGroupP6_5]: 'Fosfaat (kg)',

  // Transports

  [IntlKeys.transports]: 'Transports',
  [IntlKeys.transportLoadings]: 'Transports Loadings',
  [IntlKeys.transportLoadingsShort]: 'Loadings',
  [IntlKeys.transportUnloadings]: 'Transports Unloadings',
  [IntlKeys.transportUnloadingsShort]: 'Unloadings',

  [IntlKeys.transportsReferenceId]: 'Transport Reference Id',
  [IntlKeys.transportsPartyIdTypeCode]: 'Party Id Type Code',
  [IntlKeys.transportsPartyIdValue]: 'Party Id Value',
  [IntlKeys.transportsLogisticServiceProviderName]: 'Logistic Service Provider Name',

  [IntlKeys.transportsPlannedArrivalTime]: 'Planned Arrival Time',
  [IntlKeys.transportsActualArrivalTime]: 'Actual Arrival Time',
  [IntlKeys.transportsStartLoadingTime]: 'Start Loading Time',
  [IntlKeys.transportsEndLoadingTime]: 'End Loading Time',
  [IntlKeys.transportsPlannedDepartureTime]: 'Planned Departure Time',
  [IntlKeys.transportsActualDepartureTime]: 'Actual Departure Time',
  [IntlKeys.transportsLocationIdTypeCode]: 'Number Of Animals',
  [IntlKeys.transportsLocationIdValue]: 'Location Id Type Code',
  [IntlKeys.transportsLocationName]: 'Location Id Value',

  // farmer input

  [IntlKeys.farmerInputCategory]: 'Category',
  [IntlKeys.farmerInputIdCode]: 'ID Code',
  [IntlKeys.farmerInputIdType]: 'ID Type',
  [IntlKeys.farmerInputReportDate]: 'Report date',
  [IntlKeys.farmerInputStatus]: 'Status',
  [IntlKeys.farmerInputUpdatedBy]: 'Updated by',
  [IntlKeys.farmerInputUpdatedOn]: 'Updated on',
  [IntlKeys.farmerInputManureFileName]: 'Manure file name',
  [IntlKeys.farmerInputAverageNumberOfSowsPerPeriod]: 'Average number of sows per period',
  [IntlKeys.farmerInputAverageNumberOfRearingSowsPerPeriod]: 'Average number of rearing sows per period',
  [IntlKeys.farmerInputAverageNumberOfPigletsPerPeriod]: 'Average number of weaned piglets present',
  [IntlKeys.farmerInputAverageNumberOfFinishingPigsPerPeriod]: 'Average number of finishing pigs per period',
  [IntlKeys.farmerInputTotalPriceSoldSows]: 'Total number of sold sows (delivered from this location)',
  [IntlKeys.farmerInputTotalPriceSoldPiglets]: 'Total number sold piglets (delivered from this location)',
  [IntlKeys.farmerInputAverageWeightPigletsPurchased]: 'Average lay-weight of piglets',
  [IntlKeys.farmerInputAverageNumberOfPigletsProduced]: 'Average number of piglets raised per sow',
  [IntlKeys.farmerInputFeedConversionBreeder]: 'Feed conversion breeder',
  [IntlKeys.farmerInputFeedConversionFattener]: 'Feed conversion fattener',
  [IntlKeys.farmerInputCullingPercentage]: 'Culling percentage',
  [IntlKeys.farmerInputGrowthPerDay]: 'Growth per day',
  [IntlKeys.farmerInputEwConversion]: 'EW-conversion',
  [IntlKeys.farmerInputCompanyProductionResultsZeugenhouderij]: 'Company production results (zeugenhouderij) if applicable to this location',
  [IntlKeys.farmerInputCompanyProductionResultsVleesvarkenshouderij]: 'Company production results (vleesvarkenshouderij) if applicable to this location',
  [IntlKeys.farmerInputEnergyUse]: 'Energy consumption attributed to the pig farm on site',
  [IntlKeys.farmerInputEnergyUsageHint]: 'Check your bill to see if it\'s green or grey gas and what the electricity consumption is.',
  [IntlKeys.farmerInputQuantityOfNonRenewableGasPurchased]: 'Quantity of non-renewable gas purchased',
  [IntlKeys.farmerInputQuantityOfRenewableGasPurchased]: 'Quantity of renewable gas purchased',
  [IntlKeys.farmerInputQuantityOfNonRenewableElectricityPurchased]: 'Quantity of non-renewable electricity purchased',
  [IntlKeys.farmerInputQuantityOfRenewableElectricityPurchased]: 'Quantity of renewable electricity purchased',
  [IntlKeys.farmerInputQuantityOfTapWaterUsed]: 'Quantity of tap water used',
  [IntlKeys.farmerInputWaterFromOwnSource]: 'Water from own source',
  [IntlKeys.farmerInputSmartWaterMeterAvailable]: 'Smart water meter available',
  [IntlKeys.farmerInputSmartGasMeterAvailable]: 'Smart gas meter available',
  [IntlKeys.farmerInputSmartElectiricyMeterAvailable]: 'Smart electiricy meter available',
  [IntlKeys.farmerInputPeriodStartDate]: 'Period start date',
  [IntlKeys.farmerInputPeriodEndDate]: 'Period end date',
  [IntlKeys.farmerInputHouseholdComposition]: 'Household composition',
  [IntlKeys.farmerInputDatePurchase]: 'Date purchase',
  [IntlKeys.farmerInputProductCode]: 'CVB Code',
  [IntlKeys.farmerInputProductName]: 'Product name',
  [IntlKeys.farmerInputProductOrigin]: 'Product origin',
  [IntlKeys.farmerInputProductQuantity]: 'Kg product',
  [IntlKeys.farmerInputQuantityType]: 'Quantity type',
  [IntlKeys.farmerInputDryMatter]: 'Dry matter',
  [IntlKeys.farmerInputRe]: 'RE',
  [IntlKeys.farmerInputRas]: 'RAS',
  [IntlKeys.farmerInputVcre]: 'VCRE',
  [IntlKeys.farmerInputVcos]: 'VCOS',
  [IntlKeys.farmerInputKg]: 'kg',
  [IntlKeys.farmerInputGramKg]: 'gram/kg',
  [IntlKeys.farmerInputProduct]: 'Product (default)',
  [IntlKeys.farmerInputLiter]: 'liter',
  [IntlKeys.farmerInputSupplierName]: 'Supplier name',
  [IntlKeys.farmerInputQuantityDelivered]: 'Quantity delivered',
  [IntlKeys.farmerInputUnit]: 'Unit',
  [IntlKeys.farmerInputProcessedManure]: 'Processed manure',
  [IntlKeys.farmerInputSodiumHydroxide]: 'Sodium hydroxide',
  [IntlKeys.farmerInputGlycerineUsed]: 'Glycerine used',
  [IntlKeys.farmerInputDigestateProduction]: 'Digestate production',
  [IntlKeys.farmerInputBiogasProduction]: 'Biogas production',
  [IntlKeys.farmerInputElectricityProduction]: 'Electricity production',
  [IntlKeys.farmerInputTonsPeriod]: 'tons/period',
  [IntlKeys.farmerInputKgPeriod]: 'kg/period',
  [IntlKeys.farmerInputM3Period]: 'm<sup>3</sup>/period',
  [IntlKeys.farmerInputM2]: 'm<sup>2</sup>',
  [IntlKeys.farmerInputKwhPeriod]: 'kWh/period',
  [IntlKeys.farmerInputGeneral]: 'General',
  [IntlKeys.farmerInputOpen]: 'Open',
  [IntlKeys.farmerInputClosed]: 'Closed (all animaltypes on this UBN)',
  [IntlKeys.farmerInputTypeOfCompany]: 'Type of company on this UBN location',
  [IntlKeys.farmerInputHouseholdCompositionInfo]: 'INFO: for allocating energy consumption to private use or business use',
  [IntlKeys.farmerInputTotalPriceSowsInfo]: 'INFO: the CO2 value is assigned to sows or piglets based on economic value (e.g. 100 sold sows per year x 100 euros per sow = 10,000 euros/year)',
  [IntlKeys.farmerInputTotalPricePigletsInfo]: 'INFO: the CO2 value is assigned to sows or piglets based on economic value (e.g. 1000 piglets sold per year x 40 euros per piglet = 40 000 euros/year)',
  [IntlKeys.farmerInputDraft]: 'Draft',
  [IntlKeys.farmerInputDraftDescription]: 'This updates will be marked as drafts. If you want to mark this update as final please select “Save and Submit” from the previous screen.',
  [IntlKeys.farmerInputSubmitted]: 'Submitted',
  [IntlKeys.farmerInputSubmittedDescription]: 'This updates is marked as submitted. If you want to mark this update as draft please select “Revert to draft” from the previous screen.',
  [IntlKeys.farmerInputRevertToDraft]: 'Revert to draft',
  [IntlKeys.farmerInputRevertToDraftDescription]: 'This updates will be marked as draft. If you want to mark this update as submitted please select “Save and Submit” from the previous screen.',
  [IntlKeys.farmerInputStableNumber]: 'Stable number',
  [IntlKeys.farmerInputRavCode]: 'RAV code',
  [IntlKeys.farmerInputNrOfAnimals]: 'Nr of animals',
  [IntlKeys.farmerInputSystemId]: 'Report ID',
  [IntlKeys.farmerInputMainReport]: 'Main report',
  [IntlKeys.farmerInputMainReportId]: 'Main Report ID',
  [IntlKeys.farmerInputNrPeriod]: 'nr/period',
  [IntlKeys.farmerInputEurPeriod]: 'EUR/period',
  [IntlKeys.farmerInputNrSowPeriod]: 'nr/sow/period',
  [IntlKeys.farmerInputKgKg]: 'kg/kg',
  [IntlKeys.farmerInputGrDag]: 'gr/dag',
  [IntlKeys.farmerInputTriggeredBy]: 'Triggered by',
  [IntlKeys.farmerInputReportType]: 'Report type',
  [IntlKeys.farmerInputAction]: 'Action',
  [IntlKeys.farmerInputActionDate]: 'Action date',
  [IntlKeys.farmerInputRavCodesInfo]: 'INFO: enter the RAV code and number of animals per code for the location (UBN)',
  [IntlKeys.farmerInputRavCodes]: 'Farmer RAV Codes',
  [IntlKeys.farmerInputRavCodesHint]: 'Please note that when filling in the RAV codes, the following RAV code categories are expected on farms with the animal categories listed below (this might be different for your location):',
  [IntlKeys.farmerInputRavCodesHintOption1]: 'Piglet rearing (weaned piglets): D 1.1*',
  [IntlKeys.farmerInputRavCodesHintOption2]: 'Farrowing sows (including piglets until weaning): D 1.2*',
  [IntlKeys.farmerInputRavCodesHintOption3]: 'Dry sows and pregnant sows: D 1.3*',
  [IntlKeys.farmerInputRavCodesHintOption4]: 'Farrowing boars, 7 months and over: D 2*',
  [IntlKeys.farmerInputRavCodesHintOption5]: 'Fattening pigs, rearing boars approx. 25 kg up to 7 months, rearing sows approx. 25 kg up to first mating: D 3*',
  [IntlKeys.farmerInputAddAtLeastOneRavCode]: 'Add at least one RAV code',
  [IntlKeys.farmerInputCompanyDataOption]: 'Report {id}, {from} - {to}, {status} by {updatedBy} on {updatedAt}',
  [IntlKeys.farmerInputAverageStorageDuration]: 'Average storage duration of manure in the pit under the stable',
  [IntlKeys.farmerInputAdditionalManureStorage]: 'Additional manure storage types other than the pit under the stable',
  [IntlKeys.farmerInputShorterThanOneMonth]: 'shorter than 1 month',
  [IntlKeys.farmerInputBetweenOneAndThreeMonth]: 'between 1 and 3 months',
  [IntlKeys.farmerInputBetweenThreeAndFiveMonth]: 'between 3 and 5 months',
  [IntlKeys.farmerInputBetweenFiveAndSixMonth]: 'between 5 and 6 months',
  [IntlKeys.farmerInputLongerThanSixMonth]: 'longer than 6 months',
  [IntlKeys.farmerInputNone]: 'None',
  [IntlKeys.farmerInputManureStorageInSilo]: 'Manure storage in silo',
  [IntlKeys.farmerInputManureStorageInBag]: 'Manure storage in bag',
  [IntlKeys.farmerInputSolidStorageInStacksWithoutBedding]: 'Solid storage in stacks with no or minimal bedding material',
  [IntlKeys.farmerInputSolidStorageInStacksWithBedding]: 'Solid storage in stacks with bedding',
  [IntlKeys.farmerInputManureStorageInSiloOrBag]: 'Manure storage in silo or bag',
  [IntlKeys.farmerInputDurationOfStorage]: 'Average duration of storage in this storage type',
  [IntlKeys.farmerInputMonthlyManureRemoval]: 'Is the manure removed from the farm on a monthly basis?',
  [IntlKeys.farmerInputCallToActionWarningMessage]: "Don't forget to submit this report when it is ready",
  [IntlKeys.farmerInputReportsByProducts]: 'By-Products Reports',
  [IntlKeys.farmerInputReportsFermentation]: 'Fermentation Reports',
  [IntlKeys.farmerInputReportsManure]: 'Manure Reports',
  [IntlKeys.farmerInputAverageFeedConsumptionPerSowPresent]: 'Average feed consumption per sow present (incl. feed rearing sows)',
  [IntlKeys.farmerInputPartlyClosed]: 'Partially closed (delivers piglets and fattening pigs)',
  [IntlKeys.farmerInputOpenSows]: 'Sows without weaning piglets',
  [IntlKeys.farmerInputOpenSowsPiglets]: 'Sows with weaning piglets',
  [IntlKeys.farmerInputOpenPiglets]: 'Suckling piglets rearing',
  [IntlKeys.farmerInputOpenPigletsPigs]: 'Weaning piglets and fattening pigs',
  [IntlKeys.farmerInputOpenFinishingPigs]: 'Fattening pigs',
  [IntlKeys.farmerInputFeedConversionKg]: 'kg/kg',
  [IntlKeys.farmerInputElectricityUsageTooltipText]: 'Net consumption = consumption - production (from solar panels, among others). The Net consumption can be found on the invoice of the energy supplier. This number can also be negative.',
  [IntlKeys.farmerInputCollapsibleBlockTooltip]: 'INFO: give number of animals present and key figures calculated by the Farm Management System.',
  [IntlKeys.farmerInputSharedSmartMeter]: 'Shared smart meter with other businesses?',
  [IntlKeys.farmerInputQuantityOfElectricityProduced]: 'Quantity of electricity produced',
  [IntlKeys.farmerInputSolarPanelsSquare]: 'Number of square meters of solar panels on this UBN location',
  [IntlKeys.farmerInputOpenAnExampleLink]: 'Select the CVB code from dropdown if known, or fill in own code or name and fill the below nutritional values manually (instructions {link}).',
  [IntlKeys.farmerInputAverageNumberSucklingPiglets]: 'Average number of suckling piglets present',
  [IntlKeys.farmerInputStatusAutomaticallyFound]: 'Automatically Found',
  [IntlKeys.farmerInputStatusManuallyEntered]: 'Manually Entered',
  [IntlKeys.farmerInputIsManureBeingRemoved]: 'Is manure being removed to a manure digester?',
  [IntlKeys.farmerInputManureRemovingFrequency]: 'How frequently is the manure removed to the digester?',
  [IntlKeys.farmerInputByProductsOrLoose]: 'Are there by-products or loose raw materials fed at this location?',
  [IntlKeys.farmerInputFeedFermentation]: 'Is there feed fermentation being done at this location?',
  [IntlKeys.daily]: 'Daily',
  [IntlKeys.weekly]: 'Weekly',
  [IntlKeys.monthly]: 'Monthly',
  [IntlKeys.onceEvery1to3Months]: 'Once every 1 to 3 months',
  [IntlKeys.onceEvery3to5Months]: 'Once every 3 to 5 months',
  [IntlKeys.lessThanOnceEvery6Months]: 'Less than once every 6 months',
  [IntlKeys.farmerInputManureDigestion]: 'Does manure digestion take place on this UBN?',
  [IntlKeys.farmerInputFeedSuppliers]: 'Feed suppliers',
  [IntlKeys.farmerInputFeedSuppliersHint]: 'Select the supplier name from the dropdown, or fill in a name manually if not available in the list (at least one of the three fields has to be filled in).',
  [IntlKeys.farmerInputFeedSuppliersPiglets]: 'Which feed supplier(s) delivered feed (raw materials) for the animal category piglets for this location in this time period?',
  [IntlKeys.farmerInputFeedSuppliersSows]: 'Which feed supplier(s) delivered feed (raw materials) for the animal category sows for this location in this time period?',
  [IntlKeys.farmerInputFeedSuppliersFatteningPigs]: 'Which feed supplier(s) delivered feed (raw materials) for the animal category fattening pigs for this location in this time period?',
  [IntlKeys.farmerInputFeedSuppliersHighlighted]: 'Which feed supplier(s) delivered feed (raw materials) for the animal category {category} for this location in this time period?',
  [IntlKeys.farmerInputFeedSuppliersFatteningPigsCategory]: 'fattening pigs',
  [IntlKeys.farmerInputFeedSuppliersSowsCategory]: 'sows',
  [IntlKeys.farmerInputFeedSuppliersPigletsCategory]: 'piglets',
  [IntlKeys.farmerInputAreRavCodesCorrect]: 'Are the filled in RAV codes correct? You filled in RAV codes that, on average, do not match your company type.',
  [IntlKeys.farmerInputRavCodesCorrect]: 'I have checked the RAV codes and they are correct',
  [IntlKeys.farmerInputRavCodesIncorrect]: 'Back',
  [IntlKeys.farmerInputCompanyTypeWarn]: 'Is your company type correct? Last time you filled in fields that do not correspond with your selected company type.',
  [IntlKeys.farmerInputAtLeastOneFeedSupplierIsRequired]: 'At least one of these fields has to be filled in.',
  [IntlKeys.farmerInputNotAvailableDutToTheCompanyType]: 'Not available due to the selected company type.',
  [IntlKeys.farmerInputIncorrectValueRange]: 'The filled in value differs from expected values. Please check if it is correct.',
  [IntlKeys.farmerInputProductFedToAnimalCategory]: 'Product fed to animal category',
  [IntlKeys.farmerInputPigletsPercent]: 'Piglets %',
  [IntlKeys.farmerInputSowsPercent]: 'Sows %',
  [IntlKeys.farmerInputPigsPercent]: 'Pigs %',
  [IntlKeys.farmerInputSumOfPercentsShouldBe100]: 'Percentages sum should be 100',

  [IntlKeys.farmerInputManureTransportations]: 'Manure Transportations',
  [IntlKeys.farmerInputManureInstructionFirstRow]: 'Please upload your RVO manure report for the',
  [IntlKeys.farmerInputManureInstructionSecondRow]: 'selected time period and UBN (instructions ',
  [IntlKeys.farmerInputManureHere]: 'here',

  // reference tables

  [IntlKeys.referenceTablesId]: 'ID',
  [IntlKeys.referenceTablesStatus]: 'Status',
  [IntlKeys.referenceTablesCategory]: 'Category',
  [IntlKeys.referenceTablesFilename]: 'Filename',
  [IntlKeys.referenceTablesValidDateFrom]: 'Valid date from',
  [IntlKeys.referenceTablesValidDateTo]: 'Valid date to',
  [IntlKeys.referenceTablesNotes]: 'Notes',
  [IntlKeys.referenceTablesValidDate]: 'Valid date',
  [IntlKeys.referenceTablesUploadDate]: 'Upload date',
  [IntlKeys.referenceTablesAsPensLevel]: 'AS_PensLevel',
  [IntlKeys.referenceTablesPensLevel]: 'Pens Level',
  [IntlKeys.referenceTablesPensName]: 'Pens Name',
  [IntlKeys.referenceTablesPensNameEN]: 'Pens Name EN',
  [IntlKeys.referenceTablesActive]: 'Active',
  [IntlKeys.referenceTablesInputText]: 'Input text',
  [IntlKeys.referenceTablesFrom]: 'From',
  [IntlKeys.referenceTablesTo]: 'To',
  [IntlKeys.referenceTablesUploadedInfo]: 'Uploaded by {username} on {date}',
  [IntlKeys.referenceTablesAsPensTypeSowBarn]: 'AS_PensTypeSowBarn',
  [IntlKeys.referenceTablesPensSowBarnCode]: 'Pens type sow barn code',
  [IntlKeys.referenceTablesPensSowBarnName]: 'Pens type sow barn name',
  [IntlKeys.referenceTablesAsYesNo]: 'AS_YesNo',
  [IntlKeys.referenceTablesYesNoCode]: 'Yes No Code',
  [IntlKeys.referenceTablesYesNoName]: 'Yes No Name',
  [IntlKeys.referenceTablesInactive]: 'Inactive',
  [IntlKeys.referenceTablesCfpCo2EmissionFactors]: 'CFP_CO2EmissionFactors',
  [IntlKeys.referenceTablesCfpCo2EmissionFactorsSource]: 'energy or water source',
  [IntlKeys.referenceTablesCfpCo2EmissionFactorsVolume]: 'kg CO2/eenheid',
  [IntlKeys.referenceTablesCfpCo2EmissionFactorsReference]: 'Reference',
  [IntlKeys.referenceTablesCvbTable]: 'CFP_CVB_Table',
  [IntlKeys.referenceTablesProductCode]: 'product code',
  [IntlKeys.referenceTablesProductSubCode]: 'product_subcode',
  [IntlKeys.referenceTablesProductType]: 'soort_product',
  [IntlKeys.referenceTablesProductName]: 'productnaam',
  [IntlKeys.referenceTablesClassName]: 'klassenaam',
  [IntlKeys.referenceTablesSubClassName]: 'subklassenaam',
  [IntlKeys.referenceTablesProductNameEN]: 'productName',
  [IntlKeys.referenceTablesClassNameEN]: 'className',
  [IntlKeys.referenceTablesSubClassNameEN]: 'subClassName',
  [IntlKeys.referenceTablesDs]: 'DS',
  [IntlKeys.referenceTablesDsUnit]: 'DS Unit',
  [IntlKeys.referenceTablesRas]: 'RAS',
  [IntlKeys.referenceTablesRasUnit]: 'RAS Unit',
  [IntlKeys.referenceTablesRein]: 'REin',
  [IntlKeys.referenceTablesReinUnit]: 'REin Unit',
  [IntlKeys.referenceTablesRe]: 'RE',
  [IntlKeys.referenceTablesReUnit]: 'RE Unit',
  [IntlKeys.referenceTablesCvcRe]: 'C_VC_RE',
  [IntlKeys.referenceTablesCvcReUnit]: 'C_VC_RE Unit',
  [IntlKeys.referenceTablesCvcOs]: 'C_VC_OS',
  [IntlKeys.referenceTablesCvcOsUnit]: 'C_VC_OS Unit',
  [IntlKeys.referenceTablesIpccEmissionFactors]: 'CFP_IPCC_Emission_Factors_Indir',
  [IntlKeys.referenceTablesFactor]: 'Factor',
  [IntlKeys.referenceTablesDescription]: 'Description',
  [IntlKeys.referenceTablesDefaultValue]: 'Default value',
  [IntlKeys.referenceTablesUncertaintyRange]: 'Uncertainty range',
  [IntlKeys.referenceTablesCfpGfliFeedprintTable]: 'CFP_GFLI_Feedprint_table',
  [IntlKeys.referenceTablesCfpGfliFeedprintTableFeedPrintCode]: 'Code FeedprintCVB',
  [IntlKeys.referenceTablesCfpGfliFeedprintTableCvbCode]: 'CVB code',
  [IntlKeys.referenceTablesCfpGfliFeedprintTableSource]: 'Source',
  [IntlKeys.referenceTablesCfpGfliFeedprintTableProductNameEn]: 'Product Name EN',
  [IntlKeys.referenceTablesCfpGfliFeedprintTableProductNameNl]: 'Product Name NL',
  [IntlKeys.referenceTablesCfpGfliFeedprintTableProductCodeEu]: 'EU product code',
  [IntlKeys.referenceTablesCfpGfliFeedprintTableProductNameEu]: 'EU product name',
  [IntlKeys.referenceTablesCfpGfliFeedprintTableOrigin]: 'Origin',
  [IntlKeys.referenceTablesCfpGfliFeedprintTableClimate]: 'Climate (incl. luc) (g CO2 eq/kg)',
  [IntlKeys.referenceTablesContentOfNitricOxide]: 'CFP_NEMA_Emission_factors_for_other_gaseous_N-losses',
  [IntlKeys.referenceTablesContentOfNitricOxideCategory]: 'Livestock category',
  [IntlKeys.referenceTablesContentOfNitricOxideType]: 'Manure_type',
  [IntlKeys.referenceTablesContentOfNitricOxideValueN2oNo]: 'N2O and NO',
  [IntlKeys.referenceTablesContentOfNitricOxideUnitN2oNo]: 'N2O and NO Unit',
  [IntlKeys.referenceTablesContentOfNitricOxideValueN2]: 'N2',
  [IntlKeys.referenceTablesContentOfNitricOxideUnitN2]: 'N2 Unit',
  [IntlKeys.referenceTablesPigHousingEmissionFactors]: 'CFP_NEMA_NH3-emission_factors_for_pig_housing',
  [IntlKeys.referenceTablesPigHousingEmissionFactorsCategory]: 'Livestock_category',
  [IntlKeys.referenceTablesPigHousingEmissionFactorsSystem]: 'Housing_system',
  [IntlKeys.referenceTablesPigHousingEmissionFactorsYearValue]: 'Year_of_Value',
  [IntlKeys.referenceTablesPigHousingEmissionFactorsEmissionValue]: 'Value',
  [IntlKeys.referenceTablesPigHousingEmissionFactorsUnit]: 'Value Unit',
  [IntlKeys.referenceTablesElectricityConsumptionRates]: 'CFP_NIBUD_electriciteitsverbruik',
  [IntlKeys.referenceTablesElectricityConsumptionRatesPeopleQuantity]: 'nrPeopleHousehold',
  [IntlKeys.referenceTablesElectricityConsumptionRatesConsumption]: 'electricityConsumption',
  [IntlKeys.referenceTablesGasConsumptionRates]: 'CFP_NIBUD_gasverbruik',
  [IntlKeys.referenceTablesGasConsumptionRatesHouseType]: 'houseType',
  [IntlKeys.referenceTablesGasConsumptionRatesGasConsumption]: 'gasConsumption',
  [IntlKeys.referenceTablesWaterConsumptionRates]: 'CFP_NIBUD_waterverbruik',
  [IntlKeys.referenceTablesWaterConsumptionRatesHousehold]: 'nrPeopleHousehold',
  [IntlKeys.referenceTablesWaterConsumptionRatesConsumption]: 'waterConsumption',
  [IntlKeys.referenceTablesRavCodes]: 'CFP_RAV_codes',
  [IntlKeys.referenceTablesRavCodesAnimalCategory]: 'Diercategorie',
  [IntlKeys.referenceTablesRavCodesRavCode]: 'RAVCode',
  [IntlKeys.referenceTablesRavCodesAnimalDescriptionNl]: 'DierOmschrijving',
  [IntlKeys.referenceTablesRavCodesAnimalDescriptionEn]: 'AnimalDescription',
  [IntlKeys.referenceTablesRavCodesAnimalEnclosureCategory]: 'DierverblijfCategorie',
  [IntlKeys.referenceTablesRavCodesAmmonia]: 'Ammoniak',
  [IntlKeys.referenceTablesRavCodesAmmoniaUnit]: 'AmmoniakUnit',
  [IntlKeys.referenceTablesGwp100Factors]: 'CFP_WUR_GWP100factor',
  [IntlKeys.referenceTablesGwp100FactorsGreenhouseGas]: 'Greenhouse gas',
  [IntlKeys.referenceTablesGwp100FactorsGwp]: 'GWP100 factor',
  [IntlKeys.referenceTablesGwp100FactorsGwpUnit]: 'GWP100 factor Unit',
  [IntlKeys.referenceTablesWurMfcEfem]: 'CFP_WUR_MCF',
  [IntlKeys.referenceTablesWurMfcEfemSystem]: 'System',
  [IntlKeys.referenceTablesWurMfcEfemDurationDung]: 'Average storage duration of manure',
  [IntlKeys.referenceTablesWurMfcEfemMfc]: 'MCF',
  [IntlKeys.referenceTablesCl550]: 'cl550',
  [IntlKeys.referenceTablesCl550MainGroupCode]: 'Code Hoofdgroep',
  [IntlKeys.referenceTablesCl550SubGroupCode]: 'Code Subgroep',
  [IntlKeys.referenceTablesCl550CodeDetails]: 'Code Detallering',
  [IntlKeys.referenceTablesCl550MainGroupName]: 'Hoofdgroep',
  [IntlKeys.referenceTablesCl550SubgroupName]: 'Subgroep',
  [IntlKeys.referenceTablesCl550Detailing]: 'Detaillering',
  [IntlKeys.referenceTablesCl550English]: 'ENGLISH',
  [IntlKeys.referenceTablesCl550German]: 'DEUTSCH',
  [IntlKeys.referenceTablesCl550CodeCondition]: 'Code Aandoening',
  [IntlKeys.referenceTablesCl550Edi]: 'EDI',
  [IntlKeys.referenceTablesCl550Dgr]: 'DGR',
  [IntlKeys.referenceTablesCl550DiseaseAndTreatment]: 'ziekte/behandeling',
  [IntlKeys.referenceTablesCl550Failure]: 'uitval',
  [IntlKeys.referenceTablesCl550Disposal]: 'afvoer',
  [IntlKeys.referenceTablesCl550Selection]: 'selectie',
  [IntlKeys.referenceTablesCl550LabResult]: 'lab.uitslag',
  [IntlKeys.referenceTablesCl550Section]: 'sectie',
  [IntlKeys.referenceTablesCl550SlaughterhouseData]: 'slachthuisgegevens',
  [IntlKeys.referenceTablesCl550Agility]: 'berigheid',
  [IntlKeys.referenceTablesCl550Coverage]: 'dekking',
  [IntlKeys.referenceTablesCl550Birth]: 'Geboorte',
  [IntlKeys.referenceTablesCl550SuckingPiglet]: 'zuigende big',
  [IntlKeys.referenceTablesCl550WeanedBig]: 'gespeende big',
  [IntlKeys.referenceTablesCl550RearingSowAndBoar]: 'opfokzeug/beer',
  [IntlKeys.referenceTablesCl550GoodSow]: 'guste zeug',
  [IntlKeys.referenceTablesCl550GestationSow]: 'dragende zeug',
  [IntlKeys.referenceTablesCl550LactatingSow]: 'lacterende zeug',
  [IntlKeys.referenceTablesCl550Beer]: 'beer',
  [IntlKeys.referenceTablesCl550Finisher]: 'vleesvarken',
  [IntlKeys.referenceTablesCl550Comment]: 'opmerkingen',
  [IntlKeys.referenceTablesCl550Added]: 'toegevoegd',
  [IntlKeys.referenceTablesCl550Deleted]: 'verwijderd',
  [IntlKeys.referenceTablesCl649]: 'cl649',
  [IntlKeys.referenceTablesCl649Code]: 'code',
  [IntlKeys.referenceTablesCl649Description]: 'description',
  [IntlKeys.referenceTablesCl649Remarks]: 'notes',
  [IntlKeys.referenceTablesCl650]: 'cl650',
  [IntlKeys.referenceTablesCl650Code]: 'code',
  [IntlKeys.referenceTablesCl650Description]: 'description',
  [IntlKeys.referenceTablesCl650Note]: 'notes',
  [IntlKeys.referenceTablesCl607]: 'cl607',
  [IntlKeys.referenceTablesCl607Code]: 'code',
  [IntlKeys.referenceTablesCl607Description]: 'description',
  [IntlKeys.referenceTablesCl607Remarks]: 'notes',
  [IntlKeys.referenceTablesSuppliersNumbers]: 'cl420',
  [IntlKeys.referenceTablesSuppliersNumbersCodeList]: 'Code List',
  [IntlKeys.referenceTablesSuppliersNumbersSupplierNumber]: 'Supplier Code',
  [IntlKeys.referenceTablesSuppliersNumbersFeedSupplierName]: 'Supplier Name',
  [IntlKeys.referenceTablesSuppliersNumbersEntryDate]: 'Date From',
  [IntlKeys.referenceTablesSuppliersNumbersDischargeDate]: 'Date To',
  [IntlKeys.referenceTablesSuppliersNumbersRemark]: 'Remarks',
  [IntlKeys.referenceTablesCountryCodes]: 'ISO 3166 Country Codes',
  [IntlKeys.referenceTablesCountryCodesIso31661Code]: 'ISO 3166-1 Code',
  [IntlKeys.referenceTablesCountryCodesSubdivisionName]: 'Subdivision Name',
  [IntlKeys.referenceTablesCountryCodesIso31662Code]: 'ISO 3166-2 Code',
  [IntlKeys.referenceTablesCountryCodesCountryName]: 'Country Name',
  [IntlKeys.referenceTablesStatusAlertTitle]: 'Please confirm that the status of this table becomes "Active"!',
  [IntlKeys.referenceTablesStatusDescription]: 'If valid date intersects with other tables of this type then other tables valid dates will be changed',
  [IntlKeys.referenceTablesActiveTableExists]: 'There are active versions with overlapping dates. An already existing version of the reference table will be assigned start and/or expiry dates, so dates do not overlap.',
  [IntlKeys.referenceTablesDenyUpload]: 'Upload is not allowed, there is overlap in dates and/or concepts with an existing active reference table. Please adjust or inactivate the existing reference table first.',
  [IntlKeys.referenceTablesRavReferenceList]: 'RAV_Referentielijst',
  [IntlKeys.referenceTablesRavReferenceListAnimalCategory]: 'Animal category',
  [IntlKeys.referenceTablesRavReferenceListDescription]: 'Description',
  [IntlKeys.referenceTablesRavReferenceListEfem]: 'EF_em',
  [IntlKeys.referenceTablesRavReferenceListHousingType]: 'Housing type',
  [IntlKeys.referenceTablesAllocationManure]: 'Allocation Manure',
  [IntlKeys.referenceTablesAllocationManureAnimalCategory]: 'Animal Category',
  [IntlKeys.referenceTablesAllocationManureManure]: 'Manure',
  [IntlKeys.referenceTablesAllocationManureGas]: 'Gas',
  [IntlKeys.referenceTablesAllocationManureElectricity]: 'Electricity',
  [IntlKeys.referenceTablesAllocationManureWater]: 'Water',
  [IntlKeys.referenceTablesGreenhouseGasEmission]: 'Greenhouse gas emissions',
  [IntlKeys.referenceTablesGreenhouseGasEmissionCountry]: 'Country',
  [IntlKeys.referenceTablesGreenhouseGasEmissionLocation]: 'Location',
  [IntlKeys.referenceTablesGreenhouseGasEmissionCategory]: 'Category',
  [IntlKeys.referenceTablesGreenhouseGasEmissionAccount]: 'Account',
  [IntlKeys.referenceTablesGreenhouseGasEmissionName]: 'Name',
  [IntlKeys.referenceTablesGreenhouseGasEmissionEmissionFactor]: 'Emission factors (kg CO2-eq)',
  [IntlKeys.referenceTablesGreenhouseGasEmissionUnit]: 'Unit (ton CO2-eq)',
  [IntlKeys.referenceTablesGreenhouseGasEmissionConversionFactor]: 'Conversion Factor',
  [IntlKeys.referenceTablesGreenhouseGasEmissionFormulaCf]: 'formule CF',
  [IntlKeys.referenceTablesGreenhouseGasEmissionExtraExternalId]: 'Custom1',
  [IntlKeys.referenceTablesGreenhouseGasEmissionInputUnit]: 'Input unit',
  [IntlKeys.referenceTablesGreenhouseGasEmissionConversionFormula]: 'Conversion formula',
  [IntlKeys.referenceTablesGreenhouseGasEmissionEmissionFactors]: 'Emission factors (kg CO2-eq. of kg CH4 per eenheid)',
  [IntlKeys.referenceTablesGreenhouseGasEmissionCo2Unit]: 'Unit (kg CO2-eq)',

  // entities list
  [IntlKeys.referenceTablesEntitiesList]: 'Entities list',
  [IntlKeys.referenceTablesEntitiesListEntityId]: 'Entity ID',
  [IntlKeys.referenceTablesEntitiesListEntityName]: 'Entity name',
  [IntlKeys.referenceTablesEntitiesListCountry]: 'Country',
  [IntlKeys.referenceTablesEntitiesListBusinessUnit]: 'Business Unit',

  // farm management data

  [IntlKeys.farmManagementDataCompanyId]: 'Company ID',
  [IntlKeys.farmManagementDataTimestamp]: 'Timestamp',
  [IntlKeys.farmManagementDataGroupGroups]: 'G_Groups',
  [IntlKeys.farmManagementDataGroupsList]: 'G_Groups List',
  [IntlKeys.farmManagementDataFarmId]: 'Farm ID',
  [IntlKeys.farmManagementDataGroupId]: 'Group ID',
  [IntlKeys.farmManagementDataGroupName]: 'Group name',
  [IntlKeys.farmManagementDataDateEnd]: 'Date end',
  [IntlKeys.farmManagementDataNotes]: 'Notes',
  [IntlKeys.farmManagementDataBarnId]: 'Barn ID',
  [IntlKeys.farmManagementDataRoomId]: 'Room ID',
  [IntlKeys.farmManagementDataPenId]: 'Pen ID',
  [IntlKeys.farmManagementDataGroupDatetime]: 'Group datetime',
  [IntlKeys.farmManagementDataFirstDeliveryGrowthExp]: 'First delivery growth exp',
  [IntlKeys.farmManagementDataLastDeliveryGrowthExp]: 'Last delivery growth exp',
  [IntlKeys.farmManagementDataFirstDeliveryPer]: 'First delivery perc',
  [IntlKeys.farmManagementDataLastDeliveryPerc]: 'Last delivery perc',
  [IntlKeys.farmManagementDataFirstDeliveryWeight]: 'First delivery weight',
  [IntlKeys.farmManagementDataLastDeliveryWeight]: 'Last delivery weight',
  [IntlKeys.farmManagementDataAbout]: 'About farm management',
  [IntlKeys.farmManagementDataId]: 'System ID',
  [IntlKeys.farmManagementDataUploadType]: 'Category',
  [IntlKeys.farmManagementDataFileName]: 'File',
  [IntlKeys.farmManagementDataSource]: 'Source',
  [IntlKeys.farmManagementDataCategory]: 'Category',
  [IntlKeys.farmManagementDataCreatedAt]: 'Created At',
  [IntlKeys.farmManagementDataGroupFinancialEvents]: 'G_Financial Events',
  [IntlKeys.farmManagementDataFinEventId]: 'Fin event ID',
  [IntlKeys.farmManagementDataEventTypeId]: 'Event type ID',
  [IntlKeys.farmManagementDataEventDate]: 'Event date',
  [IntlKeys.farmManagementDataContactId]: 'Contact ID',
  [IntlKeys.farmManagementDataAmountExVat]: 'Amount ex vat',
  [IntlKeys.farmManagementDataAmountVat]: 'Amount vat',
  [IntlKeys.farmManagementDataAmountInVat]: 'Amount in vat',
  [IntlKeys.farmManagementDataInvoiceNumber]: 'Invoice number',
  [IntlKeys.farmManagementDataInvoceDate]: 'Invoce date',
  [IntlKeys.farmManagementDataFinancialNotes]: 'Financial notes',
  [IntlKeys.farmManagementDataValve]: 'Valve',
  [IntlKeys.farmManagementDataInputEventId]: 'Input event ID',
  [IntlKeys.farmManagementDataImportId]: 'Import ID',
  [IntlKeys.farmManagementDataInputWarnings]: 'Input warnings',
  [IntlKeys.farmManagementDataInputTypeStamp]: 'Input type stamp',
  [IntlKeys.farmManagementDataScannedIds]: 'Scanned IDs',
  [IntlKeys.farmManagementDataUserId]: 'User ID',
  [IntlKeys.farmManagementDataBatchNumberProduction]: 'Batch number production',
  [IntlKeys.farmManagementDataBatchNumberDelivery]: 'Batch number delivery',
  [IntlKeys.farmManagementDataGroupWeights]: 'G_GROUP WEIGHT',
  [IntlKeys.farmManagementDataWeightsList]: 'Weights List',
  [IntlKeys.farmManagementDataNumber]: 'Number',
  [IntlKeys.farmManagementDataTotalWeight]: 'Total weight',
  [IntlKeys.farmManagementDataGroupRemovals]: 'G_GROUP REMOVAL',
  [IntlKeys.farmManagementDataRemovalList]: 'Removal List',
  [IntlKeys.farmManagementDataAlive]: 'Alive',
  [IntlKeys.farmManagementDataWeightSlaughtered]: 'Weight Slaughtered',
  [IntlKeys.farmManagementDataWeightAlive]: 'Weight Alive',
  [IntlKeys.farmManagementDataFirstReamson]: 'Rem Reason 1',
  [IntlKeys.farmManagementDataSecondReamson]: 'Rem Reason 2',
  [IntlKeys.farmManagementDataRemtypeId]: 'Rem Type ID',
  [IntlKeys.farmManagementDataAnimalCategoryId]: 'Animal category ID',
  [IntlKeys.farmManagementDataWcfId]: 'WCF ID',
  [IntlKeys.farmManagementDataNotClassified]: 'Not Classified',
  [IntlKeys.farmManagementDataClassType]: 'Class Type',
  [IntlKeys.farmManagementDataNlMeatPerc]: 'NL Meat Perc',
  [IntlKeys.farmManagementDataNlAa]: 'NL AA',
  [IntlKeys.farmManagementDataNlA]: 'NL A',
  [IntlKeys.farmManagementDataNlB]: 'NL B',
  [IntlKeys.farmManagementDataNlC]: 'NL C',
  [IntlKeys.farmManagementDataNlBackFat]: 'NL Back Fat',
  [IntlKeys.farmManagementDataNlLoInDepth]: 'NL Lo In Depth',
  [IntlKeys.farmManagementDataDeLachs]: 'DE Lachs',
  [IntlKeys.farmManagementDataDeSchinken]: 'DE Schinken',
  [IntlKeys.farmManagementDataDeSchaulter]: 'DE Schaulter',
  [IntlKeys.farmManagementDataDeIndexPunkte]: 'DE Index Punkte',
  [IntlKeys.farmManagementDataDeIndexFactor]: 'DE Index Factor',
  [IntlKeys.farmManagementDataEstimatedDateIn]: 'Estimated Date In',
  [IntlKeys.farmManagementDataSexId]: 'Sex ID',
  [IntlKeys.farmManagementDataWithoutMale]: 'No Male',
  [IntlKeys.farmManagementDataWithoutFemale]: 'No Female',
  [IntlKeys.farmManagementDataWithoutMaleCastrated]: 'No Male Castrated',
  [IntlKeys.farmManagementDataCarPleuritis]: 'Car Pleuritis',
  [IntlKeys.farmManagementDataCarInfSkin]: 'Car Inf Skin',
  [IntlKeys.farmManagementDataCarInfFeed]: 'Car Inf Feed',
  [IntlKeys.farmManagementDataCarPleuritisInfFeed]: 'Car Pleuritis Inf Feed',
  [IntlKeys.farmManagementDataCarInfSkinAndFeed]: 'Car Inf Skin And Feed',
  [IntlKeys.farmManagementDataCarPleuritisInfSkin]: 'Car Pleuritis Inf Skin',
  [IntlKeys.farmManagementDataCarPleuritisInfSkinAndFeed]: 'Car Pleuritis Inf Skin And Feed',
  [IntlKeys.farmManagementDataOrganAffLiver]: 'Organ Aff Liver',
  [IntlKeys.farmManagementDataOrganRejLiver]: 'Organ Rej Liver',
  [IntlKeys.farmManagementDataOrganAffLungs]: 'Organ Aff Lungs',
  [IntlKeys.farmManagementDataOrganAffLungsAndLiver]: 'Organ Aff Lungs And Liver',
  [IntlKeys.farmManagementDataOrganAffLungsRejLiver]: 'Organ Aff Lungs Rej Liver',
  [IntlKeys.farmManagementDataQualId]: 'Qual ID',
  [IntlKeys.farmManagementDataGroupsArrival]: 'G_Groups Arrival',
  [IntlKeys.farmManagementDataArrivalList]: 'Groups Arrival List',
  [IntlKeys.farmManagementDataFinancialEventId]: 'Financial event ID',
  [IntlKeys.farmManagementDataGroupArrivalNumber]: 'Group arrival number',
  [IntlKeys.farmManagementDataOriginId]: 'Origin ID',
  [IntlKeys.farmManagementDataGroupArrivalDatetime]: 'Group arrival datetime',
  [IntlKeys.farmManagementDataOwnFarm]: 'Own farm',
  [IntlKeys.farmManagementDataGroupMoves]: 'G_GROUP MOVE',
  [IntlKeys.farmManagementDataGroupFrom]: 'Group From',
  [IntlKeys.farmManagementDataBarnFrom]: 'Barn From',
  [IntlKeys.farmManagementDataRoomFrom]: 'Room From',
  [IntlKeys.farmManagementDataPenFrom]: 'Pen From',
  [IntlKeys.farmManagementDataAnimalCatIdFrom]: 'Animal Cat Id From',
  [IntlKeys.farmManagementDataWeight]: 'Weight',
  [IntlKeys.farmManagementDataAnimalCatIdTo]: 'Animal Cat Id To',
  [IntlKeys.farmManagementDataAdminDate]: 'Admin Date',
  [IntlKeys.farmManagementDataRemReason]: 'Rem Reason',
  [IntlKeys.farmManagementDataCodesAnimalRemarks]: 'C_Animal Remark',
  [IntlKeys.farmManagementDataAnimalRemarksList]: 'Animal Remarks List',
  [IntlKeys.farmManagementDataAnimalRemarkId]: 'Animal Rem ID',
  [IntlKeys.farmManagementDataAbbreviation]: 'Abbreviation',
  [IntlKeys.farmManagementDataDescription]: 'Description',
  [IntlKeys.farmManagementDataNote]: 'Note',
  [IntlKeys.farmManagementDataCode]: 'EDI Code',
  [IntlKeys.farmManagementDataCodesDefects]: 'C_Defects',
  [IntlKeys.farmManagementDataDefectsList]: 'Defects List',
  [IntlKeys.farmManagementDataDefectId]: 'Defect ID',
  [IntlKeys.farmManagementDataWorkList]: 'Work List',
  [IntlKeys.farmManagementDataCodesDiagnose]: 'C_Diagnose',
  [IntlKeys.farmManagementDataDiagnosesList]: 'Diagnoses List',
  [IntlKeys.farmManagementDataDiagnoseId]: 'Diagnose ID',
  [IntlKeys.farmManagementDataCodesAnimalScore]: 'C_Animal Score',
  [IntlKeys.farmManagementDataAnimalScoreList]: 'Animal Scores List',
  [IntlKeys.farmManagementDataAnimalScoreId]: 'Animal score ID',
  [IntlKeys.farmManagementDataCodeGroup]: 'Code group',
  [IntlKeys.farmManagementDataScoreEnd]: 'Score end',
  [IntlKeys.farmManagementDataSows]: 'Sows',
  [IntlKeys.farmManagementDataBoars]: 'Boars',
  [IntlKeys.farmManagementDataWeaners]: 'Weaners',
  [IntlKeys.farmManagementDataSucklers]: 'Sucklers',
  [IntlKeys.farmManagementDataReplstock]: 'Replstock',
  [IntlKeys.farmManagementDataFinishers]: 'Finishers',
  [IntlKeys.farmManagementDataCodesInsemination]: 'C_Insemination',
  [IntlKeys.farmManagementDataInseminationList]: 'Insemination List',
  [IntlKeys.farmManagementDataInseminationId]: 'Insemination ID',
  [IntlKeys.farmManagementDataCodesTreatments]: 'C_Treatment',
  [IntlKeys.farmManagementDataTreatmentsList]: 'Treatments List',
  [IntlKeys.farmManagementDataTreatmentId]: 'Treatment ID',
  [IntlKeys.farmManagementDataRegistrationNumber]: 'Registration Number',
  [IntlKeys.farmManagementDataWhPeriod]: 'WH Period',
  [IntlKeys.farmManagementDataDestination]: 'Destination',
  [IntlKeys.farmManagementDataTreatmentsPerDay]: 'Treatment Per Day',
  [IntlKeys.farmManagementDataFirstRepeatTreatment]: 'First Repeat Treatment',
  [IntlKeys.farmManagementDataSecondRepeatTreatment]: 'Second Repeat Treatment',
  [IntlKeys.farmManagementDataThirdRepeatTreatment]: 'Third Repeat Treatment',
  [IntlKeys.farmManagementDataFourthRepeatTreatment]: 'Fourth Repeat Treatment',
  [IntlKeys.farmManagementDataFifthRepeatTreatment]: 'Fifth Repeat Treatment',
  [IntlKeys.farmManagementDataGroupPurchasedFood]: 'G_Food',
  [IntlKeys.farmManagementDataPurchasedFoodList]: 'Food List',
  [IntlKeys.farmManagementDataGilts]: 'Gilts',
  [IntlKeys.farmManagementDataEv]: 'EV',
  [IntlKeys.farmManagementDataDm]: 'DM',
  [IntlKeys.farmManagementDataAcctId]: 'Acct Identifier',
  [IntlKeys.farmManagementDataGroupUsedFood]: 'G_Food Used',
  [IntlKeys.farmManagementDataUsedFoodList]: 'Used Food List',
  [IntlKeys.farmManagementDataCodesMatingType]: 'C_Mating Type',
  [IntlKeys.farmManagementDataMatingTypeList]: 'Mating Types List',
  [IntlKeys.farmManagementDataMatingTypeId]: 'Mating Type ID',
  [IntlKeys.farmManagementDataCodesWeightType]: 'C_Weight Type',
  [IntlKeys.farmManagementDataWeightTypeList]: 'Weight Types List',
  [IntlKeys.farmManagementDataWeightTypeId]: 'Weight Type ID',
  [IntlKeys.farmManagementDataCodesRemovalType]: 'C_Removal Type',
  [IntlKeys.farmManagementDataRemovalTypeList]: 'Removal Types List',
  [IntlKeys.farmManagementDataRemovalTypeId]: 'Removal Type ID',
  [IntlKeys.farmManagementDataCodesAnimalIdentityType]: 'C_Animal Identity Type',
  [IntlKeys.farmManagementDataAnimalIdentityTypeList]: 'Animal Identity Types List',
  [IntlKeys.farmManagementDataIdForTypeIdentifier]: 'Identity Type ID',
  [IntlKeys.farmManagementDataUniqueIdentifier]: 'Unique ID',
  [IntlKeys.farmManagementDataTransponder]: 'Transponder',
  [IntlKeys.farmManagementDataCodesFarmOption]: 'C_Farm Option',
  [IntlKeys.farmManagementDataFarmOptionList]: 'Farm Options List',
  [IntlKeys.farmManagementDataFarmOptionId]: 'Farm Option ID',
  [IntlKeys.farmManagementDataValue]: 'Value',
  [IntlKeys.farmManagementDataCodesBarn]: 'C_Barn',
  [IntlKeys.farmManagementDataBarnList]: 'Barns List',
  [IntlKeys.farmManagementDataCorrectionFin]: 'Correction Fin',
  [IntlKeys.farmManagementDataIsEarlyWeanUnit]: 'Is Early Wean Unit',
  [IntlKeys.farmManagementDataSurface]: 'Surface',
  [IntlKeys.farmManagementDataVvoNumber]: 'VVO Number',
  [IntlKeys.farmManagementDataEuApprovalNumber]: 'EU Approval Number',
  [IntlKeys.farmManagementDataCorrectionWeaners]: 'Correction Weaners',
  [IntlKeys.farmManagementDataCorrectionReplGilts]: 'Correction Repl Gilts',
  [IntlKeys.farmManagementDataCodesRoom]: 'C_Room',
  [IntlKeys.farmManagementDataRoomList]: 'Rooms List',
  [IntlKeys.farmManagementDataRoomTypeId]: 'Room Type ID',
  [IntlKeys.farmManagementDataColor]: 'Color',
  [IntlKeys.farmManagementDataCodesPen]: 'C_Pen',
  [IntlKeys.farmManagementDataPenList]: 'Pens List',
  [IntlKeys.farmManagementDataFirstValue]: 'First Value',
  [IntlKeys.farmManagementDataSecondValue]: 'Second Value',
  [IntlKeys.farmManagementDataNumberPlaces]: 'Number Of Places',
  [IntlKeys.farmManagementDataUbn]: 'UBN',
  [IntlKeys.farmManagementDataKvk]: 'KVK',
  [IntlKeys.farmManagementDataKvkName]: 'KVK Name',
  [IntlKeys.farmManagementDataCodesAccount]: 'C_Account',
  [IntlKeys.farmManagementDataAccountList]: 'Accounts List',
  [IntlKeys.farmManagementDataPercentSows]: 'Percent Sows',
  [IntlKeys.farmManagementDataPercentWeaners]: 'Percent Weaners',
  [IntlKeys.farmManagementDataPercentFinishers]: 'Percent Finishers',
  [IntlKeys.farmManagementDataPercentReplGilts]: 'Percent ReplGilts',
  [IntlKeys.farmManagementDataTypeId]: 'Acc Type ID',
  [IntlKeys.farmManagementDataActive]: 'Is Active',
  [IntlKeys.farmManagementDataEvType]: 'EV Type',
  [IntlKeys.farmManagementDataVatId]: 'VAT ID',
  [IntlKeys.farmManagementDataBarn]: 'Barn',
  [IntlKeys.farmManagementDataMedicine]: 'Medicine',
  [IntlKeys.farmManagementDataInoculation]: 'Inoculation',
  [IntlKeys.farmManagementDataHormone]: 'Hormone',
  [IntlKeys.farmManagementDataMedicineEanCode]: 'Medicine EAN Code',
  [IntlKeys.farmManagementDataMedicineCountryCode]: 'Medicine Country Code',
  [IntlKeys.farmManagementDataMedicineOfficialName]: 'Medicine Official Name',
  [IntlKeys.farmManagementDataMedicineDateEnd]: 'Medicine Date End',
  [IntlKeys.farmManagementDataMedicineUnit]: 'Medicine Unit',
  [IntlKeys.farmManagementDataMedicineWhPeriod]: 'Medicine WH Period',
  [IntlKeys.farmManagementDataMedicineDosage]: 'Medicine Dosage',
  [IntlKeys.farmManagementDataMedicineDosageFactor]: 'Medisine Dosage Factor',
  [IntlKeys.farmManagementDataSupplierId]: 'Supplier ID',
  [IntlKeys.farmManagementDataMedicineEmbCode]: 'Medicine EMB Code',
  [IntlKeys.farmManagementDataCodesEventTypes]: 'C_Event Type',
  [IntlKeys.farmManagementDataEventTypesList]: 'Event Types List',
  [IntlKeys.farmManagementDataShortcut]: 'Shortcut',
  [IntlKeys.farmManagementDataGroupExpense]: 'G_Expense',
  [IntlKeys.farmManagementDataExpenseList]: 'Expenses List',
  [IntlKeys.farmManagementDataPeriodFrom]: 'Period From',
  [IntlKeys.farmManagementDataPeriodTo]: 'Period To',

  // farm management passports

  [IntlKeys.farmManagementPassportsAddCompanyId]: 'Add Company_ID (Agrisyst)',
  [IntlKeys.farmManagementPassportsAddFarmId]: 'Add Farm_ID (Agrisyst)',
  [IntlKeys.farmManagementPassportsRemoveAlert]: 'Are you sure you want to remove this FMS? This action is irreversible',
  [IntlKeys.farmManagementPassportsRemoved]: 'FMS Removed',
  [IntlKeys.farmManagementPassportsSaved]: 'FMS Saved',

  // aliases

  [IntlKeys.aliasesDataset]: 'Dataset',
  [IntlKeys.aliasesAlias]: 'Alias',
  [IntlKeys.aliasesOtherSystem]: 'Other system',
  [IntlKeys.aliasesPrimaryDatapoint]: 'Primary datapoint',
  [IntlKeys.aliasesPrimaryUbn]: 'Primary UBN, {ubn}',
  [IntlKeys.aliasesFtpManualUpload]: 'FTP/Manual upload',
  [IntlKeys.aliasesEdiCircle]: 'EDI Circle',
  [IntlKeys.aliasesJoinData]: 'Join Data',
  [IntlKeys.aliasesUserPassportId]: 'User Passport ID',
  [IntlKeys.aliasesPassportAliasId]: 'Passport Alias ID',
  [IntlKeys.aliasesToDelete]: 'To Delete',
  [IntlKeys.aliasesDatasetName]: 'Dataset Name',
  [IntlKeys.aliasesUploadSource]: 'Upload Source',
  [IntlKeys.aliasesNotes]: 'Notes',
  [IntlKeys.aliasRemoveAlert]: 'Are you sure you want to remove this alias? This action is irreversible',
  [IntlKeys.aliasRemoved]: 'Alias Removed',
  [IntlKeys.aliasSaved]: 'Alias Saved',

  // labels

  [IntlKeys.labelsNote]: 'Note: Changes made to this label will be reflected everywhere',

  // table

  [IntlKeys.tableBodyEmptyDataSourceMessage]: 'No records to display',
  [IntlKeys.tableBodyAddTooltip]: 'Add',
  [IntlKeys.tableBodyDeleteTooltip]: 'Delete',
  [IntlKeys.tableBodyEditTooltip]: 'Edit',
  [IntlKeys.tableBodyFilterRowFilterTooltip]: 'Filter',
  [IntlKeys.tableBodyEditRowDeleteText]: 'Are you sure delete this row?',
  [IntlKeys.tableBodyEditRowCancelTooltip]: 'Cancel',
  [IntlKeys.tableBodyEditRowSaveTooltip]: 'Save',
  [IntlKeys.tableGroupingPlaceholder]: 'Drag headers here to group by',
  [IntlKeys.tableGroupingGroupedBy]: 'Grouped By:',
  [IntlKeys.tableHeaderActions]: 'Actions',
  [IntlKeys.tablePaginationLabelDisplayedRows]: '{from}-{to} of {count}',
  [IntlKeys.tablePaginationLabelRowsSelect]: 'rows',
  [IntlKeys.tablePaginationLabelRowsPerPage]: 'Rows per page:',
  [IntlKeys.tablePaginationFirstAriaLabel]: 'First Page',
  [IntlKeys.tablePaginationFirstTooltip]: 'First Page',
  [IntlKeys.tablePaginationPreviousAriaLabel]: 'Previous Page',
  [IntlKeys.tablePaginationPreviousTooltip]: 'Previous Page',
  [IntlKeys.tablePaginationNextAriaLabel]: 'Next Page',
  [IntlKeys.tablePaginationNextTooltip]: 'Next Page',
  [IntlKeys.tablePaginationLastAriaLabel]: 'Last Page',
  [IntlKeys.tablePaginationLastTooltip]: 'Last Page',
  [IntlKeys.tableToolbarAddRemoveColumns]: 'Add or remove columns',
  [IntlKeys.tableToolbarNRowsSelected]: '{0} row(s) selected',
  [IntlKeys.tableToolbarShowColumnsTitle]: 'Show Columns',
  [IntlKeys.tableToolbarShowColumnsAriaLabel]: 'Show Columns',
  [IntlKeys.tableToolbarExportTitle]: 'Export',
  [IntlKeys.tableToolbarExportAriaLabel]: 'Export',
  [IntlKeys.tableToolbarExportName]: 'Export as CSV',
  [IntlKeys.tableToolbarSearchTooltip]: 'Search',
  [IntlKeys.tableToolbarSearchPlaceholder]: 'Search',

  // service

  [IntlKeys.serviceCreatedAt]: 'Created at',
  [ServiceKeys.updatedAt]: 'Updated at',
  [IntlKeys.serviceUpdatedBy]: 'Updated by',
  [ServiceKeys.uploadedAt]: 'Uploaded at',
  [ServiceKeys.uploadedBy]: 'Uploaded by',
  [ServiceKeys.isEdited]: 'Is edited',
  [ServiceKeys.link]: 'Link',
  [ServiceKeys.source]: 'Source',
  [ServiceKeys.checksum]: 'Checksum',
  [ServiceKeys.id]: 'id',
  [IntlKeys.serviceBlockchainHashKey]: 'Blockchain hash',
  [IntlKeys.serviceBlockchainTimestamp]: 'Blockchain timestamp',
  [ServiceKeys.blockchainId]: 'Blockchain ID',
  [ServiceKeys.blockchainMessageId]: 'Blockchain message ID',
  [ServiceKeys.tableMenu]: 'Table Menu',
  [ServiceKeys.checkbox]: 'Checkbox',
  [IntlKeys.serviceLastUpdateAt]: 'Last update at',
  [IntlKeys.serviceLastUpdateBy]: 'Last update by',

  // auth

  [IntlKeys.authSignInPassword]: 'Password',
  [IntlKeys.authSignInLogIn]: 'Log In',
  [IntlKeys.authSignInForgotPassword]: 'Forgot password?',
  [IntlKeys.authSignInClickHere]: 'Click here to reset or update your password',
  // prettier-ignore
  [IntlKeys.authSignInResetPasswordMessage]: 'Enter the email address used to register on the platform and we will send you a link to reset your password.',
  [IntlKeys.authSignInSendLink]: 'Send Link',
  // prettier-ignore
  [IntlKeys.authSignInLinkSentMessage]: 'Within a few minutes you will receive an e-mail from us with further instructions. If you have not received the e-mail, please check your spam folder or make sure your e-mail address is correct.',
  [IntlKeys.authPasswordEnterNewPassword]: 'Enter new password',
  // prettier-ignore
  [IntlKeys.authPasswordNewPasswordHelpMessage]: 'Create a new password of at least 8 characters. A strong password is a combination of letters, numbers, and special characters.',
  [IntlKeys.authPasswordRestoreExpired]: 'Restore password link is expired.',
  [IntlKeys.authPasswordSuccessTitle]: 'Passsword has changed!',
  [IntlKeys.authPasswordSuccessMessage]: 'You will be redirected to the Login page in a few seconds',
  [IntlKeys.authPasswordRepeatPassword]: 'Repeat Password',
  [IntlKeys.authPasswordUpdatePassword]: 'Update Your Password',
  [IntlKeys.authPasswordOldPassword]: 'Old Password',
  [IntlKeys.authSessionExpired]: 'Session is expired, please sign-in again.',

  [IntlKeys.serverMaintenance]: 'Something Went Wrong',
  [IntlKeys.maintenanceDescr]: 'We are continuously working towards improving the service for you.',
  [IntlKeys.maintenanceTryAgain]: 'Please try again later, we won\'t be long!',

  // form validation

  [IntlKeys.validationRequired]: 'Required',
  [IntlKeys.validationInvalidEmail]: 'Invalid email',
  [IntlKeys.validationPasswordLength]: 'Should be at least {n} characters',
  [IntlKeys.validationPasswordsMatch]: 'Passwords must match',
  [IntlKeys.validationRequiredField]: '{name} is a required field.',
  [IntlKeys.validationRequiredAtLeastOneField]: 'At least one of the fields is required: {field1}, {field2}.',
  [IntlKeys.validationShouldBePositive]: '{name} should be a positive number.',
  [IntlKeys.validationShouldBeInRangeBetween]: '{name} should be in a range between {min} and {max}.',
  [IntlKeys.validationShouldBeZeroOrPositive]: '{name} should be zero or a positive number.',
  [IntlKeys.notAvailableAnymore]: 'Option is not available anymore, choose one from the list.',
  [IntlKeys.validationCommaIsDenied]: '"," symbol is not allowed at the name of the concept',

  // statuses

  [IntlKeys.statusActive]: 'Active',
  [IntlKeys.statusInactive]: 'Inactive',
  [IntlKeys.statusPending]: 'Pending',
  [IntlKeys.statusRefused]: 'Refused',
  [IntlKeys.statusRevoked]: 'Revoked',
  [IntlKeys.statusGranted]: 'Granted',
  [IntlKeys.statusExpired]: 'Expired',

  // carbon footprint (co2)
  [IntlKeys.carbonFootprintCo2Totals]: 'Co2_Totals',
  [IntlKeys.carbonFootprintUbn]: 'UBN',
  [IntlKeys.carbonFootprintKvk]: 'KVK',
  [IntlKeys.carbonFootprintKvkName]: 'KVK Name',
  [IntlKeys.carbonFootprintCompanyType]: 'Company Type',
  [IntlKeys.carbonFootprintCultivationAndProductionOfFeedCo2Breeder]: '1. Cultivation and production of feed CO2-eq (kg)',
  [IntlKeys.carbonFootprintTransportOfFeedCo2Breeder]: '2. Transport of feed CO2-eq (kg)',
  [IntlKeys.carbonFootprintManureStorageOfFeedCo2Breeder]: '3. Emissions from manure storage CO2-eq (kg)',
  [IntlKeys.carbonFootprintManureTransportOfFeedCo2Breeder]: '4. Emissions from manure transport CO2-eq (kg)',
  [IntlKeys.carbonFootprintEntericFermentationCo2Breeder]: '5. Enteric fermentation CO2-eq (kg)',
  [IntlKeys.carbonFootprintEnergyUseCo2Breeder]: '6. Energy use CO2-eq (kg)',
  [IntlKeys.carbonFootprintSowsCo2]: '7a. Sows CO2-eq (kg)',
  [IntlKeys.carbonFootprintSowsValue]: '7a. Sows Value (€)',
  [IntlKeys.carbonFootprintPigletsCo2]: '7b. Piglets CO2-eq (kg)',
  [IntlKeys.carbonFootprintPigletsValue]: '7b. Piglets Value (€)',
  [IntlKeys.carbonFootprintTransportPigletsCo2Fattener]: '8. Transport piglets CO2-eq (kg)',
  [IntlKeys.carbonFootprintCultivationAndProductionOfFeedCo2Fattener]: '9. Cultivation and production of feed CO2-eq (kg)',
  [IntlKeys.carbonFootprintTransportOfFeedCo2Fattener]: '10. Transport of feed CO2-eq (kg)',
  [IntlKeys.carbonFootprintManureStorageOfFeedCo2Fattener]: '11. Emissions from manure storage CO2-eq (kg)',
  [IntlKeys.carbonFootprintManureTransportOfFeedCo2Fattener]: '12. Emissions from manure transport CO2-eq (kg)',
  [IntlKeys.carbonFootprintEntericFermentationCo2Fattener]: '13. Enteric fermentation CO2-eq (kg)',
  [IntlKeys.carbonFootprintEnergyUseCo2Fattener]: '14. Energy use CO2-eq (kg)',
  [IntlKeys.carbonFootprintId]: 'ID',
  [IntlKeys.carbonFootprintStatus]: 'Status',
  [IntlKeys.carbonFootprintFileName]: 'File Name',
  [IntlKeys.carbonFootprintValidDateFrom]: 'Valid From',
  [IntlKeys.carbonFootprintValidDateTo]: 'Valid Until',
  [IntlKeys.carbonFootprintNotes]: 'Notes',
  [IntlKeys.carbonFootprintsBreeding]: 'Breeding',
  [IntlKeys.carbonFootprintsFattening]: 'Fattening',
  [IntlKeys.carbonFootprintsEconomicSowsAndPiglets]: 'Economic allocation sows and piglets',
  [IntlKeys.carbonFootprintsValue]: 'Value (€)',
  [IntlKeys.carbonFootprintsEconomicAllocation]: 'Economic allocation',
  [IntlKeys.carbonFootprintsCalculation]: 'Calculation',
  [IntlKeys.carbonFootprintsCo2Eq]: 'kg CO2-eq/100 g protein per source',
  [IntlKeys.carbonFootprintsDistribution]: '%-distribution',
  [IntlKeys.carbonFootprintsConcept]: 'Supply chain average per source (%)',
  [IntlKeys.carbonFootprintsConceptAverageKg]: 'Supply chain average per source (kg CO2-eq/100 g protein)',
  [IntlKeys.carbonFootprintsTotal]: 'Total',
  [IntlKeys.carbonFootprintsPerPiglet]: 'kg CO2-eq per piglet',
  [IntlKeys.carbonFootprintsCultivationAndProductionOfFeedBreeding]: '1. Cultivation and production of feed',
  [IntlKeys.carbonFootprintsTransportOfFeedBreeding]: '2. Transport of feed',
  [IntlKeys.carbonFootprintsManureStorageBreeding]: '3. Emissions from manure storage',
  [IntlKeys.carbonFootprintsManureTransportBreeding]: '4. Emissions from manure transport',
  [IntlKeys.carbonFootprintsEntericFermentationBreeding]: '5. Enteric fermentation',
  [IntlKeys.carbonFootprintsEnergyUseBreeding]: '6. Energy use',
  [IntlKeys.carbonFootprintsSows]: '7a. Sows',
  [IntlKeys.carbonFootprintsPiglets]: '7b. Piglets',
  [IntlKeys.carbonFootprintsPigletsFattening]: '7b. Piglets (economic allocation)',
  [IntlKeys.carbonFootprintsTransportPigletsFattening]: '8. Transport piglets',
  [IntlKeys.carbonFootprintsCultivationAndProductionOfFeedFattening]: '9. Cultivation and production of feed',
  [IntlKeys.carbonFootprintsTransportOfFeedFattening]: '10. Transport of feed',
  [IntlKeys.carbonFootprintsManureStorageFattening]: '11. Emissions from manure storage',
  [IntlKeys.carbonFootprintsManureTransportFattening]: '12. Emissions from manure transport',
  [IntlKeys.carbonFootprintsEntericFermentationFattening]: '13. Enteric fermentation',
  [IntlKeys.carbonFootprintsEnergyUseFattening]: '14. Energy use',
  [IntlKeys.carbonFootprintKgSlaughterWeight]: 'kg CO2-eq/100 g protein',
  [IntlKeys.carbonFootprintsSowsTotal]: '7a. Sows (economic allocation) - assigned to breeder',
  [IntlKeys.carbonFootprintsPigsTotal]: '7b. Piglets (economic allocation) - assigned to fattener',
  [IntlKeys.carbonFootprintsTotalCo2Eq]: 'Total CO2-eq (kg)',
  [IntlKeys.carbonFootprintsSowsCo2Eq]: 'Sows CO2-eq/100 g protein',
  [IntlKeys.carbonFootprintsPigsCo2Eq]: 'Piglets CO2-eq/100 g protein',
  [IntlKeys.carbonFootprintsTransportOfFeedHint]: 'INFO: Transport of feed is included in the calculation of cultivation and production of feed',
  [IntlKeys.carbonFootprintsBreedingTotalCo2Eq]: 'Total CO2-eq (kg)',

  // request log users
  [IntlKeys.requestLogUsersFullName]: 'Username',
  [IntlKeys.requestLogUsersEmail]: 'Email',
  [IntlKeys.requestLogUsersLastLogin]: 'Last Login',
  [IntlKeys.requestLogUsersPrefLang]: 'Preferred Language',
  [IntlKeys.requestLogUsersRequestCount]: 'Visits',
  [IntlKeys.requestLogUsersUserPassports]: 'User Passports',
  [IntlKeys.requestLogUsersLabels]: 'Labels',
  [IntlKeys.requestLogUsersCarbonFootprintCount]: 'Carbon Footprint visits',
  [IntlKeys.requestLogUsersOrganizationCount]: 'My Company visits',
  [IntlKeys.requestLogUsersParticipaionCount]: 'Participations visits',
  [IntlKeys.requestLogUsersAnimalPassportCount]: 'Animal Passport visits',
  [IntlKeys.requestLogUsersVriCalculatedCount]: 'VRI Tool visits',
  [IntlKeys.requestLogUsersVriCount]: 'Good Farming Star visits',
  [IntlKeys.requestLogUsersFarmerInputCount]: 'Farmer Input visits',
  [IntlKeys.requestLogUsersMyJoindataCount]: 'My Joindata visits',
  [IntlKeys.requestLogUsersMyFarmCount]: 'My Farm visits',
  [IntlKeys.requestLogUsersHfmOutputCount]: 'CFP Scope 1&2 visits',
  [IntlKeys.requestLogUsersVriComparisonToolCount]: 'VRI Comparison Tool visits',

  // user admin updates log
  [IntlKeys.userAdminUpdatesLogCreatedBy]: 'Created By',
  [IntlKeys.userAdminUpdatesLogAction]: 'Action',
  [IntlKeys.userAdminUpdatesLogActionType]: 'Action Type',
  [IntlKeys.userAdminUpdatesLogLoggableType]: 'Loggable Type',

  // update logs details
  [IntlKeys.detailsPropertyName]: 'Property',
  [IntlKeys.detailsPreviousValue]: 'Previous value',
  [IntlKeys.detailsNewValue]: 'New value',
  [IntlKeys.detailsShowMore]: 'Show more',
  [IntlKeys.detailsFullName]: 'Full name',
  [IntlKeys.detailsCompanies]: 'Companies',
  [IntlKeys.detailsUserPassports]: 'User passports',
  [IntlKeys.detailsPermissionProfile]: 'Permission profile',
  [IntlKeys.detailsPrefLang]: 'Preferred language',
  [IntlKeys.detailsColorTheme]: 'Color theme',
  [IntlKeys.detailsIsContactPerson]: 'Is contact person',
  [IntlKeys.detailsStatus]: 'Status',
  [IntlKeys.detailsKvk]: 'KVK',
  [IntlKeys.detailsKvkCompanyName]: 'KVK company name',
  [IntlKeys.detailsEmail]: 'Email',
  [IntlKeys.detailsCustomKvkName]: 'Custom KVK name',
  [IntlKeys.detailsUbn]: 'UBN',
  [IntlKeys.detailsGln]: 'GLN',
  [IntlKeys.detailsVionRelationNumber]: 'Client relation number',
  [IntlKeys.detailsBreedingProgram]: 'Breeding program',
  [IntlKeys.detailsCertificates]: 'Certificates',
  [IntlKeys.detailsCertificatesOther]: 'Certificates other',
  [IntlKeys.detailsClimateSystemOther]: 'Climate system other',
  [IntlKeys.detailsClimateSystems]: 'Climate systems',
  [IntlKeys.detailsConcepts]: 'Concepts',
  [IntlKeys.detailsCustomUbnName]: 'Custom UBN name',
  [IntlKeys.detailsFeedSupplierOther]: 'Feed supplier other',
  [IntlKeys.detailsFeedSuppliers]: 'Feed suppliers',
  [IntlKeys.detailsFeedingSystemOther]: 'Feeding system other',
  [IntlKeys.detailsFeedingSystems]: 'Feeding systems',
  [IntlKeys.detailsFmsModuleAgrisysts]: 'FMS module agrisysts',
  [IntlKeys.detailsFmsModuleAgroCoops]: 'FMS module agroscoops',
  [IntlKeys.detailsFmsModuleAgrovisions]: 'FMS module agrovisions',
  [IntlKeys.detailsFmsNames]: 'FMS names',
  [IntlKeys.detailsGeneticSupplierOther]: 'Genetic supplier other',
  [IntlKeys.detailsGeneticSuppliers]: 'Genetic suppliers',
  [IntlKeys.detailsKiStationOther]: 'KI station other',
  [IntlKeys.detailsKiStations]: 'KI stations',
  [IntlKeys.detailsPhone]: 'Phone',
  [IntlKeys.detailsPlace]: 'Place',
  [IntlKeys.detailsPostcode]: 'Postcode',
  [IntlKeys.detailsSireLineOther]: 'Sire line other',
  [IntlKeys.detailsSireLines]: 'Sire lines',
  [IntlKeys.detailsSowLines]: 'Sow lines',
  [IntlKeys.detailsRotationalIntersection]: 'Rotational Intersection',
  [IntlKeys.detailsSowLineOther]: 'Sow line other',
  [IntlKeys.detailsStreet]: 'Street',
  [IntlKeys.detailsTypeOfCompany]: 'Type of company',
  [IntlKeys.detailsVatNr]: 'VAT nr',
  [IntlKeys.detailsVeterinarian]: 'Veterinarian',
  [IntlKeys.detailsVeterinaryPractice]: 'Veterinary practice',
  [IntlKeys.detailsVeterinaryPracticeOther]: 'Veterinary practice other',
  [IntlKeys.password]: 'Password',
  [IntlKeys.confirmPassword]: 'Confirm password',
  [IntlKeys.acceptInvite]: 'Accept invite',

  // raw materials origin
  [IntlKeys.rawMaterialsOriginSource]: 'Source',
  [IntlKeys.rawMaterialsOriginMessageId]: 'Message ID',
  [IntlKeys.rawMaterialsOriginSupplierNumber]: 'Supplier Number',
  [IntlKeys.rawMaterialsOriginSupplierName]: 'Supplier Name',
  [IntlKeys.rawMaterialsOriginCustomerNumber]: 'Customer Number',
  [IntlKeys.rawMaterialsOriginCustomerName]: 'Customer Name',
  [IntlKeys.rawMaterialsOriginUbn]: 'UBN number',
  [IntlKeys.rawMaterialsOriginBeginningTime]: 'Beginning Time',
  [IntlKeys.rawMaterialsOriginEndTime]: 'End Time',
  [IntlKeys.rawMaterialsOriginAnimalCategoryCode]: 'Animal Category Code',
  [IntlKeys.rawMaterialsOriginSubAnimalCategoryCode]: 'Sub Animal Category Code',
  [IntlKeys.rawMaterialsOriginArticleNumber]: 'Article Number',
  [IntlKeys.rawMaterialsOriginArticleTypeNumberCode]: 'Type Article Number Code',
  [IntlKeys.rawMaterialsOriginArticleDescription]: 'Supplier Number',
  [IntlKeys.rawMaterialsOriginNetQuantityDeliveredProduct]: 'Net Quantity Delivered Product',
  [IntlKeys.rawMaterialsOriginQuantityUnit]: 'Quantity Unit',
  [IntlKeys.rawMaterialsOriginNutrientDs]: 'Nutrient DS',
  [IntlKeys.rawMaterialsOriginNutrientRe]: 'Nutrient RE',
  [IntlKeys.rawMaterialsOriginBreed]: 'RAS',
  [IntlKeys.rawMaterialsOriginNutrientVcre]: 'Nutrient VCRE',
  [IntlKeys.rawMaterialsOriginNutrientVcos]: 'Nutrient VCOS',
  [IntlKeys.rawMaterialsOriginRegionOfOriginCode]: 'Region Of Origin Code',
  [IntlKeys.rawMaterialsOriginCreatedAt]: 'Upload Date',

  // hfm output
  [IntlKeys.hfmOutputId]: 'ID',
  [IntlKeys.hfmOutputTitle]: 'HFM Output',
  [IntlKeys.hfmOutputYear]: 'Year',
  [IntlKeys.hfmOutputPeriod]: 'Period',
  [IntlKeys.hfmOutputQuarter]: 'Quarter',
  [IntlKeys.hfmOutputYearToDate]: 'Year to date',
  [IntlKeys.hfmOutputEntityExternalId]: 'Entity',
  [IntlKeys.hfmOutputAccountHfm]: 'Account HFM',
  [IntlKeys.hfmOutputExtraExternalId]: 'Custom 1',
  [IntlKeys.hfmOutputConsumption]: 'Usage',
  [IntlKeys.hfmOutputCo2Equivalent]: 'kg CO2 equivalent',
  [IntlKeys.hfmOutputEntities]: 'Entities',
  [IntlKeys.hfmOutputEntitiesCountry]: 'Country',
  [IntlKeys.hfmOutputEntitiesBusinessUnit]: 'Business Unit',
  [IntlKeys.hfmOutputGreenhouseConversionFactor]: 'Conversion Factor',
  [IntlKeys.hfmOutputGreenhouseEmissionFactor]: 'Emission Factor',
  [IntlKeys.hfmOutputGreenhouseCategory]: 'Category',
  [IntlKeys.hfmOutputGreenhouseName]: 'Name',
  //
  [IntlKeys.hfmOutputReportName]: 'Report Name',
  [IntlKeys.hfmOutputNa]: 'N.A.',
  [IntlKeys.hfmOutputIcp]: 'ICP',
  [IntlKeys.hfmOutputCustom2]: 'Custom 2',
  [IntlKeys.hfmOutputCustom3]: 'Custom 3',
  [IntlKeys.hfmOutputCustom4]: 'Custom 4',

  // errors log
  [IntlKeys.errorsLogLogDatetime]: 'Log datetime',
  [IntlKeys.errorsLogErrorLogType]: 'Error log type',
  [IntlKeys.errorsLogErrorBody]: 'Error body',
  [IntlKeys.errorsLogErrorCode]: 'Error code',

  // sign in messages
  [IntlKeys.incorrectCredentials]: 'Invalid login credentials.',
  [IntlKeys.incorrectCredentialsExtended]: 'Invalid login credentials. Please try again.',
  [IntlKeys.accountIsSuspended]: 'Your account has been suspended, please contact powerchain@impactbuying.com',
  [IntlKeys.passwordExpired]: 'Your password expired! Please change it.',
  [IntlKeys.sessionExpired]: 'Your session expired! Please sign in again.',
  [IntlKeys.incorrectCredentialsNl]: 'Invalid login credentials.',
  [IntlKeys.incorrectCredentialsNlExtended]: 'Invalid login credentials. Please try again.',
  [IntlKeys.accountIsSuspendedNl]: 'Your account has been suspended, please contact powerchain@impactbuying.com',
  [IntlKeys.passwordExpiredNl]: 'Your password expired! Please change it.',
  [IntlKeys.sessionExpiredNl]: 'Your session expired! Please sign in again.'
};
