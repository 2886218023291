import { IntlKeys } from 'lib/localization/keys';

enum UserPassportsOriginal {
  UserPassports = 'user_passports',
}

enum UserPassportsSerialized {
  UserPassports = 'UserPassports',
}

enum UserPassportsUnserialized {
  UserPassports = 'UserPassports',
}

export enum UserPassportsOriginalHeaders {
  concepts = 'concepts',
  kvk = 'kvk',
  gln = 'gln',
  ubn = 'ubn',
  ubnAliases = 'passport_aliases',
  customUbnName = 'custom_ubn_name',
  vionRelationNumber = 'vion_relation_number',
  ediCircleRelationNumber = 'edi_circle_code',
  registrationName = 'registration_name',
  vatNum = 'vat_nr',
  street = 'street',
  postcode = 'postcode',
  place = 'place',
  phone = 'phone',
  feedSuppliers = 'feed_suppliers',
  certificates = 'certificates',
  certificatesOther = 'certificates_other',
  geneticSuppliers = 'genetic_suppliers',
  geneticSupplierOther = 'genetic_supplier_other',
  kiStations = 'ki_stations',
  kiStationOther = 'ki_station_other',
  sowLines = 'sow_lines',
  sowLineOther = 'sow_line_other',
  breedingProgram = 'breeding_program',
  sireLines = 'sire_lines',
  sireLineOther = 'sire_line_other',
  veterinaryPractice = 'veterinary_practice',
  veterinaryPracticeOther = 'veterinary_practice_other',
  feedSupplierOther = 'feed_supplier_other',
  fmsNames = 'fms_names',
  fmsModuleAgrisysts = 'fms_module_agrisysts',
  fmsModuleAgroCoops = 'fms_module_agro_coops',
  fmsModuleAgrovisions = 'fms_module_agrovisions',
  feedingSystems = 'feeding_systems',
  feedingSystemOther = 'feeding_system_other',
  climateSystems = 'climate_systems',
  climateSystemOther = 'climate_system_other',
  veterinarian = 'veterinarian',
  typeOfCompany = 'type_of_company',
  status = 'status',
  statusUpdatedBy = 'status_updated_by',
  statusUpdatedAt = 'status_updated_at',
  organization = 'organization',
  passportOwners = 'passport_owners',
  permissionProfileTemplateId = 'permission_profile_template_id',
  organizationPermissionProfile = 'organization_permission_profile',
  labels = 'labels',
  companyAvatar = 'logo',
}

export enum UserPassportsSerializedHeaders {
  concepts = 'concepts',
  kvk = 'kvk',
  gln = 'gln',
  ubn = 'ubn',
  ubnAliases = 'ubnAliases',
  customUbnName = 'customUbnName',
  vionRelationNumber = 'vionRelationNumber',
  registrationName = 'registrationName',
  vatNum = 'vatNum',
  street = 'street',
  postcode = 'postcode',
  place = 'place',
  phone = 'phone',
  feedSuppliers = 'feedSuppliers',
  certificates = 'certificates',
  certificatesOther = 'certificatesOther',
  geneticSuppliers = 'geneticSuppliers',
  geneticSupplierOther = 'geneticSupplierOther',
  kiStations = 'kiStations',
  kiStationOther = 'kiStationOther',
  sowLines = 'sowLines',
  sowLineOther = 'sowLineOther',
  breedingProgram = 'breedingProgram',
  sireLines = 'sireLines',
  sireLineOther = 'sireLineOther',
  veterinaryPractice = 'veterinaryPractice',
  veterinaryPracticeOther = 'veterinaryPracticeOther',
  feedSupplierOther = 'feedSupplierOther',
  fmsNames = 'fmsNames',
  fmsModuleAgrisysts = 'fmsModuleAgrisysts',
  fmsModuleAgroCoops = 'fmsModuleAgroCoops',
  fmsModuleAgrovisions = 'fmsModuleAgrovisions',
  feedingSystems = 'feedingSystems',
  feedingSystemOther = 'feedingSystemOther',
  climateSystems = 'climateSystems',
  climateSystemOther = 'climateSystemOther',
  veterinarian = 'veterinarian',
  typeOfCompany = 'typeOfCompany',
  status = 'status',
  statusUpdatedBy = 'statusUpdatedBy',
  statusUpdatedAt = 'statusUpdatedAt',
  organization = 'organization',
  passportOwners = 'passportOwners',
  permissionProfileTemplateId = 'permissionProfileTemplateId',
  organizationPermissionProfile = 'organizationPermissionProfile',
  labels = 'labels',
  companyAvatar = 'companyAvatar',
}

export enum UserPassportsUnserializedHeaders {
  concepts = 'concepts',
  kvk = 'kvk',
  gln = 'gln',
  ubn = 'ubn',
  ubnAliases = 'passport_aliases',
  customUbnName = 'custom_ubn_name',
  vionRelationNumber = 'vion_relation_number',
  registrationName = 'registration_name',
  vatNum = 'vat_nr',
  street = 'street',
  postcode = 'postcode',
  place = 'place',
  phone = 'phone',
  feedSuppliers = 'feed_suppliers',
  certificates = 'certificates',
  certificatesOther = 'certificates_other',
  geneticSuppliers = 'genetic_suppliers',
  geneticSupplierOther = 'genetic_supplier_other',
  kiStations = 'ki_stations',
  kiStationOther = 'ki_station_other',
  sowLines = 'sow_lines',
  sowLineOther = 'sow_line_other',
  breedingProgram = 'breeding_program',
  sireLines = 'sire_lines',
  sireLineOther = 'sire_line_other',
  veterinaryPractice = 'veterinary_practice',
  veterinaryPracticeOther = 'veterinary_practice_other',
  feedSupplierOther = 'feed_supplier_other',
  fmsNames = 'fms_names',
  fmsModuleAgrisysts = 'fms_module_agrisysts',
  fmsModuleAgroCoops = 'fms_module_agro_coops',
  fmsModuleAgrovisions = 'fms_module_agrovisions',
  feedingSystems = 'feeding_systems',
  feedingSystemOther = 'feeding_system_other',
  climateSystems = 'climate_systems',
  climateSystemOther = 'climate_system_other',
  veterinarian = 'veterinarian',
  typeOfCompany = 'type_of_company',
  status = 'status',
  statusUpdatedBy = 'status_updated_by',
  statusUpdatedAt = 'status_updated_at',
  organization = 'organization',
  passportOwners = 'passport_owners',
  permissionProfileTemplateId = 'permission_profile_template_id',
  organizationPermissionProfile = 'organization_permission_profile',
  labels = 'labels',
  companyAvatar = 'logo',
}

export const USER_PASSPORTS = {
  [UserPassportsSerialized.UserPassports]: {
    original: UserPassportsOriginal.UserPassports,
    serialized: UserPassportsSerialized.UserPassports,
    unserialized: UserPassportsUnserialized.UserPassports,
    view: IntlKeys.titleUserPassports,
    viewName: 'Organization Passports',

    columns: {
      [UserPassportsSerializedHeaders.concepts]: {
        original: UserPassportsOriginalHeaders.concepts,
        serialized: UserPassportsSerializedHeaders.concepts,
        unserialized: UserPassportsUnserializedHeaders.concepts,
        view: IntlKeys.organizationsConcepts,
      },
      [UserPassportsSerializedHeaders.kvk]: {
        original: UserPassportsOriginalHeaders.kvk,
        serialized: UserPassportsSerializedHeaders.kvk,
        unserialized: UserPassportsUnserializedHeaders.kvk,
        view: IntlKeys.kvk,
      },
      [UserPassportsSerializedHeaders.gln]: {
        original: UserPassportsOriginalHeaders.gln,
        serialized: UserPassportsSerializedHeaders.gln,
        unserialized: UserPassportsUnserializedHeaders.gln,
        view: IntlKeys.gln,
      },
      [UserPassportsSerializedHeaders.ubn]: {
        original: UserPassportsOriginalHeaders.ubn,
        serialized: UserPassportsSerializedHeaders.ubn,
        unserialized: UserPassportsUnserializedHeaders.ubn,
        view: IntlKeys.primeUbn,
      },
      [UserPassportsSerializedHeaders.ubnAliases]: {
        original: UserPassportsOriginalHeaders.ubnAliases,
        serialized: UserPassportsSerializedHeaders.ubnAliases,
        unserialized: UserPassportsUnserializedHeaders.ubnAliases,
        view: IntlKeys.ubnAliases,
      },
      [UserPassportsSerializedHeaders.customUbnName]: {
        original: UserPassportsOriginalHeaders.customUbnName,
        serialized: UserPassportsSerializedHeaders.customUbnName,
        unserialized: UserPassportsUnserializedHeaders.customUbnName,
        view: IntlKeys.userPassportUbnCustomName,
      },
      [UserPassportsSerializedHeaders.vionRelationNumber]: {
        original: UserPassportsOriginalHeaders.vionRelationNumber,
        serialized: UserPassportsSerializedHeaders.vionRelationNumber,
        unserialized: UserPassportsUnserializedHeaders.vionRelationNumber,
        view: IntlKeys.organizationsVionRelationNumber,
      },
      [UserPassportsSerializedHeaders.registrationName]: {
        original: UserPassportsOriginalHeaders.registrationName,
        serialized: UserPassportsSerializedHeaders.registrationName,
        unserialized: UserPassportsUnserializedHeaders.registrationName,
        view: IntlKeys.organizationsRegistrationName,
      },
      [UserPassportsSerializedHeaders.vatNum]: {
        original: UserPassportsOriginalHeaders.vatNum,
        serialized: UserPassportsSerializedHeaders.vatNum,
        unserialized: UserPassportsUnserializedHeaders.vatNum,
        view: IntlKeys.organizationsVatNum,
      },
      [UserPassportsSerializedHeaders.street]: {
        original: UserPassportsOriginalHeaders.street,
        serialized: UserPassportsSerializedHeaders.street,
        unserialized: UserPassportsUnserializedHeaders.street,
        view: IntlKeys.organizationsAddressStreet,
      },
      [UserPassportsSerializedHeaders.postcode]: {
        original: UserPassportsOriginalHeaders.postcode,
        serialized: UserPassportsSerializedHeaders.postcode,
        unserialized: UserPassportsUnserializedHeaders.postcode,
        view: IntlKeys.organizationsAddressPostcode,
      },
      [UserPassportsSerializedHeaders.place]: {
        original: UserPassportsOriginalHeaders.place,
        serialized: UserPassportsSerializedHeaders.place,
        unserialized: UserPassportsUnserializedHeaders.place,
        view: IntlKeys.organizationsAddressPlace,
      },
      [UserPassportsSerializedHeaders.phone]: {
        original: UserPassportsOriginalHeaders.phone,
        serialized: UserPassportsSerializedHeaders.phone,
        unserialized: UserPassportsUnserializedHeaders.phone,
        view: IntlKeys.organizationsPhone,
      },
      [UserPassportsSerializedHeaders.feedSuppliers]: {
        original: UserPassportsOriginalHeaders.feedSuppliers,
        serialized: UserPassportsSerializedHeaders.feedSuppliers,
        unserialized: UserPassportsUnserializedHeaders.feedSuppliers,
        view: IntlKeys.organizationsFeedSupplier,
      },
      [UserPassportsSerializedHeaders.certificates]: {
        original: UserPassportsOriginalHeaders.certificates,
        serialized: UserPassportsSerializedHeaders.certificates,
        unserialized: UserPassportsUnserializedHeaders.certificates,
        view: IntlKeys.certificates,
      },
      [UserPassportsSerializedHeaders.certificatesOther]: {
        original: UserPassportsOriginalHeaders.certificatesOther,
        serialized: UserPassportsSerializedHeaders.certificatesOther,
        unserialized: UserPassportsUnserializedHeaders.certificatesOther,
        view: IntlKeys.organizationsCertificatesOther,
      },
      [UserPassportsSerializedHeaders.geneticSuppliers]: {
        original: UserPassportsOriginalHeaders.geneticSuppliers,
        serialized: UserPassportsSerializedHeaders.geneticSuppliers,
        unserialized: UserPassportsUnserializedHeaders.geneticSuppliers,
        view: IntlKeys.organizationsGeneticSuppliers,
      },
      [UserPassportsSerializedHeaders.geneticSupplierOther]: {
        original: UserPassportsOriginalHeaders.geneticSupplierOther,
        serialized: UserPassportsSerializedHeaders.geneticSupplierOther,
        unserialized: UserPassportsUnserializedHeaders.geneticSupplierOther,
        view: IntlKeys.organizationsGeneticSupplierOther,
      },
      [UserPassportsSerializedHeaders.kiStations]: {
        original: UserPassportsOriginalHeaders.kiStations,
        serialized: UserPassportsSerializedHeaders.kiStations,
        unserialized: UserPassportsUnserializedHeaders.kiStations,
        view: IntlKeys.organizationsKiStations,
      },
      [UserPassportsSerializedHeaders.kiStationOther]: {
        original: UserPassportsOriginalHeaders.kiStationOther,
        serialized: UserPassportsSerializedHeaders.kiStationOther,
        unserialized: UserPassportsUnserializedHeaders.kiStationOther,
        view: IntlKeys.organizationsKiStationOther,
      },
      [UserPassportsSerializedHeaders.sowLines]: {
        original: UserPassportsOriginalHeaders.sowLines,
        serialized: UserPassportsSerializedHeaders.sowLines,
        unserialized: UserPassportsUnserializedHeaders.sowLines,
        view: IntlKeys.organizationsSowLines,
      },
      [UserPassportsSerializedHeaders.sowLineOther]: {
        original: UserPassportsOriginalHeaders.sowLineOther,
        serialized: UserPassportsSerializedHeaders.sowLineOther,
        unserialized: UserPassportsUnserializedHeaders.sowLineOther,
        view: IntlKeys.organizationsSowLineOther,
      },
      [UserPassportsSerializedHeaders.breedingProgram]: {
        original: UserPassportsOriginalHeaders.breedingProgram,
        serialized: UserPassportsSerializedHeaders.breedingProgram,
        unserialized: UserPassportsUnserializedHeaders.breedingProgram,
        view: IntlKeys.organizationsBreedingProgram,
      },
      [UserPassportsSerializedHeaders.sireLines]: {
        original: UserPassportsOriginalHeaders.sireLines,
        serialized: UserPassportsSerializedHeaders.sireLines,
        unserialized: UserPassportsUnserializedHeaders.sireLines,
        view: IntlKeys.organizationsSireLines,
      },
      [UserPassportsSerializedHeaders.sireLineOther]: {
        original: UserPassportsOriginalHeaders.sireLineOther,
        serialized: UserPassportsSerializedHeaders.sireLineOther,
        unserialized: UserPassportsUnserializedHeaders.sireLineOther,
        view: IntlKeys.organizationsSireLineOther,
      },
      [UserPassportsSerializedHeaders.veterinaryPractice]: {
        original: UserPassportsOriginalHeaders.veterinaryPractice,
        serialized: UserPassportsSerializedHeaders.veterinaryPractice,
        unserialized: UserPassportsUnserializedHeaders.veterinaryPractice,
        view: IntlKeys.organizationsVeterinaryPractice,
      },
      [UserPassportsSerializedHeaders.veterinaryPracticeOther]: {
        original: UserPassportsOriginalHeaders.veterinaryPracticeOther,
        serialized: UserPassportsSerializedHeaders.veterinaryPracticeOther,
        unserialized: UserPassportsUnserializedHeaders.veterinaryPracticeOther,
        view: IntlKeys.organizationsVeterinaryPracticeOther,
      },
      [UserPassportsSerializedHeaders.feedSupplierOther]: {
        original: UserPassportsOriginalHeaders.feedSupplierOther,
        serialized: UserPassportsSerializedHeaders.feedSupplierOther,
        unserialized: UserPassportsUnserializedHeaders.feedSupplierOther,
        view: IntlKeys.organizationsFeedSupplierOther,
      },
      [UserPassportsSerializedHeaders.fmsNames]: {
        original: UserPassportsOriginalHeaders.fmsNames,
        serialized: UserPassportsSerializedHeaders.fmsNames,
        unserialized: UserPassportsUnserializedHeaders.fmsNames,
        view: IntlKeys.organizationsFmsNames,
      },
      [UserPassportsSerializedHeaders.fmsModuleAgrisysts]: {
        original: UserPassportsOriginalHeaders.fmsModuleAgrisysts,
        serialized: UserPassportsSerializedHeaders.fmsModuleAgrisysts,
        unserialized: UserPassportsUnserializedHeaders.fmsModuleAgrisysts,
        view: IntlKeys.organizationsFmsModuleAgrisysts,
      },
      [UserPassportsSerializedHeaders.fmsModuleAgroCoops]: {
        original: UserPassportsOriginalHeaders.fmsModuleAgroCoops,
        serialized: UserPassportsSerializedHeaders.fmsModuleAgroCoops,
        unserialized: UserPassportsUnserializedHeaders.fmsModuleAgroCoops,
        view: IntlKeys.organizationsFmsModuleAgroCoops,
      },
      [UserPassportsSerializedHeaders.fmsModuleAgrovisions]: {
        original: UserPassportsOriginalHeaders.fmsModuleAgrovisions,
        serialized: UserPassportsSerializedHeaders.fmsModuleAgrovisions,
        unserialized: UserPassportsUnserializedHeaders.fmsModuleAgrovisions,
        view: IntlKeys.organizationsFmsModuleAgrovisions,
      },
      [UserPassportsSerializedHeaders.feedingSystems]: {
        original: UserPassportsOriginalHeaders.feedingSystems,
        serialized: UserPassportsSerializedHeaders.feedingSystems,
        unserialized: UserPassportsUnserializedHeaders.feedingSystems,
        view: IntlKeys.organizationsFeedingSystems,
      },
      [UserPassportsSerializedHeaders.feedingSystemOther]: {
        original: UserPassportsOriginalHeaders.feedingSystemOther,
        serialized: UserPassportsSerializedHeaders.feedingSystemOther,
        unserialized: UserPassportsUnserializedHeaders.feedingSystemOther,
        view: IntlKeys.organizationsFeedingSystemOther,
      },
      [UserPassportsSerializedHeaders.climateSystems]: {
        original: UserPassportsOriginalHeaders.climateSystems,
        serialized: UserPassportsSerializedHeaders.climateSystems,
        unserialized: UserPassportsUnserializedHeaders.climateSystems,
        view: IntlKeys.organizationsClimateSystems,
      },
      [UserPassportsSerializedHeaders.climateSystemOther]: {
        original: UserPassportsOriginalHeaders.climateSystemOther,
        serialized: UserPassportsSerializedHeaders.climateSystemOther,
        unserialized: UserPassportsUnserializedHeaders.climateSystemOther,
        view: IntlKeys.organizationsClimateSystemOther,
      },
      [UserPassportsSerializedHeaders.veterinarian]: {
        original: UserPassportsOriginalHeaders.veterinarian,
        serialized: UserPassportsSerializedHeaders.veterinarian,
        unserialized: UserPassportsUnserializedHeaders.veterinarian,
        view: IntlKeys.organizationsVeterinarian,
      },
      [UserPassportsSerializedHeaders.typeOfCompany]: {
        original: UserPassportsOriginalHeaders.typeOfCompany,
        serialized: UserPassportsSerializedHeaders.typeOfCompany,
        unserialized: UserPassportsUnserializedHeaders.typeOfCompany,
        view: IntlKeys.organizationsTypeOfCompany,
      },
      [UserPassportsSerializedHeaders.status]: {
        original: UserPassportsOriginalHeaders.status,
        serialized: UserPassportsSerializedHeaders.status,
        unserialized: UserPassportsUnserializedHeaders.status,
        view: IntlKeys.status,
      },
      [UserPassportsSerializedHeaders.statusUpdatedBy]: {
        original: UserPassportsOriginalHeaders.statusUpdatedBy,
        serialized: UserPassportsSerializedHeaders.statusUpdatedBy,
        unserialized: UserPassportsUnserializedHeaders.statusUpdatedBy,
        view: IntlKeys.statusUpdatedBy,
      },
      [UserPassportsSerializedHeaders.statusUpdatedAt]: {
        original: UserPassportsOriginalHeaders.statusUpdatedAt,
        serialized: UserPassportsSerializedHeaders.statusUpdatedAt,
        unserialized: UserPassportsUnserializedHeaders.statusUpdatedAt,
        view: IntlKeys.statusUpdatedAt,
      },
      [UserPassportsSerializedHeaders.organization]: {
        original: UserPassportsOriginalHeaders.organization,
        serialized: UserPassportsSerializedHeaders.organization,
        unserialized: UserPassportsUnserializedHeaders.organization,
        view: IntlKeys.id,
      },
      [UserPassportsSerializedHeaders.passportOwners]: {
        original: UserPassportsOriginalHeaders.passportOwners,
        serialized: UserPassportsSerializedHeaders.passportOwners,
        unserialized: UserPassportsUnserializedHeaders.passportOwners,
        view: IntlKeys.id,
      },
      [UserPassportsSerializedHeaders.permissionProfileTemplateId]: {
        original: UserPassportsOriginalHeaders.permissionProfileTemplateId,
        serialized: UserPassportsSerializedHeaders.permissionProfileTemplateId,
        unserialized: UserPassportsUnserializedHeaders.permissionProfileTemplateId,
        view: IntlKeys.adminPermissionProfileTemplate,
      },
      [UserPassportsSerializedHeaders.organizationPermissionProfile]: {
        original: UserPassportsOriginalHeaders.organizationPermissionProfile,
        serialized: UserPassportsSerializedHeaders.organizationPermissionProfile,
        unserialized: UserPassportsUnserializedHeaders.organizationPermissionProfile,
        view: IntlKeys.organizationsPermissionProfile,
      },
      [UserPassportsSerializedHeaders.labels]: {
        original: UserPassportsOriginalHeaders.labels,
        serialized: UserPassportsSerializedHeaders.labels,
        unserialized: UserPassportsUnserializedHeaders.labels,
        view: IntlKeys.titleLabels,
      },
      [UserPassportsSerializedHeaders.companyAvatar]: {
        original: UserPassportsOriginalHeaders.companyAvatar,
        serialized: UserPassportsSerializedHeaders.companyAvatar,
        unserialized: UserPassportsUnserializedHeaders.companyAvatar,
        view: IntlKeys.companyAvatar,
      },
    },
  },
};
