import { createSelector, createReducer } from '@reduxjs/toolkit';
import { RootState } from '../store';

import { asyncAction } from 'lib/actions';

import { DataViews } from '../entities/UI';

const initialState = {
  availableMenuItems: {} as Record<string, number | boolean>,
  dashboardAppInfos: {} as Record<string, undefined | { id: number; name: string; uses_data: string[] }>,
  applications: [] as string[],
  availableApplications: [] as string[],
  availableEntities: [] as string[],
};

export const getSideMenuCounters = asyncAction<DataViews>('ui/getSideMenuCounters');
export const fetchDashboardAppInfoAction = asyncAction('ui/fetchDashboardAppInfo');
export const myJoinDataAction = asyncAction('myJoinData/visit');

export const uiReducer = createReducer(initialState, {
  [getSideMenuCounters.success]: (state, { payload }) => {
    state.availableMenuItems = payload.availableMenuItems;
    state.applications = payload.applications;
    state.availableApplications = payload.availableApplications;
    state.availableEntities = payload.availableEntities;
  },
  [fetchDashboardAppInfoAction.success]: (state, { payload }) => {
    state.dashboardAppInfos[payload.name] = payload;
  },
});

const uiSelector = (state: RootState) => state.ui;

export const uiAvailableApplicationsSelector = createSelector(uiSelector, (ui) => ui.availableApplications);
export const uiAvailableEntitiesSelector = createSelector(uiSelector, (ui) => ui.availableEntities);
export const uiAvailableMenuItemsSelector = createSelector(uiSelector, (ui) => ui.availableMenuItems);
export const uiDashboardAppInfosSelector = createSelector(uiSelector, (ui) => ui.dashboardAppInfos);
export const uiApplicationsSelector = createSelector(uiSelector, (ui) => ui.applications);

export default uiReducer;
